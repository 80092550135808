const questionsAndAnswers = [
  {
    category: 'Funcionamiento',
    questions: [
      {
        question: '¿Cómo funciona?',
        answer:
          'Hemos desarrollado un sistema, que se adapta a ti y que aprende contigo, en función de tus experiencias.\n\nEl primer paso a seguir consiste en completar un cuestionario con el que te ayudamos a hacerte las preguntas correctas, en el que podrás poner lo que quieres e incluso lo que no. A partir de dicho cuestionario, podremos organizarte una primera cita; si en esta cita no surge la química suficiente estudiaremos como podemos mejorar la siguiente cita, hasta encontrar tu pareja ideal.',
      },
      {
        question:
          '¿Si tengo dificultades para registrarme o completar el cuestionario, me podeis ayudar?',
        answer:
          'Claro, si tienes algún tipo de discapacidad o dificultad que te lo impida, puedes contactar con nosotros y acordaremos un día y una hora para llamarte y ayudarte. Puedes enviarnos un correo a info@tucelestina.com',
      },
    ],
  },
  {
    category: 'Citas',
    questions: [
      {
        question: '¿Cómo contactareis conmigo para avisarme de la cita?',
        answer:
          'En estos momentos, nos comunicaremos contigo a través de tu correo electrónico. Cuando el servicio esté completamente disponible, podrás descargarte la aplicación que nos permitirá una comunicación más directa. Y en casos necesarios te contactaremos a través de tu teléfono.',
      },
      {
        question: '¿Tendré una cita asegurada?',
        answer:
          'Solo te ofreceremos el servicio, si consideramos que teniendo en cuenta la información aportada por ti y el resto de usuarios, tienes posibilidades de encontrar pareja.\n\nEsta propuesta puede tardar en llegar, pero te tendremos en cuenta en todo momento y te avisaremos en cuanto ese momento llegue. No dejaremos de estar en contacto contigo',
      },
      {
        question:
          '¿Cuánto tiempo tendré que esperar para tener mi primera cita?',
        answer:
          'Es un dato que no podemos darte con exactitud. Dependerá de cada caso y de tu compatibilidad con el resto de perfiles registrados. Pueden ser días, semanas o meses.\n\nPero, una vez hayas tenido la primera cita, tendrás que tomar la decisión de seguir conociendo a esa persona o de esperar a la siguiente cita para conocer a otra. Las citas serán de una forma pausada y tranquila, sin agobios.',
      },
      {
        question: '¿Cuántas propuestas de citas puedo llegar a tener?',
        answer:
          'Las que hagan falta. Puede suceder que consideres bien seleccionada a la persona propuesta pero que no surja la química necesaria entre vosotros. En este caso, tras valorar tu opinión, te organizaremos otra cita.',
      },
      {
        question: '¿En que consisten las citas?',
        answer:
          'Cada cita puede ser completamente diferente desde una cena romántica hasta una cita al aire libre. Tendremos en cuenta tus gustos y esperamos sorprenderte.',
      },
      {
        question: '¿Puedo tener más de una cita con una misma persona?',
        answer:
          'Sí, si crees que tener otra cita con la misma persona te puede ayudar, ¡para eso estamos!. Por otra parte, si te arrepientes de haber querido dejar de conocer a una persona, podemos intentar arreglarlo.',
      },
      {
        question:
          '¿Qué pasa si la persona seleccionada para mi cita no me gusta?',
        answer:
          'Si te hemos organizado una cita con esta persona es porque hemos visto una alta compatibilidad. Sí la cita, no es lo que esperabas, da una oportunidad a que finalice, para que podamos organizar la siguiente cita de forma más precisa.',
      },
    ],
  },
  {
    category: 'Problemas',
    questions: [
      {
        question: '¿Qué pasa si no puedo asistir a una cita que he confirmado?',
        answer:
          'Avísanos lo antes posible por el chat de la App e intentaremos cambiar la fecha de la cita.',
      },
      {
        question:
          '¿Qué pasa si mi pareja no acude a la cita? ¿Qué pasa si al final no llego a tener ninguna cita?',
        answer:
          'Haremos todo lo que está en nuestras manos, en primer lugar te trasladaremos las explicaciones pertinentes de la persona en cuestión. En segundo lugar,  te organizaremos otra cita, pero si no fuera posible que tengas ninguna cita te devolveremos el dinero.',
      },
    ],
  },
  {
    category: 'Privacidad',
    questions: [
      {
        question: '¿Es obligatorio subir fotos mías?',
        answer:
          'Tenemos en cuenta tanto la conexión emocional, como la física. Por ello necesitamos hacernos una idea de tus rasgos, como tu sonrisa. Si no nos aportas una foto, no podremos valorar tu compatibilidad.',
      },
      {
        question: '¿Publicaréis mis fotos o información sobre mí?',
        answer:
          'Nunca publicaremos tus fotos ni tu perfil, va en contra de nuestros principios, una foto no puede transmitir todo lo que una persona realmente es y podrías llegar a hacerte una imagen equivocada. No serás el escaparate de nadie y tu privacidad queda garantizada.',
      },
      {
        question:
          '¿Si me registro podré visualizar los perfiles de otros usuarios?',
        answer:
          'No. Los perfiles de los usuarios no se muestran ni a las personas que visitan la página ni a los usuarios registrados. Sólo nosotros tenemos acceso a los datos personales y a las fotografías.\nSolo conocerás el rostro de tu próxima cita cuando la llevéis a cabo. Nos gusta el misterio y sabemos que a ti también.',
      },
      {
        question:
          '¿Qué información me facilitaréis de la persona con quien tendré la cita?',
        answer:
          'Sólo el nombre de pila y algún dato más que fuese necesario. Pero, no te mostraremos una foto, porque una foto no puede transmitir todo lo que una persona realmente es… y no queremos condicionar tu primera impresión.',
      },
    ],
  },
  {
    category: 'Seguridad',
    questions: [
      {
        question:
          '¿Qué seguridad hay en las citas? ¿Qué pasa si mi cita no tiene un buen comportamiento y me quiero ir?',
        answer:
          'Las citas siempre se organizan en sitios donde hay alguna persona encargada de vigilar que no haya ningún problema y que pueda comprobar que las personas que se han presentado son las correctas.\n\nSi consideras que la persona seleccionada para tu cita no esta teniendo un buen comportamiento. Se lo puedes comunicar a las personas que te esten atendiendo y te ayudaran.\n\nEn todo momento sabremos donde has estado y con quien, si ubiera algún problema, todo se queda registrado y se actuará en consecuencia.',
      },
      {
        question: 'Recomendaciones',
        answer:
          'Te recomendamos que no compartas ni solicites tu datos de contacto a la otra persona, en el caso de que la cita sea exitosa, nosotros nos encargaremos de hacertelos llegar.',
      },
    ],
  },
  {
    category: 'Pago',
    questions: [
      {
        question: '¿Que precio tiene el servicio?',
        answer:
          'El pago garantiza el compromiso de las personas que contraten el servicio, al mismo tiempo que aleja a las personas que no se lo toman en serio.\n\nEl precio es de 300€, que se puede dividir en varios pagos, para que te sea más comodo. Pero es un único pago por pareja y se considera que hemos encontrado una pareja cuando dos personas lleven un año conociendose. Podrás tener las citas necesarias, durante tiempo indefinido, hasta encontrar a esa persona.\n\nEste precio, no incluye el precio de la cena u otra actividad, que irá en función de la opción seleccionada y de lo que se consuma.\n\nDurante la eleción de la actividad te indicaremos los distintos precios.',
      },
      {
        question: '¿Cuánto vale la cita y quien la paga?',
        answer:
          'La actividad realizada durante la cita tendrá un importe u otro en función de lo que se realice, pero te avisaremos del precio, antes de la eleción.\n\nLa factura de la actividad se pagá a medias, entre la persona con la que vayas y tu.',
      },
    ],
  },
  {
    category: 'Mi cuenta de usuario',
    questions: [
      {
        question: '¿Puedo "pausar" mi perfil?',
        answer:
          'Sí. Te pedimos que si durante un periodo determinado de tiempo no quieres recibir propuestas de citas, nos lo indices por el chat. Podrás volver a activar tu perfil en cualquier momento.',
      },
      {
        question: '¿Cómo puedo darme de baja del servicio?',
        answer: 'Podrás darte de baja indicándolo por el chat',
      },
    ],
  },
  {
    category: 'Consulta y contacto',
    questions: [
      {
        question: '¿Cómo puedo comunicarme con vosotros?',
        answer: 'Puedes escribirnos a info@tucelestina.com',
      },
    ],
  },
];

export default questionsAndAnswers;
