import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './login.scss';

interface LoginProps {
  registrationFormRef: React.RefObject<HTMLDivElement>;
}

const Login = ({ registrationFormRef }: LoginProps) => {
  const { t } = useTranslation();

  const login = t('buttons.login');

  const handleRegistrationButtonClick = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="login-page-home px-5">
      <div className="flex flex-col items-center gap-5 z-10 mb-32">
        <img
          className="w-40 h-40 object-contain"
          src="img/landing/celestina.webp"
          alt="celestina-logo"
        />
        <h1 className="uppercase text-primary text-4xl font-semibold">
          {t('appName')}
        </h1>
        <button
          className="text-pink-100 uppercase text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
          onClick={handleRegistrationButtonClick}>
          ¡registrate gratis!
        </button>
        {/* <Link
          to="/login"
          className="text-pink-100 uppercase text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition">
          {login}
        </Link> */}
        <h2
          className=" text-primary text-3xl font-bold text-center drop-shadow-3xl"
          style={{ fontFamily: 'Dancing Script Variable' }}>
          {t('home.login.phrase')}
        </h2>
      </div>
      <img
        src="img/landing/pinkLeaf.webp"
        alt="Superposed image"
        className="login-page-home__pink-flower__left"
      />
      <img
        src="img/landing/pinkLeaf.webp"
        alt="Superposed image"
        className="login-page-home__pink-flower__right"
      />
    </div>
  );
};

export default Login;
