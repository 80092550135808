import { useRef, useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import FirstInfo from '../../components/home/first-info/FirstInfo';
import Login from '../../components/home/login/Login';
import Question from '../../components/home/questions/Questions';
import RegistrationForm from '../../components/home/registration-form/RegistrationForm';
import SecondInfo from '../../components/home/second-info/SecondInfo';
import Navbar from '../../components/navbar/navbar';
import { useLocation } from 'react-router-dom';
import './home.scss';
import SnackbarComponent from '../../components/snack-bar/SnackBar';

const Home = () => {
  const registrationFormRef = useRef<HTMLDivElement>(null);
  const questionsFormRef = useRef<HTMLDivElement>(null);
  const homeContainerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('scrollTo') === 'form') {
      registrationFormRef.current?.scrollIntoView({ behavior: 'smooth' });
      // Opcionalmente, puedes eliminar el parámetro de consulta después del scroll
      queryParams.delete('scrollTo');
      window.history.replaceState(
        null,
        '',
        `${location.pathname}?${queryParams.toString()}`,
      );
    }
  }, [location.search]);

  return (
    <div className="home-view bg-contain" ref={homeContainerRef}>
      <header className="flex w-full justify-center">
        <Navbar isNavbarVisible={false} homeContainerRef={homeContainerRef} />
      </header>
      <main>
        <Login registrationFormRef={registrationFormRef} />
        <FirstInfo />
        <SecondInfo
          registrationFormRef={registrationFormRef}
          questionsFormRef={questionsFormRef}
        />
        <div ref={questionsFormRef}>
          <Question />
        </div>
        <div className="home-view__form" ref={registrationFormRef}>
          <RegistrationForm />
        </div>
      </main>
      <footer className="flex w-full h-auto bg-primary text-white font-semibold text-lg">
        <Footer />
      </footer>
      <SnackbarComponent />
    </div>
  );
};

export default Home;
