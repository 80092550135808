import { useState, RefObject } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useWindowScroll } from 'react-use';

import './navbar.scss';

interface navbarProps {
  isNavbarVisible: boolean;
  homeContainerRef?: RefObject<HTMLDivElement>;
}

const Navbar = ({ isNavbarVisible, homeContainerRef }: navbarProps) => {
  const { y } = useWindowScroll();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogoClick = (fromMenu: boolean) => {
    if (homeContainerRef && location.pathname === '/') {
      homeContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (fromMenu) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (
    <div
      className={`navbar ${
        isNavbarVisible || (!isNavbarVisible && y > 600)
          ? 'navbar-visible'
          : 'navbar-hidden'
      }`}>
      <div className="navbar__logo">
        <Link
          to="/"
          className="navbar__logo-link"
          onClick={() => handleLogoClick(false)}>
          <img src="img/landing/pinkCelestina.webp" alt="navbar logo" />
          <span className="navbar__title">Tu celestina</span>
        </Link>
      </div>
      <div
        className={`navbar-toggle ${isMenuOpen ? 'opened' : ''}`}
        onClick={handleMenuToggle}
      />
      <div className={`navbar-menu ${isMenuOpen ? 'opened' : ''}`}>
        <ul>
          <li className="block lg:hidden">
            <Link to="/" onClick={() => handleLogoClick(true)}>
              Inicio
            </Link>
          </li>
          {/* <li>
            <Link to="/blog">Blog</Link>
          </li> */}
          <li>
            <Link to="/conditions">Condiciones</Link>
          </li>
          <li>
            <Link to="/contact">Contacto</Link>
          </li>
          <li>
            <Link to="/login" className="navbar-menu__login-btn">
              Login
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
