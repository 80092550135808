import React from 'react';

interface ThemeSwitchProps {
  setTheme: (theme: 'light' | 'dark') => void;
  theme: 'light' | 'dark';
}

const ThemeSwitch: React.FC<ThemeSwitchProps> = ({ setTheme, theme }) => {
  return (
    <div className="inline-flex rounded-full border border-primary overflow-hidden">
      <button
        onClick={() => setTheme('light')}
        className={`px-4 py-2 ${
          theme === 'light' ? 'bg-primary text-white' : 'text-primary'
        }`}>
        Claro
      </button>
      <button
        onClick={() => setTheme('dark')}
        className={`px-4 py-2 ${
          theme === 'dark' ? 'bg-primary text-white' : 'text-primary'
        }`}>
        Oscuro
      </button>
    </div>
  );
};

export default ThemeSwitch;
