import React from 'react';
import './userSection.scss';

interface UserSectionProps {
  title: string;
  imageSrc: string;
  active: boolean;
  onClick: () => void;
}

const UserSection: React.FC<UserSectionProps> = ({
  title,
  imageSrc,
  active,
  onClick,
}) => {
  return (
    <div
      className={`user-section__item ${active ? 'active' : ''}`}
      onClick={onClick}>
      <img className="user-section__item-image" src={imageSrc} alt={title} />
      <span className="user-section__item-title">{title}</span>
    </div>
  );
};

export default UserSection;
