import Conditions from '../../components/conditions/conditions';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/navbar';
import './conditions.scss';

const ConditionsView = () => {
  return (
    <>
      <header className="flex w-full justify-center">
        <Navbar isNavbarVisible={true} />
      </header>
      <main>
        <Conditions />
      </main>
      <footer className="flex w-full h-auto bg-primary text-white font-semibold text-lg">
        <Footer />
      </footer>
    </>
  );
};

export default ConditionsView;
