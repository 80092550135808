import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomDatePickerProps {
  birthdate: Date | null;
  setBirthdate: (date: Date | null) => void;
  birthdateError: boolean;
  setBirthdateError: (error: boolean) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  birthdate,
  setBirthdate,
  birthdateError,
  setBirthdateError,
}) => {
  const { t } = useTranslation();
  const [day, setDay] = useState<string | undefined>(undefined);
  const [month, setMonth] = useState<string | undefined>(undefined);
  const [year, setYear] = useState<string | undefined>(undefined);

  const monthNames = [
    { name: t('months.january'), number: '01' },
    { name: t('months.february'), number: '02' },
    { name: t('months.march'), number: '03' },
    { name: t('months.april'), number: '04' },
    { name: t('months.may'), number: '05' },
    { name: t('months.june'), number: '06' },
    { name: t('months.july'), number: '07' },
    { name: t('months.august'), number: '08' },
    { name: t('months.september'), number: '09' },
    { name: t('months.october'), number: '10' },
    { name: t('months.november'), number: '11' },
    { name: t('months.december'), number: '12' },
  ];

  const getMaxDate = () => {
    const currentDate = new Date();
    return new Date(currentDate.setFullYear(currentDate.getFullYear() - 18));
  };

  useEffect(() => {
    if (birthdate) {
      setDay(String(birthdate.getDate()).padStart(2, '0'));
      setMonth(String(birthdate.getMonth() + 1).padStart(2, '0'));
      setYear(String(birthdate.getFullYear()));
    }
  }, [birthdate]);

  const handleDateChange = (
    newDay?: string,
    newMonth?: string,
    newYear?: string,
  ) => {
    const updatedDay = newDay !== undefined ? newDay : day;
    const updatedMonth = newMonth !== undefined ? newMonth : month;
    const updatedYear = newYear !== undefined ? newYear : year;

    if (updatedDay && updatedMonth && updatedYear) {
      const newDate = new Date(
        Number(updatedYear),
        Number(updatedMonth) - 1,
        Number(updatedDay),
      );
      if (newDate <= getMaxDate()) {
        setBirthdate(newDate);
        setBirthdateError(false);
      } else {
        setBirthdateError(true);
      }
    }
  };

  const daysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
  };

  const adjustMonthAndDay = () => {
    if (year && month) {
      const selectedYear = Number(year);
      const selectedMonth = Number(month);
      const maxDate = getMaxDate();

      let adjustedMonth = month;
      let adjustedDay = day;

      // Ajustar mes si es necesario
      if (
        selectedYear === maxDate.getFullYear() &&
        selectedMonth > maxDate.getMonth() + 1
      ) {
        adjustedMonth = String(maxDate.getMonth() + 1).padStart(2, '0');
        setMonth(adjustedMonth);
      }

      // Ajustar día si es necesario
      const daysInSelectedMonth = daysInMonth(
        Number(adjustedMonth),
        selectedYear,
      );
      if (adjustedDay && Number(adjustedDay) > daysInSelectedMonth) {
        adjustedDay = String(daysInSelectedMonth).padStart(2, '0');
        setDay(adjustedDay);
      }

      // Verificar si el día y mes actuales son válidos
      const adjustedDate = new Date(
        selectedYear,
        Number(adjustedMonth) - 1,
        Number(adjustedDay),
      );
      if (adjustedDate > maxDate) {
        adjustedMonth = String(maxDate.getMonth() + 1).padStart(2, '0');
        adjustedDay = String(maxDate.getDate()).padStart(2, '0');
        setMonth(adjustedMonth);
        setDay(adjustedDay);
      }

      handleDateChange(adjustedDay, adjustedMonth, year);
    }
  };

  useEffect(() => {
    adjustMonthAndDay();
  }, [year, month]);

  const days =
    day && month && year
      ? Array.from(
          { length: daysInMonth(Number(month), Number(year)) },
          (_, i) => String(i + 1).padStart(2, '0'),
        )
      : Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));

  const maxDate = getMaxDate();
  const currentYear = maxDate.getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => String(currentYear - i));

  const isDayDisabled = (day: string) => {
    if (!year || !month) return false;
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date > maxDate;
  };

  const isMonthDisabled = (monthNumber: string) => {
    if (!year) return false;
    const date = new Date(Number(year), Number(monthNumber) - 1, 1);
    return date > maxDate;
  };

  return (
    <div className="custom-date-picker w-full">
      <div className="flex space-x-2 w-full">
        <select
          value={day}
          onChange={(e) => {
            const newDay = e.target.value;
            setDay(newDay);
            handleDateChange(newDay, undefined, undefined);
          }}
          className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <option value="">{t('registration.day')}</option>
          {days.map((d) => (
            <option key={d} value={d} disabled={isDayDisabled(d)}>
              {d}
            </option>
          ))}
        </select>
        <select
          value={monthNames.find((m) => m.number === month)?.name || ''}
          onChange={(e) => {
            const selectedMonth = monthNames.find(
              (m) => m.name === e.target.value,
            )?.number;
            setMonth(selectedMonth);
            handleDateChange(undefined, selectedMonth, undefined);
          }}
          className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <option value="">{t('registration.month')}</option>
          {monthNames.map((m) => (
            <option
              key={m.number}
              value={m.name}
              disabled={isMonthDisabled(m.number)}>
              {m.name}
            </option>
          ))}
        </select>
        <select
          value={year}
          onChange={(e) => {
            const newYear = e.target.value;
            setYear(newYear);
            handleDateChange(undefined, undefined, newYear);
          }}
          className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <option value="">{t('registration.year')}</option>
          {years.map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
      </div>
      {birthdateError && (
        <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white mt-2">
          {t('registration.required')}
        </span>
      )}
    </div>
  );
};

export default CustomDatePicker;
