import { Link } from 'react-router-dom';
import './confirmation-view.scss';
import { useTranslation } from 'react-i18next';

const ConfirmationView = () => {
  const { t } = useTranslation();

  return (
    <section
      className="confirmation-view w-full h-screen"
      aria-live="polite"
      aria-atomic="true">
      <div className="confirmation-view__container  flex flex-col items-center justify-center gap-5 text-white p-5">
        <h1 className="uppercase text-pink-400 text-xl md:text-3xl font-bold text-center">
          {t('confirmation.title')}
        </h1>
        <p className="bg-primary rounded-lg p-3 md:max-w-4xl shadow-2xl backdrop-blur-xl text-justify text-base">
          {t('confirmation.message')}
        </p>
        <Link
          to="/"
          className="text-pink-100 uppercase text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition">
          {t('buttons.backToTheLandingPage')}
        </Link>
      </div>
    </section>
  );
};

export default ConfirmationView;
