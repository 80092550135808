interface ProvincesData {
  [key: string]: string[];
}

export const provincesData: ProvincesData = {
  'A Coruña': [
    'A Baña',
    'A Capela',
    'A Coruña',
    'A Laracha',
    'A Pobra do Caramiñal',
    'Abegondo',
    'Ames',
    'Aranga',
    'Ares',
    'Arteixo',
    'Arzúa',
    'As Pontes de García Rodríguez',
    'As Somozas',
    'Bergondo',
    'Betanzos',
    'Boimorto',
    'Boiro',
    'Boqueixón',
    'Brión',
    'Cabana de Bergantiños',
    'Cabanas',
    'Camariñas',
    'Cambre',
    'Carballo',
    'Cariño',
    'Carnota',
    'Carral',
    'Cedeira',
    'Cee',
    'Cerceda',
    'Cerdido',
    'Cesuras',
    'Coirós',
    'Corcubión',
    'Coristanco',
    'Culleredo',
    'Curtis',
    'Dodro',
    'Dumbría',
    'Fene',
    'Ferrol',
    'Fisterra',
    'Frades',
    'Irixoa',
    'Laxe',
    'Lousame',
    'Malpica de Bergantiños',
    'Mañón',
    'Mazaricos',
    'Melide',
    'Mesía',
    'Miño',
    'Moeche',
    'Monfero',
    'Mugardos',
    'Muros',
    'Muxía',
    'Narón',
    'Neda',
    'Negreira',
    'Noia',
    'O Pino',
    'Oleiros',
    'Ordes',
    'Oroso',
    'Ortigueira',
    'Outes',
    'Oza dos Ríos',
    'Paderne',
    'Padrón',
    'Ponteceso',
    'Pontedeume',
    'Porto do Son',
    'Rianxo',
    'Ribeira',
    'Rois',
    'Sada',
    'San Sadurniño',
    'Santa Comba',
    'Santiago de Compostela',
    'Santiso',
    'Sobrado',
    'Teo',
    'Toques',
    'Tordoia',
    'Touro',
    'Trazo',
    'Val do Dubra',
    'Valdoviño',
    'Vedra',
    'Vilarmaior',
    'Vilasantar',
    'Vimianzo',
    'Zas',
  ],
  Álava: [
    'Alegría-Dulantzi',
    'Amurrio',
    'Añana',
    'Aramaio',
    'Armiñón',
    'Arraia-Maeztu',
    'Arrazua-Ubarrundia',
    'Artziniega',
    'Asparrena',
    'Ayala/Aiara',
    'Baños de Ebro/Mañueta',
    'Barrundia',
    'Berantevilla',
    'Bernedo',
    'Campezo/Kanpezu',
    'Elburgo/Burgelu',
    'Elciego',
    'Elvillar/Bilar',
    'Erriberagoitia/Ribera Alta',
    'Harana/Valle de Arana',
    'Iruña Oka/Iruña de Oca',
    'Iruraiz-Gauna',
    'Kripan',
    'Kuartango',
    'Labastida/Bastida',
    'Lagrán',
    'Laguardia',
    'Lanciego/Lantziego',
    'Lantarón',
    'Lapuebla de Labarca',
    'Laudio/Llodio',
    'Legutiano',
    'Leza',
    'Moreda de Álava',
    'Navaridas',
    'Okondo',
    'Oyón-Oion',
    'Peñacerrada-Urizaharra',
    'Ribera Baja/Erribera Beitia',
    'Salvatierra/Agurain',
    'Samaniego',
    'San Millán/Donemiliaga',
    'Urkabustaiz',
    'Valdegovía/Gaubea',
    'Villabuena de Álava/Eskuernaga',
    'Vitoria-Gasteiz',
    'Yécora/Iekora',
    'Zalduondo',
    'Zambrana',
    'Zigoitia',
    'Zuia',
  ],
  Albacete: [
    'Abengibre',
    'Alatoz',
    'Albacete',
    'Albatana',
    'Alborea',
    'Alcadozo',
    'Alcalá del Júcar',
    'Alcaraz',
    'Almansa',
    'Alpera',
    'Ayna',
    'Balazote',
    'Balsa de Ves',
    'Barrax',
    'Bienservida',
    'Bogarra',
    'Bonete',
    'Carcelén',
    'Casas de Juan Núñez',
    'Casas de Lázaro',
    'Casas de Ves',
    'Casas-Ibáñez',
    'Caudete',
    'Cenizate',
    'Chinchilla de Monte-Aragón',
    'Corral-Rubio',
    'Cotillas',
    'El Ballestero',
    'El Bonillo',
    'Elche de la Sierra',
    'Férez',
    'Fuensanta',
    'Fuente-Álamo',
    'Fuentealbilla',
    'Golosalvo',
    'Hellín',
    'Higueruela',
    'Hoya-Gonzalo',
    'Jorquera',
    'La Gineta',
    'La Herrera',
    'La Recueja',
    'La Roda',
    'Letur',
    'Lezuza',
    'Liétor',
    'Madrigueras',
    'Mahora',
    'Masegoso',
    'Minaya',
    'Molinicos',
    'Montalvos',
    'Montealegre del Castillo',
    'Motilleja',
    'Munera',
    'Navas de Jorquera',
    'Nerpio',
    'Ontur',
    'Ossa de Montiel',
    'Paterna del Madera',
    'Peñas de San Pedro',
    'Peñascosa',
    'Pétrola',
    'Povedilla',
    'Pozo Cañada',
    'Pozohondo',
    'Pozo-Lorente',
    'Pozuelo',
    'Riópar',
    'Robledo',
    'Salobre',
    'San Pedro',
    'Socovos',
    'Tarazona de la Mancha',
    'Tobarra',
    'Valdeganga',
    'Vianos',
    'Villa de Ves',
    'Villalgordo del Júcar',
    'Villamalea',
    'Villapalacios',
    'Villarrobledo',
    'Villatoya',
    'Villavaliente',
    'Villaverde de Guadalimar',
    'Viveros',
    'Yeste',
  ],
  'Alicante/Alacant': [
    'Adsubia',
    'Agost',
    'Agres',
    'Aigües',
    'Albatera',
    'Alcalalí',
    'Alcocer de Planes',
    'Alcoleja',
    'Alcoy/Alcoi',
    'Alfafara',
    'Algorfa',
    'Algueña',
    'Alicante/Alacant',
    'Almoradí',
    'Almudaina',
    'Altea',
    'Aspe',
    'Balones',
    'Banyeres de Mariola',
    'Benasau',
    'Beneixama',
    'Benejúzar',
    'Benferri',
    'Beniarbeig',
    'Beniardá',
    'Beniarrés',
    'Benidoleig',
    'Benidorm',
    'Benifallim',
    'Benifato',
    'Benigembla',
    'Benijófar',
    'Benilloba',
    'Benillup',
    'Benimantell',
    'Benimarfull',
    'Benimassot',
    'Benimeli',
    'Benissa',
    'Biar',
    'Bigastro',
    'Bolulla',
    'Busot',
    'Callosa de Segura',
    "Callosa d'En Sarrià",
    'Calpe/Calp',
    'Cañada',
    'Castalla',
    'Castell de Castells',
    'Catral',
    'Cocentaina',
    'Confrides',
    'Cox',
    'Crevillent',
    'Daya Nueva',
    'Daya Vieja',
    'Dénia',
    'Dolores',
    'El Benitachell/Poble Nou de Benitatxell',
    'El Campello',
    'El Campo de Mirra/Camp de Mirra',
    'El Castell de Guadalest',
    "El Ràfol d'Almúnia",
    'El Verger',
    'El/Hondón de Las Nieves Fondó de les Neus',
    'El/Pinoso Pinós',
    'Elche/Elx',
    'Elda',
    'Els Poblets',
    'Facheca',
    'Famorca',
    'Finestrat',
    'Formentera del Segura',
    'Gaianes',
    'Gata de Gorgos',
    'Gorga',
    'Granja de Rocamora',
    'Guardamar del Segura',
    'Hondón de los Frailes',
    'Ibi',
    'Jacarilla',
    'Jávea/Xàbia',
    'Jijona/Xixona',
    "L' Alfàs del Pi",
    "L' Alqueria d'Asnar",
    "L' Lorcha/Orxa",
    'La Nucia',
    'La Romana',
    'La Torremanzanas/Torre de les Maçanes',
    "La Vall d'Alcalà",
    'La Vall de Laguar',
    "La Vall d'Ebo",
    'La Villajoyosa/Vila Joiosa',
    'Llíber',
    'Los Montesinos',
    'Millena',
    'Monforte del Cid',
    'Monóvar/Monòver',
    'Murla',
    'Muro de Alcoy',
    'Mutxamel',
    'Novelda',
    'Ondara',
    'Onil',
    'Orba',
    'Orihuela',
    'Orxeta',
    'Parcent',
    'Pedreguer',
    'Pego',
    'Penàguila',
    'Petrer',
    'Pilar de la Horadada',
    'Planes',
    'Polop',
    'Quatretondeta',
    'Rafal',
    'Redován',
    'Relleu',
    'Rojales',
    'Sagra',
    'Salinas',
    'San Fulgencio',
    'San Isidro',
    'San Miguel de Salinas',
    'San Vicente del Raspeig/Sant Vicent del Raspeig',
    'Sanet y Negrals',
    "Sant Joan d'Alacant",
    'Santa Pola',
    'Sax',
    'Sella',
    'Senija',
    'Tàrbena',
    'Teulada',
    'Tibi',
    'Tollos',
    'Tormos',
    'Torrevieja',
    'Vall de Gallinera',
    'Villena',
    'Xaló',
  ],
  Almería: [
    'Abla',
    'Abrucena',
    'Adra',
    'Albánchez',
    'Alboloduy',
    'Albox',
    'Alcolea',
    'Alcóntar',
    'Alcudia de Monteagud',
    'Alhabia',
    'Alhama de Almería',
    'Alicún',
    'Almería',
    'Almócita',
    'Alsodux',
    'Antas',
    'Arboleas',
    'Armuña de Almanzora',
    'Bacares',
    'Bayárcal',
    'Bayarque',
    'Bédar',
    'Beires',
    'Benahadux',
    'Benitagla',
    'Benizalón',
    'Bentarique',
    'Berja',
    'Canjáyar',
    'Cantoria',
    'Carboneras',
    'Castro de Filabres',
    'Chercos',
    'Chirivel',
    'Cóbdar',
    'Cuevas del Almanzora',
    'Dalías',
    'El Ejido',
    'Enix',
    'Felix',
    'Fines',
    'Fiñana',
    'Fondón',
    'Gádor',
    'Garrucha',
    'Gérgal',
    'Huécija',
    'Huércal de Almería',
    'Huércal-Overa',
    'Illar',
    'Instinción',
    'La Mojonera',
    'Laroya',
    'Las Tres Villas',
    'Láujar de Andarax',
    'Líjar',
    'Los Gallardos',
    'Lubrín',
    'Lucainena de las Torres',
    'Lúcar',
    'Macael',
    'María',
    'Mojácar',
    'Nacimiento',
    'Níjar',
    'Ohanes',
    'Olula de Castro',
    'Olula del Río',
    'Oria',
    'Padules',
    'Partaloa',
    'Paterna del Río',
    'Pechina',
    'Pulpí',
    'Purchena',
    'Rágol',
    'Rioja',
    'Roquetas de Mar',
    'Santa Cruz de Marchena',
    'Santa Fe de Mondújar',
    'Senés',
    'Serón',
    'Sierro',
    'Somontín',
    'Sorbas',
    'Suflí',
    'Tabernas',
    'Taberno',
    'Tahal',
    'Terque',
    'Tíjola',
    'Turre',
    'Turrillas',
    'Uleila del Campo',
    'Urrácal',
    'Velefique',
    'Vélez-Blanco',
    'Vélez-Rubio',
    'Vera',
    'Viator',
    'Vícar',
    'Zurgena',
  ],
  Asturias: [
    'Allande',
    'Aller',
    'Amieva',
    'Avilés',
    'Belmonte de Miranda',
    'Bimenes',
    'Boal',
    'Cabrales',
    'Cabranes',
    'Candamo',
    'Cangas de Onís',
    'Cangas del Narcea',
    'Caravia',
    'Carreño',
    'Caso',
    'Castrillón',
    'Castropol',
    'Coaña',
    'Colunga',
    'Corvera de Asturias',
    'Cudillero',
    'Degaña',
    'El Franco',
    'Gijón',
    'Gozón',
    'Grado',
    'Grandas de Salime',
    'Ibias',
    'Illano',
    'Illas',
    'Langreo',
    'Las Regueras',
    'Laviana',
    'Lena',
    'Llanera',
    'Llanes',
    'Mieres',
    'Morcín',
    'Muros de Nalón',
    'Nava',
    'Navia',
    'Noreña',
    'Onís',
    'Oviedo',
    'Parres',
    'Peñamellera Alta',
    'Peñamellera Baja',
    'Pesoz',
    'Piloña',
    'Ponga',
    'Pravia',
    'Proaza',
    'Quirós',
    'Ribadedeva',
    'Ribadesella',
    'Ribera de Arriba',
    'Riosa',
    'Salas',
    'San Martín de Oscos',
    'San Martín del Rey Aurelio',
    'San Tirso de Abres',
    'Santa Eulalia de Oscos',
    'Santo Adriano',
    'Sariego',
    'Siero',
    'Sobrescobio',
    'Somiedo',
    'Soto del Barco',
    'Tapia de Casariego',
    'Taramundi',
    'Teverga',
    'Tineo',
    'Valdés',
    'Vegadeo',
    'Villanueva de Oscos',
    'Villaviciosa',
    'Villayón',
    'Yernes y Tameza',
  ],
  Ávila: [
    'Adanero',
    'Albornos',
    'Aldeanueva de Santa Cruz',
    'Aldeaseca',
    'Amavida',
    'Arenas de San Pedro',
    'Arevalillo',
    'Arévalo',
    'Aveinte',
    'Avellaneda',
    'Ávila',
    'Barromán',
    'Becedas',
    'Becedillas',
    'Bercial de Zapardiel',
    'Bernuy-Zapardiel',
    'Berrocalejo de Aragona',
    'Blascomillán',
    'Blasconuño de Matacabras',
    'Blascosancho',
    'Bohoyo',
    'Bonilla de la Sierra',
    'Brabos',
    'Bularros',
    'Burgohondo',
    'Cabezas de Alambre',
    'Cabezas del Pozo',
    'Cabezas del Villar',
    'Cabizuela',
    'Canales',
    'Candeleda',
    'Cantiveros',
    'Cardeñosa',
    'Casas del Puerto',
    'Casasola',
    'Casavieja',
    'Casillas',
    'Castellanos de Zapardiel',
    'Cebreros',
    'Cepeda la Mora',
    'Chamartín',
    'Cillán',
    'Cisla',
    'Collado de Contreras',
    'Collado del Mirón',
    'Constanzana',
    'Crespos',
    'Cuevas del Valle',
    'Diego del Carpio',
    'Donjimeno',
    'Donvidas',
    'El Arenal',
    'El Barco de Ávila',
    'El Barraco',
    'El Bohodón',
    'El Fresno',
    'El Hornillo',
    'El Hoyo de Pinares',
    'El Losar del Barco',
    'El Mirón',
    'El Oso',
    'El Parral',
    'El Tiemblo',
    'Espinosa de los Caballeros',
    'Flores de Ávila',
    'Fontiveros',
    'Fresnedilla',
    'Fuente el Saúz',
    'Fuentes de Año',
    'Gallegos de Altamiros',
    'Gallegos de Sobrinos',
    'Garganta del Villar',
    'Gavilanes',
    'Gemuño',
    'Gil García',
    'Gilbuena',
    'Gimialcón',
    'Gotarrendura',
    'Grandes y San Martín',
    'Guisando',
    'Gutierre-Muñoz',
    'Hernansancho',
    'Herradón de Pinares',
    'Herreros de Suso',
    'Higuera de las Dueñas',
    'Horcajo de las Torres',
    'Hoyocasero',
    'Hoyorredondo',
    'Hoyos de Miguel Muñoz',
    'Hoyos del Collado',
    'Hoyos del Espino',
    'Hurtumpascual',
    'Junciana',
    'La Adrada',
    'La Aldehuela',
    'La Carrera',
    'La Colilla',
    'La Hija de Dios',
    'La Horcajada',
    'La Serrada',
    'La Torre',
    'Langa',
    'Lanzahíta',
    'Las Berlanas',
    'Las Navas del Marqués',
    'Los Llanos de Tormes',
    'Madrigal de las Altas Torres',
    'Maello',
    'Malpartida de Corneja',
    'Mamblas',
    'Mancera de Arriba',
    'Manjabálago',
    'Marlín',
    'Martiherrero',
    'Martínez',
    'Mediana de Voltoya',
    'Medinilla',
    'Mengamuñoz',
    'Mesegar de Corneja',
    'Mijares',
    'Mingorría',
    'Mironcillo',
    'Mirueña de los Infanzones',
    'Mombeltrán',
    'Monsalupe',
    'Moraleja de Matacabras',
    'Muñana',
    'Muñico',
    'Muñogalindo',
    'Muñogrande',
    'Muñomer del Peco',
    'Muñopepe',
    'Muñosancho',
    'Muñotello',
    'Narrillos del Álamo',
    'Narrillos del Rebollar',
    'Narros de Saldueña',
    'Narros del Castillo',
    'Narros del Puerto',
    'Nava de Arévalo',
    'Nava del Barco',
    'Navacepedilla de Corneja',
    'Navadijos',
    'Navaescurial',
    'Navahondilla',
    'Navalacruz',
    'Navalmoral',
    'Navalonguilla',
    'Navalosa',
    'Navalperal de Pinares',
    'Navalperal de Tormes',
    'Navaluenga',
    'Navaquesera',
    'Navarredonda de Gredos',
    'Navarredondilla',
    'Navarrevisca',
    'Navatalgordo',
    'Navatejares',
    'Neila de San Miguel',
    'Niharra',
    'Ojos-Albos',
    'Orbita',
    'Padiernos',
    'Pajares de Adaja',
    'Palacios de Goda',
    'Papatrigo',
    'Pascualcobo',
    'Pedro Bernardo',
    'Pedro-Rodríguez',
    'Peguerinos',
    'Peñalba de Ávila',
    'Piedrahíta',
    'Piedralaves',
    'Poveda',
    'Poyales del Hoyo',
    'Pozanco',
    'Pradosegar',
    'Puerto Castilla',
    'Rasueros',
    'Riocabado',
    'Riofrío',
    'Rivilla de Barajas',
    'Salobral',
    'Salvadiós',
    'San Bartolomé de Béjar',
    'San Bartolomé de Corneja',
    'San Bartolomé de Pinares',
    'San Esteban de los Patos',
    'San Esteban de Zapardiel',
    'San Esteban del Valle',
    'San García de Ingelmos',
    'San Juan de Gredos',
    'San Juan de la Encinilla',
    'San Juan de la Nava',
    'San Juan del Molinillo',
    'San Juan del Olmo',
    'San Lorenzo de Tormes',
    'San Martín de la Vega del Alberche',
    'San Martín del Pimpollar',
    'San Miguel de Corneja',
    'San Miguel de Serrezuela',
    'San Pascual',
    'San Pedro del Arroyo',
    'San Vicente de Arévalo',
    'Sanchidrián',
    'Sanchorreja',
    'Santa Cruz de Pinares',
    'Santa Cruz del Valle',
    'Santa María de los Caballeros',
    'Santa María del Arroyo',
    'Santa María del Berrocal',
    'Santa María del Cubillo',
    'Santa María del Tiétar',
    'Santiago del Collado',
    'Santiago del Tormes',
    'Santo Domingo de las Posadas',
    'Santo Tomé de Zabarcos',
    'Serranillos',
    'Sigeres',
    'Sinlabajos',
    'Solana de Ávila',
    'Solana de Rioalmar',
    'Solosancho',
    'Sotalbo',
    'Sotillo de la Adrada',
    'Tiñosillos',
    'Tolbaños',
    'Tormellas',
    'Tornadizos de Ávila',
    'Tórtoles',
    'Umbrías',
    'Vadillo de la Sierra',
    'Valdecasa',
    'Vega de Santa María',
    'Velayos',
    'Villaflor',
    'Villafranca de la Sierra',
    'Villanueva de Ávila',
    'Villanueva de Gómez',
    'Villanueva del Aceral',
    'Villanueva del Campillo',
    'Villar de Corneja',
    'Villarejo del Valle',
    'Villatoro',
    'Viñegra de Moraña',
    'Vita',
    'Zapardiel de la Cañada',
    'Zapardiel de la Ribera',
  ],
  Badajoz: [
    'Acedera',
    'Aceuchal',
    'Ahillones',
    'Alange',
    'Alburquerque',
    'Alconchel',
    'Alconera',
    'Aljucén',
    'Almendral',
    'Almendralejo',
    'Arroyo de San Serván',
    'Atalaya',
    'Azuaga',
    'Badajoz',
    'Barcarrota',
    'Baterno',
    'Benquerencia de la Serena',
    'Berlanga',
    'Bienvenida',
    'Bodonal de la Sierra',
    'Burguillos del Cerro',
    'Cabeza del Buey',
    'Cabeza la Vaca',
    'Calamonte',
    'Calera de León',
    'Calzadilla de los Barros',
    'Campanario',
    'Campillo de Llerena',
    'Capilla',
    'Carmonita',
    'Casas de Don Pedro',
    'Casas de Reina',
    'Castilblanco',
    'Castuera',
    'Cheles',
    'Cordobilla de Lácara',
    'Corte de Peleas',
    'Cristina',
    'Don Álvaro',
    'Don Benito',
    'El Carrascalejo',
    'Entrín Bajo',
    'Esparragalejo',
    'Esparragosa de la Serena',
    'Esparragosa de Lares',
    'Feria',
    'Fregenal de la Sierra',
    'Fuenlabrada de los Montes',
    'Fuente de Cantos',
    'Fuente del Arco',
    'Fuente del Maestre',
    'Fuentes de León',
    'Garbayuela',
    'Garlitos',
    'Granja de Torrehermosa',
    'Guareña',
    'Helechosa de los Montes',
    'Herrera del Duque',
    'Higuera de la Serena',
    'Higuera de Llerena',
    'Higuera de Vargas',
    'Higuera la Real',
    'Hinojosa del Valle',
    'Hornachos',
    'Jerez de los Caballeros',
    'La Albuera',
    'La Codosera',
    'La Coronada',
    'La Garrovilla',
    'La Haba',
    'La Lapa',
    'La Morera',
    'La Nava de Santiago',
    'La Parra',
    'La Roca de la Sierra',
    'La Zarza',
    'Llera',
    'Llerena',
    'Lobón',
    'Los Santos de Maimona',
    'Magacela',
    'Maguilla',
    'Malcocinado',
    'Malpartida de la Serena',
    'Manchita',
    'Medellín',
    'Medina de las Torres',
    'Mengabril',
    'Mérida',
    'Mirandilla',
    'Monesterio',
    'Montemolín',
    'Monterrubio de la Serena',
    'Montijo',
    'Navalvillar de Pela',
    'Nogales',
    'Oliva de la Frontera',
    'Oliva de Mérida',
    'Olivenza',
    'Orellana de la Sierra',
    'Orellana la Vieja',
    'Palomas',
    'Peñalsordo',
    'Peraleda del Zaucejo',
    'Puebla de Alcocer',
    'Puebla de la Calzada',
    'Puebla de la Reina',
    'Puebla de Obando',
    'Puebla de Sancho Pérez',
    'Puebla del Maestre',
    'Puebla del Prior',
    'Pueblonuevo del Guadiana',
    'Quintana de la Serena',
    'Reina',
    'Rena',
    'Retamal de Llerena',
    'Ribera del Fresno',
    'Risco',
    'Salvaleón',
    'Salvatierra de los Barros',
    'San Pedro de Mérida',
    'San Vicente de Alcántara',
    'Sancti-Spíritus',
    'Santa Amalia',
    'Santa Marta',
    'Segura de León',
    'Siruela',
    'Solana de los Barros',
    'Talarrubias',
    'Talavera la Real',
    'Táliga',
    'Tamurejo',
    'Torre de Miguel Sesmero',
    'Torremayor',
    'Torremejía',
    'Trasierra',
    'Trujillanos',
    'Usagre',
    'Valdecaballeros',
    'Valdelacalzada',
    'Valdetorres',
    'Valencia de las Torres',
    'Valencia del Mombuey',
    'Valencia del Ventoso',
    'Valle de la Serena',
    'Valle de Matamoros',
    'Valle de Santa Ana',
    'Valverde de Burguillos',
    'Valverde de Leganés',
    'Valverde de Llerena',
    'Valverde de Mérida',
    'Villafranca de los Barros',
    'Villagarcía de la Torre',
    'Villagonzalo',
    'Villalba de los Barros',
    'Villanueva de la Serena',
    'Villanueva del Fresno',
    'Villar de Rena',
    'Villar del Rey',
    'Villarta de los Montes',
    'Zafra',
    'Zahínos',
    'Zalamea de la Serena',
  ],
  Barcelona: [
    'Abrera',
    'Aguilar de Segarra',
    'Aiguafreda',
    'Alella',
    'Alpens',
    'Arenys de Mar',
    'Arenys de Munt',
    'Argençola',
    'Argentona',
    'Artés',
    'Avià',
    'Avinyó',
    'Avinyonet del Penedès',
    'Badalona',
    'Badia del Vallès',
    'Bagà',
    'Balenyà',
    'Balsareny',
    'Barberà del Vallès',
    'Barcelona',
    'Begues',
    'Bellprat',
    'Berga',
    'Bigues i Riells',
    'Borredà',
    "Cabrera d'Anoia",
    'Cabrera de Mar',
    'Cabrils',
    'Calaf',
    'Calders',
    'Caldes de Montbui',
    "Caldes d'Estrac",
    'Calella',
    'Calldetenes',
    'Callús',
    'Calonge de Segarra',
    'Campins',
    'Canet de Mar',
    'Canovelles',
    'Cànoves i Samalús',
    'Canyelles',
    'Capellades',
    'Capolat',
    'Cardedeu',
    'Cardona',
    'Carme',
    'Casserres',
    "Castell de l'Areny",
    "Castellar de n'Hug",
    'Castellar del Riu',
    'Castellar del Vallès',
    'Castellbell i el Vilar',
    'Castellbisbal',
    'Castellcir',
    'Castelldefels',
    'Castellet i la Gornal',
    'Castellfollit de Riubregós',
    'Castellfollit del Boix',
    'Castellgalí',
    'Castellnou de Bages',
    'Castellolí',
    'Castellterçol',
    'Castellví de la Marca',
    'Castellví de Rosanes',
    'Centelles',
    'Cercs',
    'Cerdanyola del Vallès',
    'Cervelló',
    'Collbató',
    'Collsuspina',
    'Copons',
    'Corbera de Llobregat',
    'Cornellà de Llobregat',
    'Cubelles',
    'Dosrius',
    'El Bruc',
    'El Brull',
    'El Masnou',
    'El Papiol',
    'El Pla del Penedès',
    'El Pont de Vilomara i Rocafort',
    'El Prat de Llobregat',
    'Els Hostalets de Pierola',
    'Els Prats de Rei',
    'Esparreguera',
    'Esplugues de Llobregat',
    'Figaró-Montmany',
    'Fígols',
    'Fogars de la Selva',
    'Fogars de Montclús',
    'Folgueroles',
    'Fonollosa',
    'Font-rubí',
    'Gaià',
    'Gallifa',
    'Gavà',
    'Gelida',
    'Gironella',
    'Gisclareny',
    'Granera',
    'Granollers',
    'Gualba',
    'Guardiola de Berguedà',
    'Gurb',
    'Igualada',
    'Jorba',
    "L' Ametlla del Vallès",
    "L' Espunyola",
    "L' Estany",
    "L' Hospitalet de Llobregat",
    'La Garriga',
    'La Granada',
    'La Llacuna',
    'La Llagosta',
    'La Nou de Berguedà',
    'La Palma de Cervelló',
    'La Pobla de Claramunt',
    'La Pobla de Lillet',
    'La Quar',
    'La Roca del Vallès',
    'La Torre de Claramunt',
    'Les Cabanyes',
    'Les Franqueses del Vallès',
    'Les Masies de Roda',
    'Les Masies de Voltregà',
    "Lliçà d'Amunt",
    'Lliçà de Vall',
    'Llinars del Vallès',
    'Lluçà',
    'Malgrat de Mar',
    'Malla',
    'Manlleu',
    'Manresa',
    'Marganell',
    'Martorell',
    'Martorelles',
    'Masquefa',
    'Matadepera',
    'Mataró',
    'Mediona',
    'Moià',
    'Molins de Rei',
    'Mollet del Vallès',
    'Monistrol de Calders',
    'Monistrol de Montserrat',
    'Montcada i Reixac',
    'Montclar',
    'Montesquiu',
    'Montgat',
    'Montmajor',
    'Montmaneu',
    'Montmeló',
    'Montornès del Vallès',
    'Montseny',
    'Muntanyola',
    'Mura',
    'Navarcles',
    'Navàs',
    'Òdena',
    'Olèrdola',
    'Olesa de Bonesvalls',
    'Olesa de Montserrat',
    'Olivella',
    'Olost',
    'Olvan',
    'Orís',
    'Oristà',
    'Orpí',
    'Òrrius',
    'Pacs del Penedès',
    'Palafolls',
    'Palau-solità i Plegamans',
    'Pallejà',
    'Parets del Vallès',
    'Perafita',
    'Piera',
    'Pineda de Mar',
    'Polinyà',
    'Pontons',
    'Prats de Lluçanès',
    'Premià de Dalt',
    'Premià de Mar',
    'Puigdàlber',
    'Puig-reig',
    'Pujalt',
    'Rajadell',
    'Rellinars',
    'Ripollet',
    'Roda de Ter',
    'Rubí',
    'Rubió',
    'Rupit i Pruit',
    'Sabadell',
    'Sagàs',
    'Saldes',
    'Sallent',
    'Sant Adrià de Besòs',
    'Sant Agustí de Lluçanès',
    'Sant Andreu de la Barca',
    'Sant Andreu de Llavaneres',
    'Sant Antoni de Vilamajor',
    'Sant Bartomeu del Grau',
    'Sant Boi de Llobregat',
    'Sant Boi de Lluçanès',
    'Sant Cebrià de Vallalta',
    'Sant Celoni',
    'Sant Climent de Llobregat',
    'Sant Cugat del Vallès',
    'Sant Cugat Sesgarrigues',
    'Sant Esteve de Palautordera',
    'Sant Esteve Sesrovires',
    'Sant Feliu de Codines',
    'Sant Feliu de Llobregat',
    'Sant Feliu Sasserra',
    'Sant Fost de Campsentelles',
    'Sant Fruitós de Bages',
    'Sant Hipòlit de Voltregà',
    'Sant Iscle de Vallalta',
    'Sant Jaume de Frontanyà',
    'Sant Joan de Vilatorrada',
    'Sant Joan Despí',
    'Sant Julià de Cerdanyola',
    'Sant Julià de Vilatorta',
    'Sant Just Desvern',
    "Sant Llorenç d'Hortons",
    'Sant Llorenç Savall',
    "Sant Martí d'Albars",
    'Sant Martí de Centelles',
    'Sant Martí de Tous',
    'Sant Martí Sarroca',
    'Sant Martí Sesgueioles',
    'Sant Mateu de Bages',
    'Sant Pere de Ribes',
    'Sant Pere de Riudebitlles',
    'Sant Pere de Torelló',
    'Sant Pere de Vilamajor',
    'Sant Pere Sallavinera',
    'Sant Pol de Mar',
    'Sant Quintí de Mediona',
    'Sant Quirze de Besora',
    'Sant Quirze del Vallès',
    'Sant Quirze Safaja',
    "Sant Sadurní d'Anoia",
    "Sant Sadurní d'Osormort",
    'Sant Salvador de Guardiola',
    'Sant Vicenç de Castellet',
    'Sant Vicenç de Montalt',
    'Sant Vicenç de Torelló',
    'Sant Vicenç dels Horts',
    'Santa Cecília de Voltregà',
    'Santa Coloma de Cervelló',
    'Santa Coloma de Gramenet',
    'Santa Eugènia de Berga',
    'Santa Eulàlia de Riuprimer',
    'Santa Eulàlia de Ronçana',
    'Santa Fe del Penedès',
    'Santa Margarida de Montbui',
    'Santa Margarida i els Monjos',
    'Santa Maria de Besora',
    'Santa Maria de Corcó',
    'Santa Maria de Martorelles',
    'Santa Maria de Merlès',
    'Santa Maria de Miralles',
    'Santa Maria de Palautordera',
    "Santa Maria d'Oló",
    'Santa Perpètua de Mogoda',
    'Santa Susanna',
    'Santpedor',
    'Sentmenat',
    'Seva',
    'Sitges',
    'Sobremunt',
    'Sora',
    'Subirats',
    'Súria',
    'Tagamanent',
    'Talamanca',
    'Taradell',
    'Tavèrnoles',
    'Tavertet',
    'Teià',
    'Terrassa',
    'Tiana',
    'Tona',
    'Tordera',
    'Torelló',
    'Torrelavit',
    'Torrelles de Foix',
    'Torrelles de Llobregat',
    'Ullastrell',
    'Vacarisses',
    "Vallbona d'Anoia",
    'Vallcebre',
    'Vallgorguina',
    'Vallirana',
    'Vallromanes',
    'Veciana',
    'Vic',
    'Vilada',
    'Viladecans',
    'Viladecavalls',
    'Vilafranca del Penedès',
    'Vilalba Sasserra',
    'Vilanova de Sau',
    'Vilanova del Camí',
    'Vilanova del Vallès',
    'Vilanova i la Geltrú',
    'Vilassar de Dalt',
    'Vilassar de Mar',
    'Vilobí del Penedès',
    'Viver i Serrateix',
  ],
  Burgos: [
    'Abajas',
    'Adrada de Haza',
    'Aguas Cándidas',
    'Aguilar de Bureba',
    'Albillos',
    'Alcocero de Mola',
    'Alfoz de Bricia',
    'Alfoz de Quintanadueñas',
    'Alfoz de Santa Gadea',
    'Altable',
    'Ameyugo',
    'Anguix',
    'Aranda de Duero',
    'Arandilla',
    'Arauzo de Miel',
    'Arauzo de Salce',
    'Arauzo de Torre',
    'Arcos',
    'Arenillas de Riopisuerga',
    'Arija',
    'Arlanzón',
    'Arraya de Oca',
    'Atapuerca',
    'Avellanosa de Muñó',
    'Bahabón de Esgueva',
    'Baños de Valdearados',
    'Bañuelos de Bureba',
    'Barbadillo de Herreros',
    'Barbadillo del Mercado',
    'Barbadillo del Pez',
    'Barrio de Muñó',
    'Barrios de Colina',
    'Basconcillos del Tozo',
    'Bascuñana',
    'Belbimbre',
    'Belorado',
    'Berberana',
    'Berlangas de Roa',
    'Berzosa de Bureba',
    'Bozoó',
    'Brazacorta',
    'Briviesca',
    'Bugedo',
    'Buniel',
    'Burgos',
    'Busto de Bureba',
    'Cabañes de Esgueva',
    'Cabezón de la Sierra',
    'Caleruega',
    'Campillo de Aranda',
    'Campolara',
    'Canicosa de la Sierra',
    'Cantabrana',
    'Carazo',
    'Carcedo de Bureba',
    'Carcedo de Burgos',
    'Cardeñadijo',
    'Cardeñajimeno',
    'Cardeñuela Riopico',
    'Carrias',
    'Cascajares de Bureba',
    'Cascajares de la Sierra',
    'Castellanos de Castro',
    'Castil de Peones',
    'Castildelgado',
    'Castrillo de la Reina',
    'Castrillo de la Vega',
    'Castrillo de Riopisuerga',
    'Castrillo del Val',
    'Castrillo Matajudíos',
    'Castrojeriz',
    'Cavia',
    'Cayuela',
    'Cebrecos',
    'Celada del Camino',
    'Cerezo de Río Tirón',
    'Cerratón de Juarros',
    'Ciadoncha',
    'Cillaperlata',
    'Cilleruelo de Abajo',
    'Cilleruelo de Arriba',
    'Ciruelos de Cervera',
    'Cogollos',
    'Condado de Treviño',
    'Contreras',
    'Coruña del Conde',
    'Covarrubias',
    'Cubillo del Campo',
    'Cubo de Bureba',
    'Cuevas de San Clemente',
    'Encío',
    'Espinosa de Cervera',
    'Espinosa de los Monteros',
    'Espinosa del Camino',
    'Estépar',
    'Fontioso',
    'Frandovínez',
    'Fresneda de la Sierra Tirón',
    'Fresneña',
    'Fresnillo de las Dueñas',
    'Fresno de Río Tirón',
    'Fresno de Rodilla',
    'Frías',
    'Fuentebureba',
    'Fuentecén',
    'Fuentelcésped',
    'Fuentelisendo',
    'Fuentemolinos',
    'Fuentenebro',
    'Fuentespina',
    'Galbarros',
    'Grijalba',
    'Grisaleña',
    'Gumiel de Izán',
    'Gumiel de Mercado',
    'Hacinas',
    'Haza',
    'Hontanas',
    'Hontangas',
    'Hontoria de la Cantera',
    'Hontoria de Valdearados',
    'Hontoria del Pinar',
    'Hornillos del Camino',
    'Hortigüela',
    'Hoyales de Roa',
    'Huérmeces',
    'Huerta de Arriba',
    'Huerta de Rey',
    'Humada',
    'Hurones',
    'Ibeas de Juarros',
    'Ibrillos',
    'Iglesiarrubia',
    'Iglesias',
    'Isar',
    'Itero del Castillo',
    'Jaramillo de la Fuente',
    'Jaramillo Quemado',
    'Junta de Traslaloma',
    'Junta de Villalba de Losa',
    'Jurisdicción de Lara',
    'Jurisdicción de San Zadornil',
    'La Cueva de Roa',
    'La Gallega',
    'La Horra',
    'La Puebla de Arganzón',
    'La Revilla y Ahedo',
    'La Sequera de Haza',
    'La Vid de Bureba',
    'La Vid y Barrios',
    'Las Hormazas',
    'Las Quintanillas',
    'Lerma',
    'Llano de Bureba',
    'Los Altos',
    'Los Ausines',
    'Los Balbases',
    'Los Barrios de Bureba',
    'Madrigal del Monte',
    'Madrigalejo del Monte',
    'Mahamud',
    'Mambrilla de Castrejón',
    'Mambrillas de Lara',
    'Mamolar',
    'Manciles',
    'Mazuela',
    'Mecerreyes',
    'Medina de Pomar',
    'Melgar de Fernamental',
    'Merindad de Cuesta-Urria',
    'Merindad de Montija',
    'Merindad de Río Ubierna',
    'Merindad de Sotoscueva',
    'Merindad de Valdeporres',
    'Merindad de Valdivielso',
    'Milagros',
    'Miranda de Ebro',
    'Miraveche',
    'Modúbar de la Emparedada',
    'Monasterio de la Sierra',
    'Monasterio de Rodilla',
    'Moncalvillo',
    'Monterrubio de la Demanda',
    'Montorio',
    'Moradillo de Roa',
    'Nava de Roa',
    'Navas de Bureba',
    'Nebreda',
    'Neila',
    'Olmedillo de Roa',
    'Olmillos de Muñó',
    'Oña',
    'Oquillas',
    'Orbaneja Riopico',
    'Padilla de Abajo',
    'Padilla de Arriba',
    'Padrones de Bureba',
    'Palacios de la Sierra',
    'Palacios de Riopisuerga',
    'Palazuelos de la Sierra',
    'Palazuelos de Muñó',
    'Pampliega',
    'Pancorbo',
    'Pardilla',
    'Partido de la Sierra en Tobalina',
    'Pedrosa de Duero',
    'Pedrosa de Río Úrbel',
    'Pedrosa del Páramo',
    'Pedrosa del Príncipe',
    'Peñaranda de Duero',
    'Peral de Arlanza',
    'Piérnigas',
    'Pineda de la Sierra',
    'Pineda Trasmonte',
    'Pinilla de los Barruecos',
    'Pinilla de los Moros',
    'Pinilla Trasmonte',
    'Poza de la Sal',
    'Prádanos de Bureba',
    'Pradoluengo',
    'Presencio',
    'Puentedura',
    'Quemada',
    'Quintana del Pidio',
    'Quintanabureba',
    'Quintanaélez',
    'Quintanaortuño',
    'Quintanapalla',
    'Quintanar de la Sierra',
    'Quintanavides',
    'Quintanilla de la Mata',
    'Quintanilla del Agua y Tordueles',
    'Quintanilla del Coco',
    'Quintanilla San García',
    'Quintanilla Vivar',
    'Rabanera del Pinar',
    'Rábanos',
    'Rabé de las Calzadas',
    'Rebolledo de la Torre',
    'Redecilla del Camino',
    'Redecilla del Campo',
    'Regumiel de la Sierra',
    'Reinoso',
    'Retuerta',
    'Revilla del Campo',
    'Revilla Vallejera',
    'Revillarruz',
    'Rezmondo',
    'Riocavado de la Sierra',
    'Roa',
    'Rojas',
    'Royuela de Río Franco',
    'Rubena',
    'Rublacedo de Abajo',
    'Rucandio',
    'Salas de Bureba',
    'Salas de los Infantes',
    'Saldaña de Burgos',
    'Salinillas de Bureba',
    'San Adrián de Juarros',
    'San Juan del Monte',
    'San Mamés de Burgos',
    'San Martín de Rubiales',
    'San Millán de Lara',
    'San Vicente del Valle',
    'Santa Cecilia',
    'Santa Cruz de la Salceda',
    'Santa Cruz del Valle Urbión',
    'Santa Gadea del Cid',
    'Santa Inés',
    'Santa María del Campo',
    'Santa María del Invierno',
    'Santa María del Mercadillo',
    'Santa María Rivarredonda',
    'Santa Olalla de Bureba',
    'Santibáñez de Esgueva',
    'Santibáñez del Val',
    'Santo Domingo de Silos',
    'Sargentes de la Lora',
    'Sarracín',
    'Sasamón',
    'Solarana',
    'Sordillos',
    'Sotillo de la Ribera',
    'Sotragero',
    'Sotresgudo',
    'Susinos del Páramo',
    'Tamarón',
    'Tardajos',
    'Tejada',
    'Terradillos de Esgueva',
    'Tinieblas de la Sierra',
    'Tobar',
    'Tordómar',
    'Torrecilla del Monte',
    'Torregalindo',
    'Torrelara',
    'Torrepadre',
    'Torresandino',
    'Tórtoles de Esgueva',
    'Tosantos',
    'Trespaderne',
    'Tubilla del Agua',
    'Tubilla del Lago',
    'Úrbel del Castillo',
    'Vadocondes',
    'Valdeande',
    'Valdezate',
    'Valdorros',
    'Vallarta de Bureba',
    'Valle de las Navas',
    'Valle de Losa',
    'Valle de Manzanedo',
    'Valle de Mena',
    'Valle de Oca',
    'Valle de Santibáñez',
    'Valle de Sedano',
    'Valle de Tobalina',
    'Valle de Valdebezana',
    'Valle de Valdelaguna',
    'Valle de Valdelucio',
    'Valle de Zamanzas',
    'Vallejera',
    'Valles de Palenzuela',
    'Valluércanes',
    'Valmala',
    'Vileña',
    'Villadiego',
    'Villaescusa de Roa',
    'Villaescusa la Sombría',
    'Villaespasa',
    'Villafranca Montes de Oca',
    'Villafruela',
    'Villagalijo',
    'Villagonzalo Pedernales',
    'Villahoz',
    'Villalba de Duero',
    'Villalbilla de Burgos',
    'Villalbilla de Gumiel',
    'Villaldemiro',
    'Villalmanzo',
    'Villamayor de los Montes',
    'Villamayor de Treviño',
    'Villambistia',
    'Villamedianilla',
    'Villamiel de la Sierra',
    'Villangómez',
    'Villanueva de Argaño',
    'Villanueva de Carazo',
    'Villanueva de Gumiel',
    'Villanueva de Teba',
    'Villaquirán de la Puebla',
    'Villaquirán de los Infantes',
    'Villarcayo de Merindad de Castilla la Vieja',
    'Villariezo',
    'Villasandino',
    'Villasur de Herreros',
    'Villatuelda',
    'Villaverde del Monte',
    'Villaverde-Mogina',
    'Villayerno Morquillas',
    'Villazopeque',
    'Villegas',
    'Villoruebo',
    'Viloria de Rioja',
    'Vilviestre del Pinar',
    'Vizcaínos',
    'Zael',
    'Zarzosa de Río Pisuerga',
    'Zazuar',
    'Zuñeda',
  ],
  Cáceres: [
    'Abadía',
    'Abertura',
    'Acebo',
    'Acehúche',
    'Aceituna',
    'Ahigal',
    'Albalá',
    'Alcántara',
    'Alcollarín',
    'Alcuéscar',
    'Aldea del Cano',
    'Aldeacentenera',
    'Aldeanueva de la Vera',
    'Aldeanueva del Camino',
    'Aldehuela de Jerte',
    'Alía',
    'Aliseda',
    'Almaraz',
    'Almoharín',
    'Arroyo de la Luz',
    'Arroyomolinos',
    'Arroyomolinos de la Vera',
    'Baños de Montemayor',
    'Barrado',
    'Belvís de Monroy',
    'Benquerencia',
    'Berrocalejo',
    'Berzocana',
    'Bohonal de Ibor',
    'Botija',
    'Brozas',
    'Cabañas del Castillo',
    'Cabezabellosa',
    'Cabezuela del Valle',
    'Cabrero',
    'Cáceres',
    'Cachorrilla',
    'Cadalso',
    'Calzadilla',
    'Caminomorisco',
    'Campillo de Deleitosa',
    'Campo Lugar',
    'Cañamero',
    'Cañaveral',
    'Carbajo',
    'Carcaboso',
    'Carrascalejo',
    'Casar de Cáceres',
    'Casar de Palomero',
    'Casares de las Hurdes',
    'Casas de Don Antonio',
    'Casas de Don Gómez',
    'Casas de Millán',
    'Casas de Miravete',
    'Casas del Castañar',
    'Casas del Monte',
    'Casatejada',
    'Casillas de Coria',
    'Castañar de Ibor',
    'Ceclavín',
    'Cedillo',
    'Cerezo',
    'Cilleros',
    'Collado',
    'Conquista de la Sierra',
    'Coria',
    'Cuacos de Yuste',
    'Deleitosa',
    'Descargamaría',
    'El Gordo',
    'El Torno',
    'Eljas',
    'Escurial',
    'Fresnedoso de Ibor',
    'Galisteo',
    'Garciaz',
    'Garganta la Olla',
    'Gargantilla',
    'Gargüera',
    'Garrovillas de Alconétar',
    'Garvín',
    'Gata',
    'Guadalupe',
    'Guijo de Coria',
    'Guijo de Galisteo',
    'Guijo de Granadilla',
    'Guijo de Santa Bárbara',
    'Herguijuela',
    'Hernán-Pérez',
    'Herrera de Alcántara',
    'Herreruela',
    'Hervás',
    'Higuera',
    'Hinojal',
    'Holguera',
    'Hoyos',
    'Huélaga',
    'Ibahernando',
    'Jaraicejo',
    'Jaraíz de la Vera',
    'Jarandilla de la Vera',
    'Jarilla',
    'Jerte',
    'La Aldea del Obispo',
    'La Cumbre',
    'La Garganta',
    'La Granja',
    'La Pesga',
    'Ladrillar',
    'Logrosán',
    'Losar de la Vera',
    'Madrigal de la Vera',
    'Madrigalejo',
    'Madroñera',
    'Majadas',
    'Malpartida de Cáceres',
    'Malpartida de Plasencia',
    'Marchagaz',
    'Mata de Alcántara',
    'Membrío',
    'Mesas de Ibor',
    'Miajadas',
    'Millanes',
    'Mirabel',
    'Mohedas de Granadilla',
    'Monroy',
    'Montánchez',
    'Montehermoso',
    'Moraleja',
    'Morcillo',
    'Navaconcejo',
    'Navalmoral de la Mata',
    'Navalvillar de Ibor',
    'Navas del Madroño',
    'Navezuelas',
    'Nuñomoral',
    'Oliva de Plasencia',
    'Palomero',
    'Pasarón de la Vera',
    'Pedroso de Acim',
    'Peraleda de la Mata',
    'Peraleda de San Román',
    'Perales del Puerto',
    'Pescueza',
    'Piedras Albas',
    'Pinofranqueado',
    'Piornal',
    'Plasencia',
    'Plasenzuela',
    'Portaje',
    'Portezuelo',
    'Pozuelo de Zarzón',
    'Puerto de Santa Cruz',
    'Rebollar',
    'Riolobos',
    'Robledillo de Gata',
    'Robledillo de la Vera',
    'Robledillo de Trujillo',
    'Robledollano',
    'Romangordo',
    'Rosalejo',
    'Ruanes',
    'Salorino',
    'Salvatierra de Santiago',
    'San Martín de Trevejo',
    'Santa Ana',
    'Santa Cruz de la Sierra',
    'Santa Cruz de Paniagua',
    'Santa Marta de Magasca',
    'Santiago de Alcántara',
    'Santiago del Campo',
    'Santibáñez el Alto',
    'Santibáñez el Bajo',
    'Saucedilla',
    'Segura de Toro',
    'Serradilla',
    'Serrejón',
    'Sierra de Fuentes',
    'Talaván',
    'Talaveruela de la Vera',
    'Talayuela',
    'Tejeda de Tiétar',
    'Toril',
    'Tornavacas',
    'Torre de Don Miguel',
    'Torre de Santa María',
    'Torrecilla de los Ángeles',
    'Torrecillas de la Tiesa',
    'Torrejón el Rubio',
    'Torrejoncillo',
    'Torremenga',
    'Torremocha',
    'Torreorgaz',
    'Torrequemada',
    'Trujillo',
    'Valdastillas',
    'Valdecañas de Tajo',
    'Valdefuentes',
    'Valdehúncar',
    'Valdelacasa de Tajo',
    'Valdemorales',
    'Valdeobispo',
    'Valencia de Alcántara',
    'Valverde de la Vera',
    'Valverde del Fresno',
    'Viandar de la Vera',
    'Villa del Campo',
    'Villa del Rey',
    'Villamesías',
    'Villamiel',
    'Villanueva de la Sierra',
    'Villanueva de la Vera',
    'Villar de Plasencia',
    'Villar del Pedroso',
    'Villasbuenas de Gata',
    'Zarza de Granadilla',
    'Zarza de Montánchez',
    'Zarza la Mayor',
    'Zorita',
  ],
  Cádiz: [
    'Alcalá de los Gazules',
    'Alcalá del Valle',
    'Algar',
    'Algeciras',
    'Algodonales',
    'Arcos de la Frontera',
    'Barbate',
    'Benalup-Casas Viejas',
    'Benaocaz',
    'Bornos',
    'Cádiz',
    'Castellar de la Frontera',
    'Chiclana de la Frontera',
    'Chipiona',
    'Conil de la Frontera',
    'El Bosque',
    'El Gastor',
    'El Puerto de Santa María',
    'Espera',
    'Grazalema',
    'Jerez de la Frontera',
    'Jimena de la Frontera',
    'La Línea de la Concepción',
    'Los Barrios',
    'Medina-Sidonia',
    'Olvera',
    'Paterna de Rivera',
    'Prado del Rey',
    'Puerto Real',
    'Puerto Serrano',
    'Rota',
    'San Fernando',
    'San José del Valle',
    'San Roque',
    'Sanlúcar de Barrameda',
    'Setenil de las Bodegas',
    'Tarifa',
    'Torre Alháquime',
    'Trebujena',
    'Ubrique',
    'Vejer de la Frontera',
    'Villaluenga del Rosario',
    'Villamartín',
    'Zahara',
  ],
  Cantabria: [
    'Alfoz de Lloredo',
    'Ampuero',
    'Anievas',
    'Arenas de Iguña',
    'Argoños',
    'Arnuero',
    'Arredondo',
    'Bárcena de Cicero',
    'Bárcena de Pie de Concha',
    'Bareyo',
    'Cabezón de la Sal',
    'Cabezón de Liébana',
    'Cabuérniga',
    'Camaleño',
    'Camargo',
    'Campoo de Enmedio',
    'Campoo de Yuso',
    'Cartes',
    'Castañeda',
    'Castro-Urdiales',
    'Cieza',
    'Cillorigo de Liébana',
    'Colindres',
    'Comillas',
    'Corvera de Toranzo',
    'El Astillero',
    'Entrambasaguas',
    'Escalante',
    'Guriezo',
    'Hazas de Cesto',
    'Hermandad de Campoo de Suso',
    'Herrerías',
    'Lamasón',
    'Laredo',
    'Las Rozas de Valdearroyo',
    'Liendo',
    'Liérganes',
    'Limpias',
    'Los Corrales de Buelna',
    'Los Tojos',
    'Luena',
    'Marina de Cudeyo',
    'Mazcuerras',
    'Medio Cudeyo',
    'Meruelo',
    'Miengo',
    'Miera',
    'Molledo',
    'Noja',
    'Penagos',
    'Peñarrubia',
    'Pesaguero',
    'Pesquera',
    'Piélagos',
    'Polaciones',
    'Polanco',
    'Potes',
    'Puente Viesgo',
    'Ramales de la Victoria',
    'Rasines',
    'Reinosa',
    'Reocín',
    'Ribamontán al Mar',
    'Ribamontán al Monte',
    'Rionansa',
    'Riotuerto',
    'Ruente',
    'Ruesga',
    'Ruiloba',
    'San Felices de Buelna',
    'San Miguel de Aguayo',
    'San Pedro del Romeral',
    'San Roque de Riomiera',
    'San Vicente de la Barquera',
    'Santa Cruz de Bezana',
    'Santa María de Cayón',
    'Santander',
    'Santillana del Mar',
    'Santiurde de Reinosa',
    'Santiurde de Toranzo',
    'Santoña',
    'Saro',
    'Selaya',
    'Soba',
    'Solórzano',
    'Suances',
    'Torrelavega',
    'Tresviso',
    'Tudanca',
    'Udías',
    'Val de San Vicente',
    'Valdáliga',
    'Valdeolea',
    'Valdeprado del Río',
    'Valderredible',
    'Valle de Villaverde',
    'Vega de Liébana',
    'Vega de Pas',
    'Villacarriedo',
    'Villaescusa',
    'Villafufre',
    'Voto',
  ],
  'Castellón/Castelló': [
    'Aín',
    'Albocàsser',
    'Alcalà de Xivert',
    'Alcudia de Veo',
    'Alfondeguilla',
    'Algimia de Almonacid',
    'Almazora/Almassora',
    'Almedíjar',
    'Almenara',
    'Alquerías del Niño Perdido',
    'Altura',
    'Arañuel',
    'Ares del Maestre',
    'Argelita',
    'Artana',
    'Atzeneta del Maestrat',
    'Ayódar',
    'Azuébar',
    'Barracas',
    'Bejís',
    'Benafer',
    'Benafigos',
    'Benasal',
    'Benicarló',
    'Benicasim/Benicàssim',
    'Benlloch',
    'Betxí',
    'Borriana/Burriana',
    'Borriol',
    'Cabanes',
    'Càlig',
    'Canet lo Roig',
    'Castell de Cabres',
    'Castellfort',
    'Castellnovo',
    'Castellón de la Plana/Castelló de la Plana',
    'Castillo de Villamalefa',
    'Catí',
    'Caudiel',
    'Cervera del Maestre',
    'Chert/Xert',
    'Chilches/Xilxes',
    'Chodos/Xodos',
    'Chóvar',
    'Cinctorres',
    'Cirat',
    'Cortes de Arenoso',
    'Costur',
    'Culla',
    'El Toro',
    'Eslida',
    'Espadilla',
    'Fanzara',
    'Figueroles',
    'Forcall',
    'Fuente la Reina',
    'Fuentes de Ayódar',
    'Gaibiel',
    'Geldo',
    'Herbés',
    'Higueras',
    'Jérica',
    "L' Alcora",
    'La Jana',
    'La Llosa',
    'La Mata de Morella',
    'La Pobla de Benifassà',
    'La Pobla Tornesa',
    'La Salzadella',
    "La Torre d'En Besora",
    "La Torre d'en Doménec",
    "La Vall d'Uixó",
    'La Vilavella',
    'les Coves de Vinromà',
    'les Useras/Useres',
    'Lucena del Cid',
    'Ludiente',
    'Matet',
    'Moncofa',
    'Montán',
    'Montanejos',
    'Morella',
    'Navajas',
    'Nules',
    'Olocau del Rey',
    'Onda',
    'Oropesa del Mar/Orpesa',
    'Palanques',
    'Pavías',
    'Peníscola/Peñíscola',
    'Pina de Montalgrao',
    'Portell de Morella',
    'Puebla de Arenoso',
    'Ribesalbes',
    'Rossell',
    'Sacañet',
    'San Rafael del Río',
    'Sant Joan de Moró',
    'Sant Jordi/San Jorge',
    'Sant Mateu',
    'Santa Magdalena de Pulpis',
    'Sarratella',
    'Segorbe',
    'Sierra Engarcerán',
    'Soneja',
    'Sot de Ferrer',
    'Sueras/Suera',
    'Tales',
    'Teresa',
    'Tírig',
    'Todolella',
    'Toga',
    'Torás',
    'Torralba del Pinar',
    'Torreblanca',
    'Torrechiva',
    'Traiguera',
    "Vall d'Alba",
    'Vall de Almonacid',
    'Vallat',
    'Vallibona',
    'Vilafamés',
    "Vilanova d'Alcolea",
    'Vilar de Canes',
    'Vila-real',
    'Villafranca del Cid/Vilafranca',
    'Villahermosa del Río',
    'Villamalur',
    'Villanueva de Viver',
    'Villores',
    'Vinaròs',
    'Vistabella del Maestrazgo',
    'Viver',
    'Zorita del Maestrazgo',
    'Zucaina',
  ],
  Ceuta: ['Ceuta'],
  'Ciudad Real': [
    'Abenójar',
    'Agudo',
    'Alamillo',
    'Albaladejo',
    'Alcázar de San Juan',
    'Alcoba',
    'Alcolea de Calatrava',
    'Alcubillas',
    'Aldea del Rey',
    'Alhambra',
    'Almadén',
    'Almadenejos',
    'Almagro',
    'Almedina',
    'Almodóvar del Campo',
    'Almuradiel',
    'Anchuras',
    'Arenales de San Gregorio',
    'Arenas de San Juan',
    'Argamasilla de Alba',
    'Argamasilla de Calatrava',
    'Arroba de los Montes',
    'Ballesteros de Calatrava',
    'Bolaños de Calatrava',
    'Brazatortas',
    'Cabezarados',
    'Cabezarrubias del Puerto',
    'Calzada de Calatrava',
    'Campo de Criptana',
    'Cañada de Calatrava',
    'Caracuel de Calatrava',
    'Carrión de Calatrava',
    'Carrizosa',
    'Castellar de Santiago',
    'Chillón',
    'Ciudad Real',
    'Corral de Calatrava',
    'Cózar',
    'Daimiel',
    'El Robledo',
    'Fernán Caballero',
    'Fontanarejo',
    'Fuencaliente',
    'Fuenllana',
    'Fuente el Fresno',
    'Granátula de Calatrava',
    'Guadalmez',
    'Herencia',
    'Hinojosas de Calatrava',
    'Horcajo de los Montes',
    'La Solana',
    'Las Labores',
    'Llanos del Caudillo',
    'Los Cortijos',
    'Los Pozuelos de Calatrava',
    'Luciana',
    'Malagón',
    'Manzanares',
    'Membrilla',
    'Mestanza',
    'Miguelturra',
    'Montiel',
    'Moral de Calatrava',
    'Navalpino',
    'Navas de Estena',
    'Pedro Muñoz',
    'Picón',
    'Piedrabuena',
    'Poblete',
    'Porzuna',
    'Pozuelo de Calatrava',
    'Puebla de Don Rodrigo',
    'Puebla del Príncipe',
    'Puerto Lápice',
    'Puertollano',
    'Retuerta del Bullaque',
    'Ruidera',
    'Saceruela',
    'San Carlos del Valle',
    'San Lorenzo de Calatrava',
    'Santa Cruz de los Cáñamos',
    'Santa Cruz de Mudela',
    'Socuéllamos',
    'Solana del Pino',
    'Terrinches',
    'Tomelloso',
    'Torralba de Calatrava',
    'Torre de Juan Abad',
    'Torrenueva',
    'Valdemanco del Esteras',
    'Valdepeñas',
    'Valenzuela de Calatrava',
    'Villahermosa',
    'Villamanrique',
    'Villamayor de Calatrava',
    'Villanueva de la Fuente',
    'Villanueva de los Infantes',
    'Villanueva de San Carlos',
    'Villar del Pozo',
    'Villarrubia de los Ojos',
    'Villarta de San Juan',
    'Viso del Marqués',
  ],
  Cuenca: [
    'Abia de la Obispalía',
    'Alarcón',
    'Albaladejo del Cuende',
    'Albalate de las Nogueras',
    'Albendea',
    'Alcalá de la Vega',
    'Alcantud',
    'Alcázar del Rey',
    'Alcohujate',
    'Alconchel de la Estrella',
    'Algarra',
    'Aliaguilla',
    'Almendros',
    'Almodóvar del Pinar',
    'Almonacid del Marquesado',
    'Altarejos',
    'Arandilla del Arroyo',
    'Arcas del Villar',
    'Arcos de la Sierra',
    'Arguisuelas',
    'Arrancacepas',
    'Atalaya del Cañavate',
    'Barajas de Melo',
    'Barchín del Hoyo',
    'Bascuñana de San Pedro',
    'Beamud',
    'Belinchón',
    'Belmonte',
    'Belmontejo',
    'Beteta',
    'Boniches',
    'Buciegas',
    'Buenache de Alarcón',
    'Buenache de la Sierra',
    'Buendía',
    'Campillo de Altobuey',
    'Campillos-Paravientos',
    'Campillos-Sierra',
    'Campos del Paraíso',
    'Canalejas del Arroyo',
    'Cañada del Hoyo',
    'Cañada Juncosa',
    'Cañamares',
    'Cañaveras',
    'Cañaveruelas',
    'Cañete',
    'Cañizares',
    'Carboneras de Guadazaón',
    'Cardenete',
    'Carrascosa',
    'Carrascosa de Haro',
    'Casas de Benítez',
    'Casas de Fernando Alonso',
    'Casas de Garcimolina',
    'Casas de Guijarro',
    'Casas de Haro',
    'Casas de los Pinos',
    'Casasimarro',
    'Castejón',
    'Castillejo de Iniesta',
    'Castillejo-Sierra',
    'Castillo de Garcimuñoz',
    'Castillo-Albaráñez',
    'Cervera del Llano',
    'Chillarón de Cuenca',
    'Chumillas',
    'Cuenca',
    'Cueva del Hierro',
    'El Acebrón',
    'El Cañavate',
    'El Herrumblar',
    'El Hito',
    'El Pedernoso',
    'El Peral',
    'El Picazo',
    'El Pozuelo',
    'El Provencio',
    'Enguídanos',
    'Fresneda de Altarejos',
    'Fresneda de la Sierra',
    'Fuente de Pedro Naharro',
    'Fuentelespino de Haro',
    'Fuentelespino de Moya',
    'Fuentenava de Jábaga',
    'Fuentes',
    'Fuertescusa',
    'Gabaldón',
    'Garaballa',
    'Gascueña',
    'Graja de Campalbo',
    'Graja de Iniesta',
    'Henarejos',
    'Honrubia',
    'Hontanaya',
    'Hontecillas',
    'Horcajo de Santiago',
    'Huélamo',
    'Huelves',
    'Huérguina',
    'Huerta de la Obispalía',
    'Huerta del Marquesado',
    'Huete',
    'Iniesta',
    'La Alberca de Záncara',
    'La Almarcha',
    'La Cierva',
    'La Frontera',
    'La Hinojosa',
    'La Parra de las Vegas',
    'La Peraleja',
    'La Pesquera',
    'Laguna del Marquesado',
    'Lagunaseca',
    'Landete',
    'Las Majadas',
    'Las Mesas',
    'Las Pedroñeras',
    'Las Valeras',
    'Ledaña',
    'Leganiel',
    'Los Hinojosos',
    'Los Valdecolmenas',
    'Mariana',
    'Masegosa',
    'Minglanilla',
    'Mira',
    'Monreal del Llano',
    'Montalbanejo',
    'Montalbo',
    'Monteagudo de las Salinas',
    'Mota de Altarejos',
    'Mota del Cuervo',
    'Motilla del Palancar',
    'Moya',
    'Narboneta',
    'Olivares de Júcar',
    'Olmeda de la Cuesta',
    'Olmeda del Rey',
    'Olmedilla de Alarcón',
    'Olmedilla de Eliz',
    'Osa de la Vega',
    'Pajarón',
    'Pajaroncillo',
    'Palomares del Campo',
    'Palomera',
    'Paracuellos',
    'Paredes',
    'Pinarejo',
    'Pineda de Gigüela',
    'Piqueras del Castillo',
    'Portalrubio de Guadamejud',
    'Portilla',
    'Poyatos',
    'Pozoamargo',
    'Pozorrubielos de la Mancha',
    'Pozorrubio',
    'Priego',
    'Puebla de Almenara',
    'Puebla de Don Francisco',
    'Puebla del Salvador',
    'Quintanar del Rey',
    'Rada de Haro',
    'Reíllo',
    'Rozalén del Monte',
    'Saceda-Trasierra',
    'Saelices',
    'Salinas del Manzano',
    'Salmeroncillos',
    'Salvacañete',
    'San Clemente',
    'San Lorenzo de la Parrilla',
    'San Martín de Boniches',
    'San Pedro Palmiches',
    'Santa Cruz de Moya',
    'Santa María de los Llanos',
    'Santa María del Campo Rus',
    'Santa María del Val',
    'Sisante',
    'Solera de Gabaldón',
    'Sotorribas',
    'Talayuelas',
    'Tarancón',
    'Tébar',
    'Tejadillos',
    'Tinajas',
    'Torralba',
    'Torrejoncillo del Rey',
    'Torrubia del Campo',
    'Torrubia del Castillo',
    'Tragacete',
    'Tresjuncos',
    'Tribaldos',
    'Uclés',
    'Uña',
    'Valdemeca',
    'Valdemorillo de la Sierra',
    'Valdemoro-Sierra',
    'Valdeolivas',
    'Valdetórtola',
    'Valhermoso de la Fuente',
    'Valsalobre',
    'Valverde de Júcar',
    'Valverdejo',
    'Vara de Rey',
    'Vega del Codorno',
    'Vellisca',
    'Villaconejos de Trabaque',
    'Villaescusa de Haro',
    'Villagarcía del Llano',
    'Villalba de la Sierra',
    'Villalba del Rey',
    'Villalgordo del Marquesado',
    'Villalpardo',
    'Villamayor de Santiago',
    'Villanueva de Guadamejud',
    'Villanueva de la Jara',
    'Villar de Cañas',
    'Villar de Domingo García',
    'Villar de la Encina',
    'Villar de Olalla',
    'Villar del Humo',
    'Villar del Infantado',
    'Villar y Velasco',
    'Villarejo de Fuentes',
    'Villarejo de la Peñuela',
    'Villarejo-Periesteban',
    'Villares del Saz',
    'Villarrubio',
    'Villarta',
    'Villas de la Ventosa',
    'Villaverde y Pasaconsol',
    'Víllora',
    'Vindel',
    'Yémeda',
    'Zafra de Záncara',
    'Zafrilla',
    'Zarza de Tajo',
    'Zarzuela',
  ],
  Córdoba: [
    'Adamuz',
    'Aguilar de la Frontera',
    'Alcaracejos',
    'Almedinilla',
    'Almodóvar del Río',
    'Añora',
    'Baena',
    'Belalcázar',
    'Belmez',
    'Benamejí',
    'Bujalance',
    'Cabra',
    'Cañete de las Torres',
    'Carcabuey',
    'Cardeña',
    'Castro del Río',
    'Conquista',
    'Córdoba',
    'Doña Mencía',
    'Dos Torres',
    'El Carpio',
    'El Guijo',
    'El Viso',
    'Encinas Reales',
    'Espejo',
    'Espiel',
    'Fernán-Núñez',
    'Fuente la Lancha',
    'Fuente Obejuna',
    'Fuente Palmera',
    'Fuente-Tójar',
    'Guadalcázar',
    'Hinojosa del Duque',
    'Hornachuelos',
    'Iznájar',
    'La Carlota',
    'La Granjuela',
    'La Rambla',
    'La Victoria',
    'Los Blázquez',
    'Lucena',
    'Luque',
    'Montalbán de Córdoba',
    'Montemayor',
    'Montilla',
    'Montoro',
    'Monturque',
    'Moriles',
    'Nueva Carteya',
    'Obejo',
    'Palenciana',
    'Palma del Río',
    'Pedro Abad',
    'Pedroche',
    'Peñarroya-Pueblonuevo',
    'Posadas',
    'Pozoblanco',
    'Priego de Córdoba',
    'Puente Genil',
    'Rute',
    'San Sebastián de los Ballesteros',
    'Santa Eufemia',
    'Santaella',
    'Torrecampo',
    'Valenzuela',
    'Valsequillo',
    'Villa del Río',
    'Villafranca de Córdoba',
    'Villaharta',
    'Villanueva de Córdoba',
    'Villanueva del Duque',
    'Villanueva del Rey',
    'Villaralto',
    'Villaviciosa de Córdoba',
    'Zuheros',
  ],
  Guipúzcoa: [
    'Abaltzisketa',
    'Aduna',
    'Aia',
    'Aizarnazabal',
    'Albiztur',
    'Alegia',
    'Alkiza',
    'Altzaga',
    'Altzo',
    'Amezketa',
    'Andoain',
    'Anoeta',
    'Antzuola',
    'Arama',
    'Aretxabaleta',
    'Arrasate/Mondragón',
    'Asteasu',
    'Astigarraga',
    'Ataun',
    'Azkoitia',
    'Azpeitia',
    'Baliarrain',
    'Beasain',
    'Beizama',
    'Belauntza',
    'Berastegi',
    'Bergara',
    'Berrobi',
    'Bidegoian',
    'Deba',
    'Donostia-San Sebastián',
    'Eibar',
    'Elduain',
    'Elgeta',
    'Elgoibar',
    'Errenteria',
    'Errezil',
    'Eskoriatza',
    'Ezkio-Itsaso',
    'Gabiria',
    'Gaintza',
    'Gaztelu',
    'Getaria',
    'Hernani',
    'Hernialde',
    'Hondarribia',
    'Ibarra',
    'Idiazabal',
    'Ikaztegieta',
    'Irun',
    'Irura',
    'Itsasondo',
    'Larraul',
    'Lasarte-Oria',
    'Lazkao',
    'Leaburu',
    'Legazpi',
    'Legorreta',
    'Leintz-Gatzaga',
    'Lezo',
    'Lizartza',
    'Mendaro',
    'Mutiloa',
    'Mutriku',
    'Oiartzun',
    'Olaberria',
    'Oñati',
    'Ordizia',
    'Orendain',
    'Orexa',
    'Orio',
    'Ormaiztegi',
    'Pasaia',
    'Segura',
    'Soraluze/Placencia de las Armas',
    'Tolosa',
    'Urnieta',
    'Urretxu',
    'Usurbil',
    'Villabona',
    'Zaldibia',
    'Zarautz',
    'Zegama',
    'Zerain',
    'Zestoa',
    'Zizurkil',
    'Zumaia',
    'Zumarraga',
  ],
  Girona: [
    'Agullana',
    'Aiguaviva',
    'Albanyà',
    'Albons',
    'Alp',
    'Amer',
    'Anglès',
    'Arbúcies',
    'Argelaguer',
    'Avinyonet de Puigventós',
    'Banyoles',
    'Bàscara',
    'Begur',
    "Bellcaire d'Empordà",
    'Besalú',
    'Bescanó',
    'Beuda',
    'Biure',
    'Blanes',
    'Boadella i les Escaules',
    'Bolvir',
    'Bordils',
    'Borrassà',
    'Breda',
    'Brunyola',
    'Cabanelles',
    'Cabanes',
    'Cadaqués',
    'Caldes de Malavella',
    'Calonge',
    'Camós',
    'Campdevànol',
    'Campelles',
    'Campllong',
    'Camprodon',
    "Canet d'Adri",
    'Cantallops',
    'Capmany',
    'Cassà de la Selva',
    'Castellfollit de la Roca',
    "Castelló d'Empúries",
    "Castell-Platja d'Aro",
    'Celrà',
    'Cervià de Ter',
    'Cistella',
    'Colera',
    'Colomers',
    'Corçà',
    'Cornellà del Terri',
    'Crespià',
    "Cruïlles, Monells i Sant Sadurní de l'Heura",
    'Darnius',
    'Das',
    "El Far d'Empordà",
    'El Port de la Selva',
    'Espinelves',
    'Espolla',
    'Esponellà',
    'Figueres',
    'Flaçà',
    'Foixà',
    'Fontanals de Cerdanya',
    'Fontanilles',
    'Fontcoberta',
    'Forallac',
    'Fornells de la Selva',
    'Fortià',
    'Garrigàs',
    'Garrigoles',
    'Garriguella',
    'Ger',
    'Girona',
    'Gombrèn',
    'Gualta',
    'Guils de Cerdanya',
    'Hostalric',
    'Isòvol',
    'Jafre',
    'Juià',
    "L' Armentera",
    "L' Escala",
    "La Bisbal d'Empordà",
    'La Cellera de Ter',
    'La Jonquera',
    'La Pera',
    'La Selva de Mar',
    "La Tallada d'Empordà",
    'La Vajol',
    'La Vall de Bianya',
    "La Vall d'en Bas",
    'Les Llosses',
    "Les Planes d'Hostoles",
    'Les Preses',
    'Lladó',
    'Llagostera',
    'Llambilles',
    'Llanars',
    'Llançà',
    'Llers',
    'Llívia',
    'Lloret de Mar',
    'Maçanet de Cabrenys',
    'Maçanet de la Selva',
    'Madremanya',
    'Maià de Montcal',
    'Masarac',
    'Massanes',
    'Meranges',
    'Mieres',
    'Mollet de Peralada',
    'Molló',
    'Montagut i Oix',
    'Mont-ras',
    'Navata',
    'Ogassa',
    'Olot',
    'Ordis',
    'Osor',
    'Palafrugell',
    'Palamós',
    'Palau de Santa Eulàlia',
    'Palau-sator',
    'Palau-saverdera',
    'Palol de Revardit',
    'Pals',
    'Pardines',
    'Parlavà',
    'Pau',
    'Pedret i Marzà',
    'Peralada',
    'Planoles',
    'Pont de Molins',
    'Pontós',
    'Porqueres',
    'Portbou',
    'Puigcerdà',
    'Quart',
    'Queralbs',
    'Rabós',
    'Regencós',
    'Ribes de Freser',
    'Riells i Viabrea',
    'Ripoll',
    'Riudarenes',
    'Riudaura',
    'Riudellots de la Selva',
    'Riumors',
    'Roses',
    'Rupià',
    'Sales de Llierca',
    'Salt',
    'Sant Andreu Salou',
    'Sant Aniol de Finestres',
    'Sant Climent Sescebes',
    'Sant Feliu de Buixalleu',
    'Sant Feliu de Guíxols',
    'Sant Feliu de Pallerols',
    'Sant Ferriol',
    'Sant Gregori',
    'Sant Hilari Sacalm',
    'Sant Jaume de Llierca',
    'Sant Joan de les Abadesses',
    'Sant Joan de Mollet',
    'Sant Joan les Fonts',
    'Sant Jordi Desvalls',
    'Sant Julià de Ramis',
    'Sant Julià del Llor i Bonmatí',
    'Sant Llorenç de la Muga',
    'Sant Martí de Llémena',
    'Sant Martí Vell',
    'Sant Miquel de Campmajor',
    'Sant Miquel de Fluvià',
    'Sant Mori',
    'Sant Pau de Segúries',
    'Sant Pere Pescador',
    'Santa Coloma de Farners',
    "Santa Cristina d'Aro",
    "Santa Llogaia d'Àlguema",
    'Santa Pau',
    'Sarrià de Ter',
    'Saus, Camallera i Llampaies',
    'Serinyà',
    'Serra de Daró',
    'Setcases',
    'Sils',
    'Siurana',
    'Susqueda',
    'Terrades',
    'Torrent',
    'Torroella de Fluvià',
    'Torroella de Montgrí',
    'Tortellà',
    'Toses',
    'Tossa de Mar',
    'Ullà',
    'Ullastret',
    'Ultramort',
    'Urús',
    'Vallfogona de Ripollès',
    'Vall-llobrega',
    'Ventalló',
    'Verges',
    'Vidrà',
    'Vidreres',
    'Vilabertran',
    'Vilablareix',
    'Viladamat',
    'Viladasens',
    'Vilademuls',
    'Viladrau',
    'Vilafant',
    'Vilajuïga',
    'Vilallonga de Ter',
    'Vilamacolum',
    'Vilamalla',
    'Vilamaniscle',
    'Vilanant',
    'Vila-sacra',
    'Vilaür',
    "Vilobí d'Onyar",
    'Vilopriu',
  ],
  Guadalajara: [
    'Abánades',
    'Ablanque',
    'Adobes',
    'Alaminos',
    'Alarilla',
    'Albalate de Zorita',
    'Albares',
    'Albendiego',
    'Alcocer',
    'Alcolea de las Peñas',
    'Alcolea del Pinar',
    'Alcoroches',
    'Aldeanueva de Guadalajara',
    'Algar de Mesa',
    'Algora',
    'Alhóndiga',
    'Alique',
    'Almadrones',
    'Almoguera',
    'Almonacid de Zorita',
    'Alocén',
    'Alovera',
    'Alustante',
    'Angón',
    'Anguita',
    'Anquela del Ducado',
    'Anquela del Pedregal',
    'Aranzueque',
    'Arbancón',
    'Arbeteta',
    'Argecilla',
    'Armallones',
    'Armuña de Tajuña',
    'Arroyo de las Fraguas',
    'Atanzón',
    'Atienza',
    'Auñón',
    'Azuqueca de Henares',
    'Baides',
    'Baños de Tajo',
    'Bañuelos',
    'Barriopedro',
    'Berninches',
    'Brihuega',
    'Budia',
    'Bujalaro',
    'Bustares',
    'Cabanillas del Campo',
    'Campillo de Dueñas',
    'Campillo de Ranas',
    'Campisábalos',
    'Canredondo',
    'Cantalojas',
    'Cañizar',
    'Casa de Uceda',
    'Casas de San Galindo',
    'Caspueñas',
    'Castejón de Henares',
    'Castellar de la Muela',
    'Castilforte',
    'Castilnuevo',
    'Cendejas de Enmedio',
    'Cendejas de la Torre',
    'Centenera',
    'Checa',
    'Chequilla',
    'Chillarón del Rey',
    'Chiloeches',
    'Cifuentes',
    'Cincovillas',
    'Ciruelas',
    'Ciruelos del Pinar',
    'Cobeta',
    'Cogollor',
    'Cogolludo',
    'Condemios de Abajo',
    'Condemios de Arriba',
    'Congostrina',
    'Copernal',
    'Corduente',
    'Driebes',
    'Durón',
    'El Cardoso de la Sierra',
    'El Casar',
    'El Cubillo de Uceda',
    'El Olivar',
    'El Ordial',
    'El Pedregal',
    'El Pobo de Dueñas',
    'El Recuenco',
    'El Sotillo',
    'Embid',
    'Escamilla',
    'Escariche',
    'Escopete',
    'Espinosa de Henares',
    'Esplegares',
    'Establés',
    'Estriégana',
    'Fontanar',
    'Fuembellida',
    'Fuencemillán',
    'Fuentelahiguera de Albatages',
    'Fuentelencina',
    'Fuentelsaz',
    'Fuentelviejo',
    'Fuentenovilla',
    'Gajanejos',
    'Galápagos',
    'Galve de Sorbe',
    'Gascueña de Bornova',
    'Guadalajara',
    'Henche',
    'Heras de Ayuso',
    'Herrería',
    'Hiendelaencina',
    'Hijes',
    'Hita',
    'Hombrados',
    'Hontoba',
    'Horche',
    'Hortezuela de Océn',
    'Huérmeces del Cerro',
    'Huertahernando',
    'Hueva',
    'Humanes',
    'Illana',
    'Iniéstola',
    'Irueste',
    'Jadraque',
    'Jirueque',
    'La Bodera',
    'La Huerce',
    'La Mierla',
    'La Miñosa',
    'La Olmeda de Jadraque',
    'La Toba',
    'La Yunta',
    'Las Inviernas',
    'Las Navas de Jadraque',
    'Ledanca',
    'Loranca de Tajuña',
    'Lupiana',
    'Luzaga',
    'Luzón',
    'Majaelrayo',
    'Málaga del Fresno',
    'Malaguilla',
    'Mandayona',
    'Mantiel',
    'Maranchón',
    'Marchamalo',
    'Masegoso de Tajuña',
    'Matarrubia',
    'Matillas',
    'Mazarete',
    'Mazuecos',
    'Medranda',
    'Megina',
    'Membrillera',
    'Miedes de Atienza',
    'Millana',
    'Milmarcos',
    'Mirabueno',
    'Miralrío',
    'Mochales',
    'Mohernando',
    'Molina de Aragón',
    'Monasterio',
    'Mondéjar',
    'Montarrón',
    'Moratilla de los Meleros',
    'Morenilla',
    'Muduex',
    'Negredo',
    'Ocentejo',
    'Olmeda de Cobeta',
    'Orea',
    'Pálmaces de Jadraque',
    'Pardos',
    'Paredes de Sigüenza',
    'Pareja',
    'Pastrana',
    'Peñalén',
    'Peñalver',
    'Peralejos de las Truchas',
    'Peralveche',
    'Pinilla de Jadraque',
    'Pinilla de Molina',
    'Pioz',
    'Piqueras',
    'Poveda de la Sierra',
    'Pozo de Almoguera',
    'Pozo de Guadalajara',
    'Prádena de Atienza',
    'Prados Redondos',
    'Puebla de Beleña',
    'Puebla de Valles',
    'Quer',
    'Rebollosa de Jadraque',
    'Renera',
    'Retiendas',
    'Riba de Saelices',
    'Rillo de Gallo',
    'Riofrío del Llano',
    'Robledillo de Mohernando',
    'Robledo de Corpes',
    'Romanillos de Atienza',
    'Romanones',
    'Rueda de la Sierra',
    'Sacecorbo',
    'Sacedón',
    'Saelices de la Sal',
    'Salmerón',
    'San Andrés del Congosto',
    'San Andrés del Rey',
    'Santiuste',
    'Saúca',
    'Sayatón',
    'Selas',
    'Semillas',
    'Setiles',
    'Sienes',
    'Sigüenza',
    'Solanillos del Extremo',
    'Somolinos',
    'Sotodosos',
    'Tamajón',
    'Taragudo',
    'Taravilla',
    'Tartanedo',
    'Tendilla',
    'Terzaga',
    'Tierzo',
    'Tordellego',
    'Tordelrábano',
    'Tordesilos',
    'Torija',
    'Torre del Burgo',
    'Torrecuadrada de Molina',
    'Torrecuadradilla',
    'Torrejón del Rey',
    'Torremocha de Jadraque',
    'Torremocha del Campo',
    'Torremocha del Pinar',
    'Torremochuela',
    'Torrubia',
    'Tórtola de Henares',
    'Tortuera',
    'Tortuero',
    'Traíd',
    'Trijueque',
    'Trillo',
    'Uceda',
    'Ujados',
    'Utande',
    'Valdarachas',
    'Valdearenas',
    'Valdeavellano',
    'Valdeaveruelo',
    'Valdeconcha',
    'Valdegrudas',
    'Valdelcubo',
    'Valdenuño Fernández',
    'Valdepeñas de la Sierra',
    'Valderrebollo',
    'Valdesotos',
    'Valfermoso de Tajuña',
    'Valhermoso',
    'Valtablado del Río',
    'Valverde de los Arroyos',
    'Viana de Jadraque',
    'Villanueva de Alcorón',
    'Villanueva de Argecilla',
    'Villanueva de la Torre',
    'Villares de Jadraque',
    'Villaseca de Henares',
    'Villaseca de Uceda',
    'Villel de Mesa',
    'Viñuelas',
    'Yebes',
    'Yebra',
    'Yélamos de Abajo',
    'Yélamos de Arriba',
    'Yunquera de Henares',
    'Zaorejas',
    'Zarzuela de Jadraque',
    'Zorita de los Canes',
  ],
  Granada: [
    'Agrón',
    'Alamedilla',
    'Albolote',
    'Albondón',
    'Albuñán',
    'Albuñol',
    'Albuñuelas',
    'Aldeire',
    'Alfacar',
    'Algarinejo',
    'Alhama de Granada',
    'Alhendín',
    'Alicún de Ortega',
    'Almegíjar',
    'Almuñécar',
    'Alpujarra de la Sierra',
    'Alquife',
    'Arenas del Rey',
    'Armilla',
    'Atarfe',
    'Baza',
    'Beas de Granada',
    'Beas de Guadix',
    'Benalúa',
    'Benalúa de las Villas',
    'Benamaurel',
    'Bérchules',
    'Bubión',
    'Busquístar',
    'Cacín',
    'Cádiar',
    'Cájar',
    'Calicasas',
    'Campotéjar',
    'Caniles',
    'Cáñar',
    'Capileira',
    'Carataunas',
    'Cástaras',
    'Castilléjar',
    'Castril',
    'Cenes de la Vega',
    'Chauchina',
    'Chimeneas',
    'Churriana de la Vega',
    'Cijuela',
    'Cogollos de Guadix',
    'Cogollos de la Vega',
    'Colomera',
    'Cortes de Baza',
    'Cortes y Graena',
    'Cuevas del Campo',
    'Cúllar',
    'Cúllar Vega',
    'Darro',
    'Dehesas de Guadix',
    'Deifontes',
    'Diezma',
    'Dílar',
    'Dólar',
    'Dúdar',
    'Dúrcal',
    'El Pinar',
    'El Valle',
    'Escúzar',
    'Ferreira',
    'Fonelas',
    'Freila',
    'Fuente Vaqueros',
    'Galera',
    'Gobernador',
    'Gójar',
    'Gor',
    'Gorafe',
    'Granada',
    'Guadahortuna',
    'Guadix',
    'Gualchos',
    'Güejar Sierra',
    'Güevéjar',
    'Huélago',
    'Huéneja',
    'Huéscar',
    'Huétor de Santillán',
    'Huétor Tájar',
    'Huétor Vega',
    'Illora',
    'Itrabo',
    'Iznalloz',
    'Jayena',
    'Jerez del Marquesado',
    'Jete',
    'Jun',
    'Juviles',
    'La Calahorra',
    'La Malahá',
    'La Peza',
    'La Taha',
    'La Zubia',
    'Láchar',
    'Lanjarón',
    'Lanteira',
    'Las Gabias',
    'Lecrín',
    'Lentegí',
    'Lobras',
    'Loja',
    'Los Guajares',
    'Lugros',
    'Lújar',
    'Maracena',
    'Marchal',
    'Moclín',
    'Molvízar',
    'Monachil',
    'Montefrío',
    'Montejícar',
    'Montillana',
    'Moraleda de Zafayona',
    'Morelábor',
    'Motril',
    'Murtas',
    'Nevada',
    'Nigüelas',
    'Nívar',
    'Ogíjares',
    'Orce',
    'Órgiva',
    'Otívar',
    'Otura',
    'Padul',
    'Pampaneira',
    'Pedro Martínez',
    'Peligros',
    'Pinos Genil',
    'Pinos Puente',
    'Píñar',
    'Polícar',
    'Polopos',
    'Pórtugos',
    'Puebla de Don Fadrique',
    'Pulianas',
    'Purullena',
    'Quéntar',
    'Rubite',
    'Salar',
    'Salobreña',
    'Santa Cruz del Comercio',
    'Santa Fe',
    'Soportújar',
    'Sorvilán',
    'Torre-Cardela',
    'Torvizcón',
    'Trevélez',
    'Turón',
    'Ugíjar',
    'Valle del Zalabí',
    'Válor',
    'Vegas del Genil',
    'Vélez de Benaudalla',
    'Ventas de Huelma',
    'Villamena',
    'Villanueva de las Torres',
    'Villanueva Mesía',
    'Víznar',
    'Zafarraya',
    'Zagra',
    'Zújar',
  ],
  Huelva: [
    'Alájar',
    'Aljaraque',
    'Almonaster la Real',
    'Almonte',
    'Alosno',
    'Aracena',
    'Aroche',
    'Arroyomolinos de León',
    'Ayamonte',
    'Beas',
    'Berrocal',
    'Bollullos Par del Condado',
    'Bonares',
    'Cabezas Rubias',
    'Cala',
    'Calañas',
    'Campofrío',
    'Cañaveral de León',
    'Cartaya',
    'Castaño del Robledo',
    'Chucena',
    'Corteconcepción',
    'Cortegana',
    'Cortelazor',
    'Cumbres de Enmedio',
    'Cumbres de San Bartolomé',
    'Cumbres Mayores',
    'El Almendro',
    'El Campillo',
    'El Cerro de Andévalo',
    'El Granado',
    'Encinasola',
    'Escacena del Campo',
    'Fuenteheridos',
    'Galaroza',
    'Gibraleón',
    'Higuera de la Sierra',
    'Hinojales',
    'Hinojos',
    'Huelva',
    'Isla Cristina',
    'Jabugo',
    'La Granada de Río-Tinto',
    'La Nava',
    'La Palma del Condado',
    'Lepe',
    'Linares de la Sierra',
    'Los Marines',
    'Lucena del Puerto',
    'Manzanilla',
    'Minas de Riotinto',
    'Moguer',
    'Nerva',
    'Niebla',
    'Palos de la Frontera',
    'Paterna del Campo',
    'Paymogo',
    'Puebla de Guzmán',
    'Puerto Moral',
    'Punta Umbría',
    'Rociana del Condado',
    'Rosal de la Frontera',
    'San Bartolomé de la Torre',
    'San Juan del Puerto',
    'San Silvestre de Guzmán',
    'Sanlúcar de Guadiana',
    'Santa Ana la Real',
    'Santa Bárbara de Casa',
    'Santa Olalla del Cala',
    'Trigueros',
    'Valdelarco',
    'Valverde del Camino',
    'Villablanca',
    'Villalba del Alcor',
    'Villanueva de las Cruces',
    'Villanueva de los Castillejos',
    'Villarrasa',
    'Zalamea la Real',
    'Zufre',
  ],
  Huesca: [
    'Abiego',
    'Abizanda',
    'Adahuesca',
    'Agüero',
    'Aínsa-Sobrarbe',
    'Aisa',
    'Albalate de Cinca',
    'Albalatillo',
    'Albelda',
    'Albero Alto',
    'Albero Bajo',
    'Alberuela de Tubo',
    'Alcalá de Gurrea',
    'Alcalá del Obispo',
    'Alcampell',
    'Alcolea de Cinca',
    'Alcubierre',
    'Alerre',
    'Alfántega',
    'Almudévar',
    'Almunia de San Juan',
    'Almuniente',
    'Alquézar',
    'Altorricón',
    'Angüés',
    'Ansó',
    'Antillón',
    'Aragüés del Puerto',
    'Arén',
    'Argavieso',
    'Arguis',
    'Ayerbe',
    'Azanuy-Alins',
    'Azara',
    'Azlor',
    'Baélls',
    'Bailo',
    'Baldellou',
    'Ballobar',
    'Banastás',
    'Barbastro',
    'Barbués',
    'Barbuñales',
    'Bárcabo',
    'Belver de Cinca',
    'Benabarre',
    'Benasque',
    'Berbegal',
    'Bielsa',
    'Bierge',
    'Biescas',
    'Binaced',
    'Binéfar',
    'Bisaurri',
    'Biscarrués',
    'Blecua y Torres',
    'Boltaña',
    'Bonansa',
    'Borau',
    'Broto',
    'Caldearenas',
    'Campo',
    'Camporrélls',
    'Canal de Berdún',
    'Candasnos',
    'Canfranc',
    'Capdesaso',
    'Capella',
    'Casbas de Huesca',
    'Castejón de Monegros',
    'Castejón de Sos',
    'Castejón del Puente',
    'Castelflorite',
    'Castiello de Jaca',
    'Castigaleu',
    'Castillazuelo',
    'Castillonroy',
    'Chalamera',
    'Chía',
    'Chimillas',
    'Colungo',
    'El Grado',
    'El Pueyo de Araguás',
    'Esplús',
    'Estada',
    'Estadilla',
    'Estopiñán del Castillo',
    'Fago',
    'Fanlo',
    'Fiscal',
    'Fonz',
    'Foradada del Toscar',
    'Fraga',
    'Gistaín',
    'Grañén',
    'Graus',
    'Gurrea de Gállego',
    'Hoz de Jaca',
    'Hoz y Costean',
    'Huerto',
    'Huesca',
    'Ibieca',
    'Igriés',
    'Ilche',
    'Isábena',
    'Jaca',
    'Jasa',
    'La Fueva',
    'La Puebla de Castro',
    'La Sotonera',
    'Labuerda',
    'Laluenga',
    'Lalueza',
    'Lanaja',
    'Laperdiguera',
    'Las Peñas de Riglos',
    'Lascellas-Ponzano',
    'Lascuarre',
    'Laspaúles',
    'Laspuña',
    'Loarre',
    'Loporzano',
    'Loscorrales',
    'Lupiñén-Ortilla',
    'Monesma y Cajigar',
    'Monflorite-Lascasas',
    'Montanuy',
    'Monzón',
    'Naval',
    'Novales',
    'Nueno',
    'Olvena',
    'Ontiñena',
    'Osso de Cinca',
    'Palo',
    'Panticosa',
    'Peñalba',
    'Peralta de Alcofea',
    'Peralta de Calasanz',
    'Peraltilla',
    'Perarrúa',
    'Pertusa',
    'Piracés',
    'Plan',
    'Poleñino',
    'Pozán de Vero',
    'Puente de Montañana',
    'Puente la Reina de Jaca',
    'Puértolas',
    'Pueyo de Santa Cruz',
    'Quicena',
    'Robres',
    'Sabiñánigo',
    'Sahún',
    'Salas Altas',
    'Salas Bajas',
    'Salillas',
    'Sallent de Gállego',
    'San Esteban de Litera',
    'San Juan de Plan',
    'San Miguel del Cinca',
    'Sangarrén',
    'Santa Cilia',
    'Santa Cruz de la Serós',
    'Santa María de Dulcis',
    'Santaliestra y San Quílez',
    'Sariñena',
    'Secastilla',
    'Seira',
    'Sena',
    'Senés de Alcubierre',
    'Sesa',
    'Sesué',
    'Siétamo',
    'Sopeira',
    'Tamarite de Litera',
    'Tardienta',
    'Tella-Sin',
    'Tierz',
    'Tolva',
    'Torla',
    'Torralba de Aragón',
    'Torre la Ribera',
    'Torrente de Cinca',
    'Torres de Alcanadre',
    'Torres de Barbués',
    'Tramaced',
    'Valfarta',
    'Valle de Bardají',
    'Valle de Hecho',
    'Valle de Lierp',
    'Velilla de Cinca',
    'Vencillón',
    'Veracruz',
    'Viacamp y Litera',
    'Vicién',
    'Villanova',
    'Villanúa',
    'Villanueva de Sigena',
    'Yebra de Basa',
    'Yésero',
    'Zaidín',
  ],
  'Illes Balears': [
    'Alaior',
    'Alaró',
    'Alcúdia',
    'Algaida',
    'Andratx',
    'Ariany',
    'Artà',
    'Banyalbufar',
    'Binissalem',
    'Búger',
    'Bunyola',
    'Calvià',
    'Campanet',
    'Campos',
    'Capdepera',
    'Ciutadella de Menorca',
    'Consell',
    'Costitx',
    'Deyá',
    'Eivissa',
    'Es Castell',
    'Es Mercadal',
    'Es Migjorn Gran',
    'Escorca',
    'Esporles',
    'Estellencs',
    'Felanitx',
    'Ferreries',
    'Formentera',
    'Fornalutx',
    'Inca',
    'Lloret de Vistalegre',
    'Lloseta',
    'Llubí',
    'Llucmajor',
    'Manacor',
    'Mancor de la Vall',
    'Maó',
    'Maria de la Salut',
    'Marratxí',
    'Montuïri',
    'Muro',
    'Palma',
    'Petra',
    'Pollença',
    'Porreres',
    'Puigpunyent',
    'Sa Pobla',
    'Sant Antoni de Portmany',
    'Sant Joan',
    'Sant Joan de Labritja',
    'Sant Josep de sa Talaia',
    'Sant Llorenç des Cardassar',
    'Sant Lluís',
    'Santa Eugènia',
    'Santa Eulalia del Río',
    'Santa Margalida',
    'Santa María del Camí',
    'Santanyí',
    'Selva',
    'Sencelles',
    'Ses Salines',
    'Sineu',
    'Sóller',
    'Son Servera',
    'Valldemossa',
    'Vilafranca de Bonany',
  ],
  Jaén: [
    'Albanchez de Mágina',
    'Alcalá la Real',
    'Alcaudete',
    'Aldeaquemada',
    'Andújar',
    'Arjona',
    'Arjonilla',
    'Arquillos',
    'Arroyo del Ojanco',
    'Baeza',
    'Bailén',
    'Baños de la Encina',
    'Beas de Segura',
    'Bedmar y Garcíez',
    'Begíjar',
    'Bélmez de la Moraleda',
    'Benatae',
    'Cabra del Santo Cristo',
    'Cambil',
    'Campillo de Arenas',
    'Canena',
    'Carboneros',
    'Cárcheles',
    'Castellar',
    'Castillo de Locubín',
    'Cazalilla',
    'Cazorla',
    'Chiclana de Segura',
    'Chilluévar',
    'Escañuela',
    'Espelúy',
    'Frailes',
    'Fuensanta de Martos',
    'Fuerte del Rey',
    'Génave',
    'Guarromán',
    'Higuera de Calatrava',
    'Hinojares',
    'Hornos',
    'Huelma',
    'Huesa',
    'Ibros',
    'Iznatoraf',
    'Jabalquinto',
    'Jaén',
    'Jamilena',
    'Jimena',
    'Jódar',
    'La Carolina',
    'La Guardia de Jaén',
    'La Iruela',
    'La Puerta de Segura',
    'Lahiguera',
    'Larva',
    'Linares',
    'Lopera',
    'Los Villares',
    'Lupión',
    'Mancha Real',
    'Marmolejo',
    'Martos',
    'Mengíbar',
    'Montizón',
    'Navas de San Juan',
    'Noalejo',
    'Orcera',
    'Peal de Becerro',
    'Pegalajar',
    'Porcuna',
    'Pozo Alcón',
    'Puente de Génave',
    'Quesada',
    'Rus',
    'Sabiote',
    'Santa Elena',
    'Santiago de Calatrava',
    'Santiago-Pontones',
    'Santisteban del Puerto',
    'Santo Tomé',
    'Segura de la Sierra',
    'Siles',
    'Sorihuela del Guadalimar',
    'Torre del Campo',
    'Torreblascopedro',
    'Torredonjimeno',
    'Torreperogil',
    'Torres',
    'Torres de Albánchez',
    'Úbeda',
    'Valdepeñas de Jaén',
    'Vilches',
    'Villacarrillo',
    'Villanueva de la Reina',
    'Villanueva del Arzobispo',
    'Villardompardo',
    'Villarrodrigo',
    'Villatorres',
  ],
  'La Rioja': [
    'Ábalos',
    'Agoncillo',
    'Aguilar del Río Alhama',
    'Ajamil de Cameros',
    'Albelda de Iregua',
    'Alberite',
    'Alcanadre',
    'Aldeanueva de Ebro',
    'Alesanco',
    'Alesón',
    'Alfaro',
    'Almarza de Cameros',
    'Anguciana',
    'Anguiano',
    'Arenzana de Abajo',
    'Arenzana de Arriba',
    'Arnedillo',
    'Arnedo',
    'Arrúbal',
    'Ausejo',
    'Autol',
    'Azofra',
    'Badarán',
    'Bañares',
    'Baños de Río Tobía',
    'Baños de Rioja',
    'Berceo',
    'Bergasa',
    'Bergasillas Bajera',
    'Bezares',
    'Bobadilla',
    'Brieva de Cameros',
    'Briñas',
    'Briones',
    'Cabezón de Cameros',
    'Calahorra',
    'Campanet',
    'Canales de la Sierra',
    'Canillas de Río Tuerto',
    'Cañas',
    'Cárdenas',
    'Casalarreina',
    'Castañares de Rioja',
    'Castroviejo',
    'Cellorigo',
    'Cenicero',
    'Cervera del Río Alhama',
    'Cidamón',
    'Cihuri',
    'Cirueña',
    'Clavijo',
    'Cordovín',
    'Corera',
    'Cornago',
    'Corporales',
    'Cuzcurrita de Río Tirón',
    'Daroca de Rioja',
    'El Rasillo de Cameros',
    'El Redal',
    'El Villar de Arnedo',
    'Enciso',
    'Entrena',
    'Estollo',
    'Ezcaray',
    'Foncea',
    'Fonzaleche',
    'Fuenmayor',
    'Galbárruli',
    'Galilea',
    'Gallinero de Cameros',
    'Gimileo',
    'Grañón',
    'Grávalos',
    'Haro',
    'Herce',
    'Herramélluri',
    'Hervías',
    'Hormilla',
    'Hormilleja',
    'Hornillos de Cameros',
    'Hornos de Moncalvillo',
    'Huércanos',
    'Igea',
    'Jalón de Cameros',
    'Laguna de Cameros',
    'Lagunilla del Jubera',
    'Lardero',
    'Ledesma de la Cogolla',
    'Leiva',
    'Leza de Río Leza',
    'Logroño',
    'Lumbreras',
    'Manjarrés',
    'Mansilla de la Sierra',
    'Manzanares de Rioja',
    'Matute',
    'Medrano',
    'Munilla',
    'Murillo de Río Leza',
    'Muro de Aguas',
    'Muro en Cameros',
    'Nájera',
    'Nalda',
    'Navajún',
    'Navarrete',
    'Nestares',
    'Nieva de Cameros',
    'Ochánduri',
    'Ocón',
    'Ojacastro',
    'Ollauri',
    'Ortigosa de Cameros',
    'Pazuengos',
    'Pedroso',
    'Pinillos',
    'Pradejón',
    'Pradillo',
    'Préjano',
    'Quel',
    'Rabanera',
    'Ribafrecha',
    'Rincón de Soto',
    'Robres del Castillo',
    'Rodezno',
    'Sajazarra',
    'San Asensio',
    'San Millán de la Cogolla',
    'San Millán de Yécora',
    'San Román de Cameros',
    'San Torcuato',
    'San Vicente de la Sonsierra',
    'Santa Coloma',
    'Santa Engracia del Jubera',
    'Santa Eulalia Bajera',
    'Santo Domingo de la Calzada',
    'Santurde de Rioja',
    'Santurdejo',
    'Sojuela',
    'Sorzano',
    'Sotés',
    'Soto en Cameros',
    'Terroba',
    'Tirgo',
    'Tobía',
    'Tormantos',
    'Torre en Cameros',
    'Torrecilla en Cameros',
    'Torrecilla sobre Alesanco',
    'Torremontalbo',
    'Treviana',
    'Tricio',
    'Tudelilla',
    'Uruñuela',
    'Valdemadera',
    'Valgañón',
    'Ventosa',
    'Ventrosa',
    'Viguera',
    'Villalba de Rioja',
    'Villalobar de Rioja',
    'Villamediana de Iregua',
    'Villanueva de Cameros',
    'Villar de Torre',
    'Villarejo',
    'Villarroya',
    'Villarta-Quintana',
    'Villavelayo',
    'Villaverde de Rioja',
    'Villoslada de Cameros',
    'Viniegra de Abajo',
    'Viniegra de Arriba',
    'Zarratón',
    'Zarzosa',
    'Zorraquín',
  ],
  'Las Palmas': [
    'Agaete',
    'Agüimes',
    'Antigua',
    'Arrecife',
    'Artenara',
    'Arucas',
    'Betancuria',
    'Firgas',
    'Gáldar',
    'Haría',
    'Ingenio',
    'La Aldea de San Nicolás',
    'La Oliva',
    'Las Palmas de Gran Canaria',
    'Mogán',
    'Moya',
    'Pájara',
    'Puerto del Rosario',
    'San Bartolomé',
    'San Bartolomé de Tirajana',
    'Santa Brígida',
    'Santa Lucía de Tirajana',
    'Santa María de Guía de Gran Canaria',
    'Teguise',
    'Tejeda',
    'Telde',
    'Teror',
    'Tías',
    'Tinajo',
    'Tuineje',
    'Valleseco',
    'Valsequillo de Gran Canaria',
    'Vega de San Mateo',
    'Yaiza',
  ],
  León: [
    'Acebedo',
    'Algadefe',
    'Alija del Infantado',
    'Almanza',
    'Ardón',
    'Arganza',
    'Astorga',
    'Balboa',
    'Barjas',
    'Bembibre',
    'Benavides',
    'Benuza',
    'Bercianos del Páramo',
    'Bercianos del Real Camino',
    'Berlanga del Bierzo',
    'Boca de Huérgano',
    'Boñar',
    'Borrenes',
    'Brazuelo',
    'Burón',
    'Bustillo del Páramo',
    'Cabañas Raras',
    'Cabreros del Río',
    'Cabrillanes',
    'Cacabelos',
    'Calzada del Coto',
    'Campazas',
    'Campo de Villavidel',
    'Camponaraya',
    'Candín',
    'Cármenes',
    'Carracedelo',
    'Carrizo',
    'Carrocera',
    'Carucedo',
    'Castilfalé',
    'Castrillo de Cabrera',
    'Castrillo de la Valduerna',
    'Castrocalbón',
    'Castrocontrigo',
    'Castropodame',
    'Castrotierra de Valmadrigal',
    'Cea',
    'Cebanico',
    'Cebrones del Río',
    'Chozas de Abajo',
    'Cimanes de la Vega',
    'Cimanes del Tejar',
    'Cistierna',
    'Congosto',
    'Corbillos de los Oteros',
    'Corullón',
    'Crémenes',
    'Cuadros',
    'Cubillas de los Oteros',
    'Cubillas de Rueda',
    'Cubillos del Sil',
    'Destriana',
    'El Burgo Ranero',
    'Encinedo',
    'Escobar de Campos',
    'Fabero',
    'Folgoso de la Ribera',
    'Fresno de la Vega',
    'Fuentes de Carbajal',
    'Garrafe de Torío',
    'Gordaliza del Pino',
    'Gordoncillo',
    'Gradefes',
    'Grajal de Campos',
    'Gusendos de los Oteros',
    'Hospital de Órbigo',
    'Igüeña',
    'Izagre',
    'Joarilla de las Matas',
    'La Antigua',
    'La Bañeza',
    'La Ercina',
    'La Pola de Gordón',
    'La Robla',
    'La Vecilla',
    'Laguna Dalga',
    'Laguna de Negrillos',
    'Las Omañas',
    'León',
    'Llamas de la Ribera',
    'Los Barrios de Luna',
    'Lucillo',
    'Luyego',
    'Magaz de Cepeda',
    'Mansilla de las Mulas',
    'Mansilla Mayor',
    'Maraña',
    'Matadeón de los Oteros',
    'Matallana de Torío',
    'Matanza',
    'Molinaseca',
    'Murias de Paredes',
    'Noceda del Bierzo',
    'Oencia',
    'Onzonilla',
    'Oseja de Sajambre',
    'Pajares de los Oteros',
    'Palacios de la Valduerna',
    'Palacios del Sil',
    'Páramo del Sil',
    'Peranzanes',
    'Pobladura de Pelayo García',
    'Ponferrada',
    'Posada de Valdeón',
    'Pozuelo del Páramo',
    'Prado de la Guzpeña',
    'Priaranza del Bierzo',
    'Prioro',
    'Puebla de Lillo',
    'Puente de Domingo Flórez',
    'Quintana del Castillo',
    'Quintana del Marco',
    'Quintana y Congosto',
    'Regueras de Arriba',
    'Reyero',
    'Riaño',
    'Riego de la Vega',
    'Riello',
    'Rioseco de Tapia',
    'Roperuelos del Páramo',
    'Sabero',
    'Sahagún',
    'San Adrián del Valle',
    'San Andrés del Rabanedo',
    'San Cristóbal de la Polantera',
    'San Emiliano',
    'San Esteban de Nogales',
    'San Justo de la Vega',
    'San Millán de los Caballeros',
    'San Pedro Bercianos',
    'Sancedo',
    'Santa Colomba de Curueño',
    'Santa Colomba de Somoza',
    'Santa Cristina de Valmadrigal',
    'Santa Elena de Jamuz',
    'Santa María de la Isla',
    'Santa María de Ordás',
    'Santa María del Monte de Cea',
    'Santa María del Páramo',
    'Santa Marina del Rey',
    'Santas Martas',
    'Santiago Millas',
    'Santovenia de la Valdoncina',
    'Sariegos',
    'Sena de Luna',
    'Sobrado',
    'Soto de la Vega',
    'Soto y Amío',
    'Toral de los Guzmanes',
    'Toreno',
    'Torre del Bierzo',
    'Trabadelo',
    'Truchas',
    'Turcia',
    'Urdiales del Páramo',
    'Val de San Lorenzo',
    'Valdefresno',
    'Valdefuentes del Páramo',
    'Valdelugueros',
    'Valdemora',
    'Valdepiélago',
    'Valdepolo',
    'Valderas',
    'Valderrey',
    'Valderrueda',
    'Valdesamario',
    'Valdevimbre',
    'Valencia de Don Juan',
    'Vallecillo',
    'Valverde de la Virgen',
    'Valverde-Enrique',
    'Vega de Espinareda',
    'Vega de Infanzones',
    'Vega de Valcarce',
    'Vegacervera',
    'Vegaquemada',
    'Vegas del Condado',
    'Villablino',
    'Villabraz',
    'Villadangos del Páramo',
    'Villadecanes',
    'Villademor de la Vega',
    'Villafranca del Bierzo',
    'Villagatón',
    'Villamandos',
    'Villamanín',
    'Villamañán',
    'Villamartín de Don Sancho',
    'Villamejil',
    'Villamol',
    'Villamontán de la Valduerna',
    'Villamoratiel de las Matas',
    'Villanueva de las Manzanas',
    'Villaobispo de Otero',
    'Villaornate y Castro',
    'Villaquejida',
    'Villaquilambre',
    'Villarejo de Órbigo',
    'Villares de Órbigo',
    'Villasabariego',
    'Villaselán',
    'Villaturiel',
    'Villazala',
    'Villazanzo de Valderaduey',
    'Zotes del Páramo',
  ],
  Lleida: [
    'Abella de la Conca',
    'Àger',
    'Agramunt',
    'Aitona',
    'Alàs i Cerc',
    'Albatàrrec',
    'Albesa',
    'Alcanó',
    'Alcarràs',
    'Alcoletge',
    'Alfarràs',
    'Alfés',
    'Algerri',
    'Alguaire',
    'Alins',
    'Almacelles',
    'Almatret',
    'Almenar',
    'Alòs de Balaguer',
    'Alpicat',
    'Alt Àneu',
    'Anglesola',
    'Arbeca',
    'Arres',
    'Arsèguel',
    'Artesa de Lleida',
    'Artesa de Segre',
    'Aspa',
    'Baix Pallars',
    'Balaguer',
    'Barbens',
    'Bassella',
    'Bausen',
    'Belianes',
    'Bellaguarda',
    "Bellcaire d'Urgell",
    "Bell-lloc d'Urgell",
    "Bellmunt d'Urgell",
    'Bellpuig',
    'Bellver de Cerdanya',
    'Bellvís',
    'Benavent de Segrià',
    'Biosca',
    'Bossòst',
    'Bovera',
    'Cabanabona',
    'Cabó',
    'Camarasa',
    'Canejan',
    'Castell de Mur',
    'Castellar de la Ribera',
    'Castelldans',
    'Castellnou de Seana',
    'Castelló de Farfanya',
    'Castellserà',
    'Cava',
    'Cervera',
    'Cervià de les Garrigues',
    'Ciutadilla',
    'Clariana de Cardener',
    'Coll de Nargó',
    'Conca de Dalt',
    'Corbins',
    'Cubells',
    'El Cogul',
    "El Palau d'Anglesola",
    'El Poal',
    'El Pont de Bar',
    'El Pont de Suert',
    'El Soleràs',
    'El Vilosell',
    'Els Alamús',
    'Els Omellons',
    'Els Omells de na Gaia',
    'Els Plans de Sió',
    'Els Torms',
    'Es Bòrdes',
    'Espot',
    'Estamariu',
    'Estaràs',
    "Esterri d'Àneu",
    'Esterri de Cardós',
    'Farrera',
    'Fígols i Alinyà',
    'Fondarella',
    'Foradada',
    'Fulleda',
    'Gavet de la Conca',
    'Gimenells i el Pla de la Font',
    'Golmés',
    'Gósol',
    'Granyanella',
    'Granyena de les Garrigues',
    'Granyena de Segarra',
    'Guimerà',
    'Guissona',
    'Guixers',
    'Isona i Conca Dellà',
    'Ivars de Noguera',
    "Ivars d'Urgell",
    'Ivorra',
    'Josa i Tuixén',
    'Juncosa',
    'Juneda',
    "L' Albagés",
    "L' Albi",
    "L' Espluga Calba",
    'La Baronia de Rialb',
    'La Coma i la Pedra',
    'La Floresta',
    'La Fuliola',
    'La Granadella',
    "La Granja d'Escarp",
    "La Guingueta d'Àneu",
    'La Molsosa',
    'La Pobla de Cérvoles',
    'La Pobla de Segur',
    'La Portella',
    'La Sentiu de Sió',
    "La Seu d'Urgell",
    'La Torre de Cabdella',
    'La Vall de Boí',
    'La Vansa i Fórnols',
    'Les',
    'Les Avellanes i Santa Linya',
    'Les Borges Blanques',
    'Les Oluges',
    "Les Valls d'Aguilar",
    'Les Valls de Valira',
    'Linyola',
    'Lladorre',
    'Lladurs',
    'Llardecans',
    'Llavorsí',
    'Lleida',
    'Lles de Cerdanya',
    'Llimiana',
    'Llobera',
    'Maials',
    'Maldà',
    'Massalcoreig',
    'Massoteres',
    'Menàrguens',
    'Miralcamp',
    'Mollerussa',
    'Montellà i Martinet',
    'Montferrer i Castellbò',
    'Montgai',
    'Montoliu de Lleida',
    'Montoliu de Segarra',
    'Montornès de Segarra',
    'Nalec',
    'Naut Aran',
    'Navès',
    'Odèn',
    'Oliana',
    'Oliola',
    'Olius',
    'Organyà',
    'Os de Balaguer',
    'Ossó de Sió',
    'Penelles',
    'Peramola',
    'Pinell de Solsonès',
    'Pinós',
    'Ponts',
    'Prats i Sansor',
    'Preixana',
    'Preixens',
    'Prullans',
    'Puiggròs',
    "Puigverd d'Agramunt",
    'Puigverd de Lleida',
    'Rialp',
    "Ribera d'Ondara",
    "Ribera d'Urgellet",
    'Riner',
    'Riu de Cerdanya',
    'Rosselló',
    'Salàs de Pallars',
    'Sanaüja',
    'Sant Esteve de la Sarga',
    'Sant Guim de Freixenet',
    'Sant Guim de la Plana',
    'Sant Llorenç de Morunys',
    'Sant Martí de Riucorb',
    'Sant Ramon',
    'Sarroca de Bellera',
    'Sarroca de Lleida',
    'Senterada',
    'Seròs',
    'Sidamon',
    'Solsona',
    'Soriguera',
    'Sort',
    'Soses',
    'Sudanell',
    'Sunyer',
    'Talarn',
    'Talavera',
    'Tàrrega',
    'Tarrés',
    'Tarroja de Segarra',
    'Térmens',
    'Tírvia',
    'Tiurana',
    'Torà',
    'Tornabous',
    'Torrebesses',
    'Torrefarrera',
    'Torrefeta i Florejacs',
    'Torregrossa',
    'Torrelameu',
    'Torres de Segre',
    'Torre-serona',
    'Tremp',
    'Vall de Cardós',
    'Vallbona de les Monges',
    'Vallfogona de Balaguer',
    'Verdú',
    'Vielha e Mijaran',
    'Vilagrassa',
    'Vilaller',
    'Vilamòs',
    'Vilanova de Bellpuig',
    'Vilanova de la Barca',
    "Vilanova de l'Aguda",
    'Vilanova de Meià',
    'Vilanova de Segrià',
    'Vila-sana',
  ],
  Lugo: [
    'A Fonsagrada',
    'A Pastoriza',
    'A Pobra do Brollón',
    'A Pontenova',
    'Abadín',
    'Alfoz',
    'Antas de Ulla',
    'As Nogais',
    'Baleira',
    'Baralla',
    'Barreiros',
    'Becerreá',
    'Begonte',
    'Bóveda',
    'Burela',
    'Carballedo',
    'Castro de Rei',
    'Castroverde',
    'Cervantes',
    'Cervo',
    'Chantada',
    'Cospeito',
    'Folgoso do Courel',
    'Foz',
    'Friol',
    'Guitiriz',
    'Guntín',
    'Láncara',
    'Lourenzá',
    'Lugo',
    'Meira',
    'Mondoñedo',
    'Monforte de Lemos',
    'Monterroso',
    'Muras',
    'Navia de Suarna',
    'Negueira de Muñiz',
    'O Corgo',
    'O Incio',
    'O Páramo',
    'O Saviñao',
    'O Valadouro',
    'O Vicedo',
    'Ourol',
    'Outeiro de Rei',
    'Palas de Rei',
    'Pantón',
    'Paradela',
    'Pedrafita do Cebreiro',
    'Pol',
    'Portomarín',
    'Quiroga',
    'Rábade',
    'Ribadeo',
    'Ribas de Sil',
    'Ribeira de Piquín',
    'Riotorto',
    'Samos',
    'Sarria',
    'Sober',
    'Taboada',
    'Trabada',
    'Triacastela',
    'Vilalba',
    'Viveiro',
    'Xermade',
    'Xove',
  ],
  Madrid: [
    'Ajalvir',
    'Alameda del Valle',
    'Alcalá de Henares',
    'Alcobendas',
    'Alcorcón',
    'Aldea del Fresno',
    'Algete',
    'Alpedrete',
    'Ambite',
    'Anchuelo',
    'Aranjuez',
    'Arganda del Rey',
    'Arroyomolinos',
    'Batres',
    'Becerril de la Sierra',
    'Belmonte de Tajo',
    'Berzosa del Lozoya',
    'Boadilla del Monte',
    'Braojos',
    'Brea de Tajo',
    'Brunete',
    'Buitrago del Lozoya',
    'Bustarviejo',
    'Cabanillas de la Sierra',
    'Cadalso de los Vidrios',
    'Camarma de Esteruelas',
    'Campo Real',
    'Canencia',
    'Carabaña',
    'Casarrubuelos',
    'Cenicientos',
    'Cercedilla',
    'Cervera de Buitrago',
    'Chapinería',
    'Chinchón',
    'Ciempozuelos',
    'Cobeña',
    'Collado Mediano',
    'Collado Villalba',
    'Colmenar de Oreja',
    'Colmenar del Arroyo',
    'Colmenar Viejo',
    'Colmenarejo',
    'Corpa',
    'Coslada',
    'Cubas de la Sagra',
    'Daganzo de Arriba',
    'El Álamo',
    'El Atazar',
    'El Berrueco',
    'El Boalo',
    'El Escorial',
    'El Molar',
    'El Vellón',
    'Estremera',
    'Fresnedillas de la Oliva',
    'Fresno de Torote',
    'Fuenlabrada',
    'Fuente el Saz de Jarama',
    'Fuentidueña de Tajo',
    'Galapagar',
    'Garganta de los Montes',
    'Gargantilla del Lozoya y Pinilla de Buitrago',
    'Gascones',
    'Getafe',
    'Griñón',
    'Guadalix de la Sierra',
    'Guadarrama',
    'Horcajo de la Sierra',
    'Horcajuelo de la Sierra',
    'Hoyo de Manzanares',
    'Humanes de Madrid',
    'La Acebeda',
    'La Cabrera',
    'La Hiruela',
    'La Serna del Monte',
    'Las Rozas de Madrid',
    'Leganés',
    'Loeches',
    'Los Molinos',
    'Los Santos de la Humosa',
    'Lozoya',
    'Lozoyuela-Navas-Sieteiglesias',
    'Madarcos',
    'Madrid',
    'Majadahonda',
    'Manzanares el Real',
    'Meco',
    'Mejorada del Campo',
    'Miraflores de la Sierra',
    'Montejo de la Sierra',
    'Moraleja de Enmedio',
    'Moralzarzal',
    'Morata de Tajuña',
    'Móstoles',
    'Navacerrada',
    'Navalafuente',
    'Navalagamella',
    'Navalcarnero',
    'Navarredonda y San Mamés',
    'Navas del Rey',
    'Nuevo Baztán',
    'Olmeda de las Fuentes',
    'Orusco de Tajuña',
    'Paracuellos de Jarama',
    'Parla',
    'Patones',
    'Pedrezuela',
    'Pelayos de la Presa',
    'Perales de Tajuña',
    'Pezuela de las Torres',
    'Pinilla del Valle',
    'Pinto',
    'Piñuécar-Gandullas',
    'Pozuelo de Alarcón',
    'Pozuelo del Rey',
    'Prádena del Rincón',
    'Puebla de la Sierra',
    'Puentes Viejas',
    'Quijorna',
    'Rascafría',
    'Redueña',
    'Ribatejada',
    'Rivas-Vaciamadrid',
    'Robledillo de la Jara',
    'Robledo de Chavela',
    'Robregordo',
    'Rozas de Puerto Real',
    'San Agustín del Guadalix',
    'San Fernando de Henares',
    'San Lorenzo de El Escorial',
    'San Martín de la Vega',
    'San Martín de Valdeiglesias',
    'San Sebastián de los Reyes',
    'Santa María de la Alameda',
    'Santorcaz',
    'Serranillos del Valle',
    'Sevilla la Nueva',
    'Somosierra',
    'Soto del Real',
    'Talamanca de Jarama',
    'Tielmes',
    'Titulcia',
    'Torrejón de Ardoz',
    'Torrejón de la Calzada',
    'Torrejón de Velasco',
    'Torrelaguna',
    'Torrelodones',
    'Torremocha de Jarama',
    'Torres de la Alameda',
    'Tres Cantos',
    'Valdaracete',
    'Valdeavero',
    'Valdelaguna',
    'Valdemanco',
    'Valdemaqueda',
    'Valdemorillo',
    'Valdemoro',
    'Valdeolmos-Alalpardo',
    'Valdepiélagos',
    'Valdetorres de Jarama',
    'Valdilecha',
    'Valverde de Alcalá',
    'Velilla de San Antonio',
    'Venturada',
    'Villa del Prado',
    'Villaconejos',
    'Villalbilla',
    'Villamanrique de Tajo',
    'Villamanta',
    'Villamantilla',
    'Villanueva de la Cañada',
    'Villanueva de Perales',
    'Villanueva del Pardillo',
    'Villar del Olmo',
    'Villarejo de Salvanés',
    'Villaviciosa de Odón',
    'Villavieja del Lozoya',
    'Zarzalejo',
  ],
  Málaga: [
    'Alameda',
    'Alcaucín',
    'Alfarnate',
    'Alfarnatejo',
    'Algarrobo',
    'Algatocín',
    'Alhaurín de la Torre',
    'Alhaurín el Grande',
    'Almáchar',
    'Almargen',
    'Almogía',
    'Álora',
    'Alozaina',
    'Alpandeire',
    'Antequera',
    'Árchez',
    'Archidona',
    'Ardales',
    'Arenas',
    'Arriate',
    'Atajate',
    'Benadalid',
    'Benahavís',
    'Benalauría',
    'Benalmádena',
    'Benamargosa',
    'Benamocarra',
    'Benaoján',
    'Benarrabá',
    'Campillos',
    'Canillas de Aceituno',
    'Canillas de Albaida',
    'Cañete la Real',
    'Carratraca',
    'Cartajima',
    'Cártama',
    'Casabermeja',
    'Casarabonela',
    'Casares',
    'Coín',
    'Colmenar',
    'Comares',
    'Cómpeta',
    'Cortes de la Frontera',
    'Cuevas Bajas',
    'Cuevas de San Marcos',
    'Cuevas del Becerro',
    'Cútar',
    'El Borge',
    'El Burgo',
    'Estepona',
    'Faraján',
    'Frigiliana',
    'Fuengirola',
    'Fuente de Piedra',
    'Gaucín',
    'Genalguacil',
    'Guaro',
    'Humilladero',
    'Igualeja',
    'Istán',
    'Iznate',
    'Jimera de Líbar',
    'Jubrique',
    'Júzcar',
    'Macharaviaya',
    'Málaga',
    'Manilva',
    'Marbella',
    'Mijas',
    'Moclinejo',
    'Mollina',
    'Monda',
    'Montejaque',
    'Nerja',
    'Ojén',
    'Parauta',
    'Periana',
    'Pizarra',
    'Pujerra',
    'Rincón de la Victoria',
    'Riogordo',
    'Ronda',
    'Salares',
    'Sayalonga',
    'Sedella',
    'Sierra de Yeguas',
    'Teba',
    'Tolox',
    'Torremolinos',
    'Torrox',
    'Totalán',
    'Valle de Abdalajís',
    'Vélez-Málaga',
    'Villanueva de Algaidas',
    'Villanueva de Tapia',
    'Villanueva del Rosario',
    'Villanueva del Trabuco',
    'Viñuela',
    'Yunquera',
  ],
  Melilla: ['Melilla'],
  Murcia: [
    'Abanilla',
    'Abarán',
    'Águilas',
    'Albudeite',
    'Alcantarilla',
    'Aledo',
    'Alguazas',
    'Alhama de Murcia',
    'Archena',
    'Beniel',
    'Blanca',
    'Bullas',
    'Calasparra',
    'Campos del Río',
    'Caravaca de la Cruz',
    'Cartagena',
    'Cehegín',
    'Ceutí',
    'Cieza',
    'Fortuna',
    'Fuente Álamo de Murcia',
    'Jumilla',
    'La Unión',
    'Las Torres de Cotillas',
    'Librilla',
    'Lorca',
    'Lorquí',
    'Los Alcázares',
    'Mazarrón',
    'Molina de Segura',
    'Moratalla',
    'Mula',
    'Murcia',
    'Ojós',
    'Pliego',
    'Puerto Lumbreras',
    'Ricote',
    'San Javier',
    'San Pedro del Pinatar',
    'Santomera',
    'Torre-Pacheco',
    'Totana',
    'Ulea',
    'Villanueva del Río Segura',
    'Yecla',
  ],
  Navarra: [
    'Abáigar',
    'Abárzuza',
    'Abaurregaina/Abaurrea Alta',
    'Abaurrepea/Abaurrea Baja',
    'Aberin',
    'Ablitas',
    'Adiós',
    'Aguilar de Codés',
    'Aibar/Oibar',
    'Allín',
    'Allo',
    'Altsasu/Alsasua',
    'Améscoa Baja',
    'Ancín',
    'Andosilla',
    'Ansoáin',
    'Anue',
    'Añorbe',
    'Aoiz/Agoitz',
    'Araitz',
    'Arakil',
    'Aranarache',
    'Aranguren',
    'Arano',
    'Arantza',
    'Aras',
    'Arbizu',
    'Arce/Artzi',
    'Arellano',
    'Areso',
    'Arguedas',
    'Aria',
    'Aribe',
    'Armañanzas',
    'Arróniz',
    'Arruazu',
    'Artajona',
    'Artazu',
    'Atez',
    'Auritz/Burguete',
    'Ayegui',
    'Azagra',
    'Azuelo',
    'Bakaiku',
    'Barañain',
    'Barásoain',
    'Barbarin',
    'Bargota',
    'Barillas',
    'Basaburua',
    'Baztan',
    'Beintza-Labaien',
    'Beire',
    'Belascoáin',
    'Bera/Vera de Bidasoa',
    'Berbinzana',
    'Beriáin',
    'Berrioplano',
    'Berriozar',
    'Bertizarana',
    'Betelu',
    'Bidaurreta',
    'Biurrun-Olcoz',
    'Buñuel',
    'Burgui/Burgi',
    'Burlada/Burlata',
    'Cabanillas',
    'Cabredo',
    'Cadreita',
    'Caparroso',
    'Cárcar',
    'Carcastillo',
    'Cascante',
    'Cáseda',
    'Castejón',
    'Castillonuevo',
    'Cendea de Olza/Oltza Zendea',
    'Cintruénigo',
    'Cirauqui',
    'Ciriza',
    'Cizur',
    'Corella',
    'Cortes',
    'Desojo',
    'Dicastillo',
    'Donamaria',
    'Doneztebe/Santesteban',
    'Echarri',
    'Egüés',
    'El Busto',
    'Elgorriaga',
    'Enériz',
    'Eratsun',
    'Ergoiena',
    'Erro',
    'Eslava',
    'Esparza de Salazar/Espartza Zaraitzu',
    'Espronceda',
    'Estella/Lizarra',
    'Esteribar',
    'Etayo',
    'Etxalar',
    'Etxarri-Aranatz',
    'Etxauri',
    'Eulate',
    'Ezcabarte',
    'Ezcároz/Ezkaroze',
    'Ezkurra',
    'Ezprogui',
    'Falces',
    'Fitero',
    'Fontellas',
    'Funes',
    'Fustiñana',
    'Galar',
    'Gallipienzo',
    'Gallués/Galoze',
    'Garaioa',
    'Garde',
    'Garínoain',
    'Garralda',
    'Genevilla',
    'Goizueta',
    'Goñi',
    'Güesa/Gorza',
    'Guesálaz',
    'Guirguillano',
    'Hiriberri/Villanueva de Aezkoa',
    'Huarte/Uharte',
    'Ibargoiti',
    'Igantzi',
    'Igúzquiza',
    'Imotz',
    'Irañeta',
    'Irurtzun',
    'Isaba/Izaba',
    'Ituren',
    'Iturmendi',
    'Iza',
    'Izagaondoa',
    'Izalzu/Itzaltzu',
    'Jaurrieta',
    'Javier',
    'Juslapeña',
    'Lakuntza',
    'Lana',
    'Lantz',
    'Lapoblación',
    'Larraga',
    'Larraona',
    'Larraun',
    'Lazagurría',
    'Leache',
    'Legarda',
    'Legaria',
    'Leitza',
    'Lekunberri',
    'Leoz',
    'Lerga',
    'Lerín',
    'Lesaka',
    'Lezáun',
    'Liédena',
    'Lizoáin',
    'Lodosa',
    'Lónguida/Longida',
    'Los Arcos',
    'Lumbier',
    'Luquin',
    'Luzaide/Valcarlos',
    'Mañeru',
    'Marañón',
    'Marcilla',
    'Mélida',
    'Mendavia',
    'Mendaza',
    'Mendigorría',
    'Metauten',
    'Milagro',
    'Mirafuentes',
    'Miranda de Arga',
    'Monreal',
    'Monteagudo',
    'Morentin',
    'Mues',
    'Murchante',
    'Murieta',
    'Murillo el Cuende',
    'Murillo el Fruto',
    'Muruzábal',
    'Navascués',
    'Nazar',
    'Noáin (Valle de Elorz)/Noain (Elortzibar)',
    'Obanos',
    'Ochagavía/Otsagabia',
    'Oco',
    'Odieta',
    'Oitz',
    'Olaibar',
    'Olazti/Olazagutía',
    'Olejua',
    'Olite',
    'Ollo',
    'Olóriz',
    'Orbaitzeta',
    'Orbara',
    'Orísoain',
    'Orkoien',
    'Oronz/Orontze',
    'Orreaga/Roncesvalles',
    'Oteiza',
    'Pamplona/Iruña',
    'Peralta',
    'Petilla de Aragón',
    'Piedramillera',
    'Pitillas',
    'Puente la Reina/Gares',
    'Pueyo',
    'Ribaforada',
    'Romanzado',
    'Roncal/Erronkari',
    'Sada',
    'Saldías',
    'Salinas de Oro',
    'San Adrián',
    'San Martín de Unx',
    'Sangüesa/Zangoza',
    'Sansol',
    'Santacara',
    'Sarriés/Sartze',
    'Sartaguda',
    'Sesma',
    'Sorlada',
    'Sunbilla',
    'Tafalla',
    'Tiebas-Muruarte de Reta',
    'Tirapu',
    'Torralba del Río',
    'Torres del Río',
    'Tudela',
    'Tulebras',
    'Ucar',
    'Uharte-Arakil',
    'Ujué',
    'Ultzama',
    'Unciti',
    'Unzué',
    'Urdazubi/Urdax',
    'Urdiain',
    'Urraul Alto',
    'Urraul Bajo',
    'Urrotz',
    'Urroz-Villa',
    'Urzainqui/Urzainki',
    'Uterga',
    'Uztárroz/Uztarroze',
    'Valle de Yerri/Deierri',
    'Valtierra',
    'Viana',
    'Vidángoz/Bidankoze',
    'Villafranca',
    'Villamayor de Monjardín',
    'Villatuerta',
    'Villava/Atarrabia',
    'Yesa',
    'Zabalza',
    'Ziordia',
    'Zizur Mayor/Zizur Nagusia',
    'Zubieta',
    'Zugarramurdi',
    'Zúñiga',
  ],
  Ourense: [
    'A Arnoia',
    'A Bola',
    'A Gudiña',
    'A Merca',
    'A Mezquita',
    'A Peroxa',
    'A Pobra de Trives',
    'A Rúa',
    'A Teixeira',
    'A Veiga',
    'Allariz',
    'Amoeiro',
    'Avión',
    'Baltar',
    'Bande',
    'Baños de Molgas',
    'Barbadás',
    'Beade',
    'Beariz',
    'Boborás',
    'Calvos de Randín',
    'Carballeda de Avia',
    'Carballeda de Valdeorras',
    'Cartelle',
    'Castrelo de Miño',
    'Castrelo do Val',
    'Castro Caldelas',
    'Celanova',
    'Cenlle',
    'Chandrexa de Queixa',
    'Coles',
    'Cortegada',
    'Cualedro',
    'Entrimo',
    'Esgos',
    'Gomesende',
    'Larouco',
    'Laza',
    'Leiro',
    'Lobeira',
    'Lobios',
    'Maceda',
    'Manzaneda',
    'Maside',
    'Melón',
    'Montederramo',
    'Monterrei',
    'Muíños',
    'Nogueira de Ramuín',
    'O Barco de Valdeorras',
    'O Bolo',
    'O Carballiño',
    'O Irixo',
    'O Pereiro de Aguiar',
    'Oímbra',
    'Os Blancos',
    'Ourense',
    'Paderne de Allariz',
    'Padrenda',
    'Parada de Sil',
    'Petín',
    'Piñor',
    'Pontedeva',
    'Porqueira',
    'Punxín',
    'Quintela de Leirado',
    'Rairiz de Veiga',
    'Ramirás',
    'Ribadavia',
    'Riós',
    'Rubiá',
    'San Amaro',
    'San Cibrao das Viñas',
    'San Cristovo de Cea',
    'San Xoán de Río',
    'Sandiás',
    'Sarreaus',
    'Taboadela',
    'Toén',
    'Trasmiras',
    'Verea',
    'Verín',
    'Viana do Bolo',
    'Vilamarín',
    'Vilamartín de Valdeorras',
    'Vilar de Barrio',
    'Vilar de Santos',
    'Vilardevós',
    'Vilariño de Conso',
    'Xinzo de Limia',
    'Xunqueira de Ambía',
    'Xunqueira de Espadanedo',
  ],
  Palencia: [
    'Abarca de Campos',
    'Abia de las Torres',
    'Aguilar de Campoo',
    'Alar del Rey',
    'Alba de Cerrato',
    'Amayuelas de Arriba',
    'Ampudia',
    'Amusco',
    'Antigüedad',
    'Arconada',
    'Astudillo',
    'Autilla del Pino',
    'Autillo de Campos',
    'Ayuela',
    'Baltanás',
    'Baquerín de Campos',
    'Bárcena de Campos',
    'Barruelo de Santullán',
    'Báscones de Ojeda',
    'Becerril de Campos',
    'Belmonte de Campos',
    'Berzosilla',
    'Boada de Campos',
    'Boadilla de Rioseco',
    'Boadilla del Camino',
    'Brañosera',
    'Buenavista de Valdavia',
    'Bustillo de la Vega',
    'Bustillo del Páramo de Carrión',
    'Calahorra de Boedo',
    'Calzada de los Molinos',
    'Capillas',
    'Cardeñosa de Volpejera',
    'Carrión de los Condes',
    'Castil de Vela',
    'Castrejón de la Peña',
    'Castrillo de Don Juan',
    'Castrillo de Onielo',
    'Castrillo de Villavega',
    'Castromocho',
    'Cervatos de la Cueza',
    'Cervera de Pisuerga',
    'Cevico de la Torre',
    'Cevico Navero',
    'Cisneros',
    'Cobos de Cerrato',
    'Collazos de Boedo',
    'Congosto de Valdavia',
    'Cordovilla la Real',
    'Cubillas de Cerrato',
    'Dehesa de Montejo',
    'Dehesa de Romanos',
    'Dueñas',
    'Espinosa de Cerrato',
    'Espinosa de Villagonzalo',
    'Frechilla',
    'Fresno del Río',
    'Frómista',
    'Fuentes de Nava',
    'Fuentes de Valdepero',
    'Grijota',
    'Guardo',
    'Guaza de Campos',
    'Hérmedes de Cerrato',
    'Herrera de Pisuerga',
    'Herrera de Valdecañas',
    'Hontoria de Cerrato',
    'Hornillos de Cerrato',
    'Husillos',
    'Itero de la Vega',
    'La Pernía',
    'La Puebla de Valdavia',
    'La Serna',
    'La Vid de Ojeda',
    'Lagartos',
    'Lantadilla',
    'Ledigos',
    'Loma de Ucieza',
    'Lomas',
    'Magaz de Pisuerga',
    'Manquillos',
    'Mantinos',
    'Marcilla de Campos',
    'Mazariegos',
    'Mazuecos de Valdeginate',
    'Melgar de Yuso',
    'Meneses de Campos',
    'Micieces de Ojeda',
    'Monzón de Campos',
    'Moratinos',
    'Mudá',
    'Nogal de las Huertas',
    'Olea de Boedo',
    'Olmos de Ojeda',
    'Osornillo',
    'Osorno la Mayor',
    'Palencia',
    'Palenzuela',
    'Páramo de Boedo',
    'Paredes de Nava',
    'Payo de Ojeda',
    'Pedraza de Campos',
    'Pedrosa de la Vega',
    'Perales',
    'Pino del Río',
    'Piña de Campos',
    'Población de Arroyo',
    'Población de Campos',
    'Población de Cerrato',
    'Polentinos',
    'Pomar de Valdivia',
    'Poza de la Vega',
    'Pozo de Urama',
    'Prádanos de Ojeda',
    'Quintana del Puente',
    'Quintanilla de Onsoña',
    'Reinoso de Cerrato',
    'Renedo de la Vega',
    'Requena de Campos',
    'Respenda de la Peña',
    'Revenga de Campos',
    'Revilla de Collazos',
    'Ribas de Campos',
    'Riberos de la Cueza',
    'Saldaña',
    'Salinas de Pisuerga',
    'San Cebrián de Campos',
    'San Cebrián de Mudá',
    'San Cristóbal de Boedo',
    'San Mamés de Campos',
    'San Román de la Cuba',
    'Santa Cecilia del Alcor',
    'Santa Cruz de Boedo',
    'Santervás de la Vega',
    'Santibáñez de Ecla',
    'Santibáñez de la Peña',
    'Santoyo',
    'Soto de Cerrato',
    'Sotobañado y Priorato',
    'Tabanera de Cerrato',
    'Tabanera de Valdavia',
    'Támara de Campos',
    'Tariego de Cerrato',
    'Torquemada',
    'Torremormojón',
    'Triollo',
    'Valbuena de Pisuerga',
    'Valdeolmillos',
    'Valderrábano',
    'Valde-Ucieza',
    'Valle de Cerrato',
    'Valle del Retortillo',
    'Velilla del Río Carrión',
    'Venta de Baños',
    'Vertavillo',
    'Villabasta de Valdavia',
    'Villacidaler',
    'Villaconancio',
    'Villada',
    'Villaeles de Valdavia',
    'Villahán',
    'Villaherreros',
    'Villalaco',
    'Villalba de Guardo',
    'Villalcázar de Sirga',
    'Villalcón',
    'Villalobón',
    'Villaluenga de la Vega',
    'Villamartín de Campos',
    'Villamediana',
    'Villameriel',
    'Villamoronta',
    'Villamuera de la Cueza',
    'Villamuriel de Cerrato',
    'Villanueva del Rebollar',
    'Villanuño de Valdavia',
    'Villaprovedo',
    'Villarmentero de Campos',
    'Villarrabé',
    'Villarramiel',
    'Villasarracino',
    'Villasila de Valdavia',
    'Villaturde',
    'Villaumbrales',
    'Villaviudas',
    'Villerías de Campos',
    'Villodre',
    'Villodrigo',
    'Villoldo',
    'Villota del Páramo',
    'Villovieco',
  ],
  Pontevedra: [
    'A Cañiza',
    'A Estrada',
    'A Guarda',
    'A Illa de Arousa',
    'A Lama',
    'Agolada',
    'Arbo',
    'As Neves',
    'Baiona',
    'Barro',
    'Bueu',
    'Caldas de Reis',
    'Cambados',
    'Campo Lameiro',
    'Cangas',
    'Catoira',
    'Cerdedo',
    'Cotobade',
    'Covelo',
    'Crecente',
    'Cuntis',
    'Dozón',
    'Forcarei',
    'Fornelos de Montes',
    'Gondomar',
    'Lalín',
    'Marín',
    'Meaño',
    'Meis',
    'Moaña',
    'Mondariz',
    'Mondariz-Balneario',
    'Moraña',
    'Mos',
    'Nigrán',
    'O Grove',
    'O Porriño',
    'O Rosal',
    'Oia',
    'Pazos de Borbén',
    'Poio',
    'Ponte Caldelas',
    'Ponteareas',
    'Pontecesures',
    'Pontevedra',
    'Portas',
    'Redondela',
    'Ribadumia',
    'Rodeiro',
    'Salceda de Caselas',
    'Salvaterra de Miño',
    'Sanxenxo',
    'Silleda',
    'Soutomaior',
    'Tomiño',
    'Tui',
    'Valga',
    'Vigo',
    'Vila de Cruces',
    'Vilaboa',
    'Vilagarcía de Arousa',
    'Vilanova de Arousa',
  ],
  Salamanca: [
    'Abusejo',
    'Agallas',
    'Ahigal de los Aceiteros',
    'Ahigal de Villarino',
    'Alaraz',
    'Alba de Tormes',
    'Alba de Yeltes',
    'Alconada',
    'Aldea del Obispo',
    'Aldeacipreste',
    'Aldeadávila de la Ribera',
    'Aldealengua',
    'Aldeanueva de Figueroa',
    'Aldeanueva de la Sierra',
    'Aldearrodrigo',
    'Aldearrubia',
    'Aldeaseca de Alba',
    'Aldeaseca de la Frontera',
    'Aldeatejada',
    'Aldeavieja de Tormes',
    'Aldehuela de la Bóveda',
    'Aldehuela de Yeltes',
    'Almenara de Tormes',
    'Almendra',
    'Anaya de Alba',
    'Añover de Tormes',
    'Arabayona de Mógica',
    'Arapiles',
    'Arcediano',
    'Armenteros',
    'Babilafuente',
    'Bañobárez',
    'Barbadillo',
    'Barbalos',
    'Barceo',
    'Barruecopardo',
    'Béjar',
    'Beleña',
    'Bermellar',
    'Berrocal de Huebra',
    'Berrocal de Salvatierra',
    'Boada',
    'Bogajo',
    'Bóveda del Río Almar',
    'Brincones',
    'Buenamadre',
    'Buenavista',
    'Cabeza del Caballo',
    'Cabezabellosa de la Calzada',
    'Cabrerizos',
    'Cabrillas',
    'Calvarrasa de Abajo',
    'Calvarrasa de Arriba',
    'Calzada de Don Diego',
    'Calzada de Valdunciel',
    'Campillo de Azaba',
    'Candelario',
    'Canillas de Abajo',
    'Cantagallo',
    'Cantalapiedra',
    'Cantalpino',
    'Cantaracillo',
    'Carbajosa de la Sagrada',
    'Carpio de Azaba',
    'Carrascal de Barregas',
    'Carrascal del Obispo',
    'Casafranca',
    'Casillas de Flores',
    'Castellanos de Moriscos',
    'Castellanos de Villiquera',
    'Castillejo de Martín Viejo',
    'Castraz',
    'Cepeda',
    'Cereceda de la Sierra',
    'Cerezal de Peñahorcada',
    'Cerralbo',
    'Cespedosa de Tormes',
    'Chagarcía Medianero',
    'Cilleros de la Bastida',
    'Cipérez',
    'Ciudad Rodrigo',
    'Coca de Alba',
    'Colmenar de Montemayor',
    'Cordovilla',
    'Cristóbal',
    'Dios le Guarde',
    'Doñinos de Ledesma',
    'Doñinos de Salamanca',
    'Ejeme',
    'El Arco',
    'El Bodón',
    'El Cabaco',
    'El Campo de Peñaranda',
    'El Cerro',
    'El Cubo de Don Sancho',
    'El Maíllo',
    'El Manzano',
    'El Milano',
    'El Payo',
    'El Pedroso de la Armuña',
    'El Pino de Tormes',
    'El Sahugo',
    'El Tejado',
    'El Tornadizo',
    'Encina de San Silvestre',
    'Encinas de Abajo',
    'Encinas de Arriba',
    'Encinasola de los Comendadores',
    'Endrinal',
    'Escurial de la Sierra',
    'Espadaña',
    'Espeja',
    'Espino de la Orbada',
    'Florida de Liébana',
    'Forfoleda',
    'Frades de la Sierra',
    'Fresnedoso',
    'Fresno Alhándiga',
    'Fuenteguinaldo',
    'Fuenteliante',
    'Fuenterroble de Salvatierra',
    'Fuentes de Béjar',
    'Fuentes de Oñoro',
    'Gajates',
    'Galindo y Perahuy',
    'Galinduste',
    'Galisancho',
    'Gallegos de Argañán',
    'Gallegos de Solmirón',
    'Garcibuey',
    'Garcihernández',
    'Garcirrey',
    'Gejuelo del Barro',
    'Golpejas',
    'Gomecello',
    'Guadramiro',
    'Guijo de Ávila',
    'Guijuelo',
    'Herguijuela de Ciudad Rodrigo',
    'Herguijuela de la Sierra',
    'Herguijuela del Campo',
    'Hinojosa de Duero',
    'Horcajo de Montemayor',
    'Horcajo Medianero',
    'Huerta',
    'Iruelos',
    'Ituero de Azaba',
    'Juzbado',
    'La Alameda de Gardón',
    'La Alamedilla',
    'La Alberca',
    'La Alberguería de Argañán',
    'La Atalaya',
    'La Bastida',
    'La Bouza',
    'La Cabeza de Béjar',
    'La Calzada de Béjar',
    'La Encina',
    'La Fregeneda',
    'La Fuente de San Esteban',
    'La Hoya',
    'La Mata de Ledesma',
    'La Maya',
    'La Orbada',
    'La Peña',
    'La Redonda',
    'La Rinconada de la Sierra',
    'La Sagrada',
    'La Sierpe',
    'La Tala',
    'La Vellés',
    'La Vídola',
    'La Zarza de Pumareda',
    'Lagunilla',
    'Larrodrigo',
    'Las Casas del Conde',
    'Las Veguillas',
    'Ledesma',
    'Ledrada',
    'Linares de Riofrío',
    'Los Santos',
    'Lumbrales',
    'Machacón',
    'Macotera',
    'Madroñal',
    'Malpartida',
    'Mancera de Abajo',
    'Martiago',
    'Martín de Yeltes',
    'Martinamor',
    'Masueco',
    'Matilla de los Caños del Río',
    'Membribe de la Sierra',
    'Mieza',
    'Miranda de Azán',
    'Miranda del Castañar',
    'Mogarraz',
    'Molinillo',
    'Monforte de la Sierra',
    'Monleón',
    'Monleras',
    'Monsagro',
    'Montejo',
    'Montemayor del Río',
    'Monterrubio de Armuña',
    'Monterrubio de la Sierra',
    'Morasverdes',
    'Morille',
    'Moríñigo',
    'Moriscos',
    'Moronta',
    'Mozárbez',
    'Narros de Matalayegua',
    'Nava de Béjar',
    'Nava de Francia',
    'Nava de Sotrobal',
    'Navacarros',
    'Navales',
    'Navalmoral de Béjar',
    'Navamorales',
    'Navarredonda de la Rinconada',
    'Navasfrías',
    'Negrilla de Palencia',
    'Olmedo de Camaces',
    'Pajares de la Laguna',
    'Palacios del Arzobispo',
    'Palaciosrubios',
    'Palencia de Negrilla',
    'Parada de Arriba',
    'Parada de Rubiales',
    'Paradinas de San Juan',
    'Pastores',
    'Pedraza de Alba',
    'Pedrosillo de Alba',
    'Pedrosillo de los Aires',
    'Pedrosillo el Ralo',
    'Pelabravo',
    'Pelarrodríguez',
    'Pelayos',
    'Peñacaballera',
    'Peñaparda',
    'Peñaranda de Bracamonte',
    'Peñarandilla',
    'Peralejos de Abajo',
    'Peralejos de Arriba',
    'Pereña de la Ribera',
    'Peromingo',
    'Pinedas',
    'Pitiegua',
    'Pizarral',
    'Poveda de las Cintas',
    'Pozos de Hinojo',
    'Puebla de Azaba',
    'Puebla de San Medel',
    'Puebla de Yeltes',
    'Puente del Congosto',
    'Puertas',
    'Puerto de Béjar',
    'Puerto Seguro',
    'Rágama',
    'Retortillo',
    'Robleda',
    'Robliza de Cojos',
    'Rollán',
    'Saelices el Chico',
    'Salamanca',
    'Saldeana',
    'Salmoral',
    'Salvatierra de Tormes',
    'San Cristóbal de la Cuesta',
    'San Esteban de la Sierra',
    'San Felices de los Gallegos',
    'San Martín del Castañar',
    'San Miguel de Valero',
    'San Miguel del Robledo',
    'San Morales',
    'San Muñoz',
    'San Pedro de Rozados',
    'San Pedro del Valle',
    'San Pelayo de Guareña',
    'Sanchón de la Ribera',
    'Sanchón de la Sagrada',
    'Sanchotello',
    'Sancti-Spíritus',
    'Sando',
    'Santa María de Sando',
    'Santa Marta de Tormes',
    'Santiago de la Puebla',
    'Santibáñez de Béjar',
    'Santibáñez de la Sierra',
    'Santiz',
    'Sardón de los Frailes',
    'Saucelle',
    'Sepulcro-Hilario',
    'Sequeros',
    'Serradilla del Arroyo',
    'Serradilla del Llano',
    'Sieteiglesias de Tormes',
    'Sobradillo',
    'Sorihuela',
    'Sotoserrano',
    'Tabera de Abajo',
    'Tamames',
    'Tarazona de Guareña',
    'Tardáguila',
    'Tejeda y Segoyuela',
    'Tenebrón',
    'Terradillos',
    'Topas',
    'Tordillos',
    'Torresmenudas',
    'Trabanca',
    'Tremedal de Tormes',
    'Valdecarros',
    'Valdefuentes de Sangusín',
    'Valdehijaderos',
    'Valdelacasa',
    'Valdelageve',
    'Valdelosa',
    'Valdemierque',
    'Valderrodrigo',
    'Valdunciel',
    'Valero',
    'Vallejera de Riofrío',
    'Valsalabroso',
    'Valverde de Valdelacasa',
    'Valverdón',
    'Vecinos',
    'Vega de Tirados',
    'Ventosa del Río Almar',
    'Villaflores',
    'Villagonzalo de Tormes',
    'Villalba de los Llanos',
    'Villamayor',
    'Villanueva del Conde',
    'Villar de Argañán',
    'Villar de Ciervo',
    'Villar de Gallimazo',
    'Villar de la Yegua',
    'Villar de Peralonso',
    'Villar de Samaniego',
    'Villares de la Reina',
    'Villares de Yeltes',
    'Villarino de los Aires',
    'Villarmayor',
    'Villarmuerto',
    'Villasbuenas',
    'Villasdardo',
    'Villaseco de los Gamitos',
    'Villaseco de los Reyes',
    'Villasrubias',
    'Villaverde de Guareña',
    'Villavieja de Yeltes',
    'Villoria',
    'Villoruela',
    'Vilvestre',
    'Vitigudino',
    'Yecla de Yeltes',
    'Zamarra',
    'Zamayón',
    'Zarapicos',
    'Zorita de la Frontera',
  ],
  'Santa Cruz de Tenerife': [
    'Adeje',
    'Agulo',
    'Alajeró',
    'Arafo',
    'Arico',
    'Arona',
    'Barlovento',
    'Breña Alta',
    'Breña Baja',
    'Buenavista del Norte',
    'Candelaria',
    'El Paso',
    'El Pinar de El Hierro',
    'El Rosario',
    'El Sauzal',
    'El Tanque',
    'Fasnia',
    'Frontera',
    'Fuencaliente de la Palma',
    'Garachico',
    'Garafía',
    'Granadilla de Abona',
    'Guía de Isora',
    'Güímar',
    'Hermigua',
    'Icod de los Vinos',
    'La Guancha',
    'La Matanza de Acentejo',
    'La Orotava',
    'La Victoria de Acentejo',
    'Los Llanos de Aridane',
    'Los Realejos',
    'Los Silos',
    'Puerto de la Cruz',
    'Puntagorda',
    'Puntallana',
    'San Andrés y Sauces',
    'San Cristóbal de La Laguna',
    'San Juan de la Rambla',
    'San Miguel de Abona',
    'San Sebastián de la Gomera',
    'Santa Cruz de la Palma',
    'Santa Cruz de Tenerife',
    'Santa Úrsula',
    'Santiago del Teide',
    'Tacoronte',
    'Tazacorte',
    'Tegueste',
    'Tijarafe',
    'Valle Gran Rey',
    'Vallehermoso',
    'Valverde',
    'Vilaflor',
    'Villa de Mazo',
  ],
  Segovia: [
    'Abades',
    'Adrada de Pirón',
    'Adrados',
    'Aguilafuente',
    'Alconada de Maderuelo',
    'Aldea Real',
    'Aldealcorvo',
    'Aldealengua de Pedraza',
    'Aldealengua de Santa María',
    'Aldeanueva de la Serrezuela',
    'Aldeanueva del Codonal',
    'Aldeasoña',
    'Aldehorno',
    'Aldehuela del Codonal',
    'Aldeonte',
    'Anaya',
    'Añe',
    'Arahuetes',
    'Arcones',
    'Arevalillo de Cega',
    'Armuña',
    'Ayllón',
    'Barbolla',
    'Basardilla',
    'Bercial',
    'Bercimuel',
    'Bernardos',
    'Bernardos de Porreros',
    'Boceguillas',
    'Brieva',
    'Caballar',
    'Cabañas de Polendos',
    'Cabezuela',
    'Calabazas de Fuentidueña',
    'Campo de San Pedro',
    'Cantalejo',
    'Cantimpalos',
    'Carbonero el Mayor',
    'Carrascal del Río',
    'Casla',
    'Castillejo de Mesleón',
    'Castro de Fuentidueña',
    'Castrojimeno',
    'Castroserna de Abajo',
    'Castroserracín',
    'Cedillo de la Torre',
    'Cerezo de Abajo',
    'Cerezo de Arriba',
    'Chañe',
    'Cilleruelo de San Mamés',
    'Cobos de Fuentidueña',
    'Coca',
    'Codorniz',
    'Collado Hermoso',
    'Condado de Castilnovo',
    'Corral de Ayllón',
    'Cozuelos de Fuentidueña',
    'Cubillo',
    'Cuéllar',
    'Cuevas de Provanco',
    'Domingo García',
    'Donhierro',
    'Duruelo',
    'El Espinar',
    'Encinas',
    'Encinillas',
    'Escalona del Prado',
    'Escarabajosa de Cabezas',
    'Escobar de Polendos',
    'Espirdo',
    'Fresneda de Cuéllar',
    'Fresno de Cantespino',
    'Fresno de la Fuente',
    'Frumales',
    'Fuente de Santa Cruz',
    'Fuente el Olmo de Fuentidueña',
    'Fuente el Olmo de Íscar',
    'Fuentepelayo',
    'Fuentepiñel',
    'Fuenterrebollo',
    'Fuentesaúco de Fuentidueña',
    'Fuentesoto',
    'Fuentidueña',
    'Gallegos',
    'Garcillán',
    'Gomezserracín',
    'Grajera',
    'Honrubia de la Cuesta',
    'Hontalbilla',
    'Hontanares de Eresma',
    'Ituero y Lama',
    'Juarros de Riomoros',
    'Juarros de Voltoya',
    'La Lastrilla',
    'La Losa',
    'La Matilla',
    'Labajos',
    'Laguna de Contreras',
    'Languilla',
    'Lastras de Cuéllar',
    'Lastras del Pozo',
    'Los Huertos',
    'Maderuelo',
    'Marazoleja',
    'Marazuela',
    'Martín Miguel',
    'Martín Muñoz de la Dehesa',
    'Martín Muñoz de las Posadas',
    'Marugán',
    'Mata de Cuéllar',
    'Matabuena',
    'Melque de Cercos',
    'Membibre de la Hoz',
    'Migueláñez',
    'Montejo de Arévalo',
    'Montejo de la Vega de la Serrezuela',
    'Monterrubio',
    'Moral de Hornuez',
    'Mozoncillo',
    'Muñopedro',
    'Muñoveros',
    'Nava de la Asunción',
    'Navafría',
    'Navalilla',
    'Navalmanzano',
    'Navares de Ayuso',
    'Navares de Enmedio',
    'Navares de las Cuevas',
    'Navas de Oro',
    'Navas de Riofrío',
    'Navas de San Antonio',
    'Nieva',
    'Olombrada',
    'Orejana',
    'Ortigosa de Pestaño',
    'Ortigosa del Monte',
    'Otero de Herreros',
    'Pajarejos',
    'Palazuelos de Eresma',
    'Pedraza',
    'Pelayos del Arroyo',
    'Perosillo',
    'Pinarejos',
    'Pinarnegrillo',
    'Pradales',
    'Prádena',
    'Puebla de Pedraza',
    'Rapariegos',
    'Rebollo',
    'Remondo',
    'Riaguas de San Bartolomé',
    'Riaza',
    'Ribota',
    'Riofrío de Riaza',
    'Roda de Eresma',
    'Sacramenia',
    'Samboal',
    'San Cristóbal de Cuéllar',
    'San Cristóbal de la Vega',
    'San Cristóbal de Segovia',
    'San Ildefonso',
    'San Martín y Mudrián',
    'San Miguel de Bernuy',
    'San Pedro de Gaíllos',
    'Sanchonuño',
    'Sangarcía',
    'Santa María la Real de Nieva',
    'Santa Marta del Cerro',
    'Santiuste de Pedraza',
    'Santiuste de San Juan Bautista',
    'Santo Domingo de Pirón',
    'Santo Tomé del Puerto',
    'Sauquillo de Cabezas',
    'Sebúlcor',
    'Segovia',
    'Sepúlveda',
    'Sequera de Fresno',
    'Sotillo',
    'Sotosalbos',
    'Tabanera la Luenga',
    'Tolocirio',
    'Torre Val de San Pedro',
    'Torreadrada',
    'Torrecaballeros',
    'Torrecilla del Pinar',
    'Torreiglesias',
    'Trescasas',
    'Turégano',
    'Urueñas',
    'Valdeprados',
    'Valdevacas de Montejo',
    'Valdevacas y Guijar',
    'Valle de Tabladillo',
    'Vallelado',
    'Valleruela de Pedraza',
    'Valleruela de Sepúlveda',
    'Valseca',
    'Valtiendas',
    'Valverde del Majano',
    'Veganzones',
    'Vegas de Matute',
    'Ventosilla y Tejadilla',
    'Villacastín',
    'Villaverde de Íscar',
    'Villaverde de Montejo',
    'Villeguillo',
    'Yanguas de Eresma',
    'Zarzuela del Monte',
    'Zarzuela del Pinar',
  ],
  Sevilla: [
    'Aguadulce',
    'Alanís',
    'Albaida del Aljarafe',
    'Alcalá de Guadaíra',
    'Alcalá del Río',
    'Alcolea del Río',
    'Algámitas',
    'Almadén de la Plata',
    'Almensilla',
    'Arahal',
    'Aznalcázar',
    'Aznalcóllar',
    'Badolatosa',
    'Benacazón',
    'Bollullos de la Mitación',
    'Bormujos',
    'Brenes',
    'Burguillos',
    'Camas',
    'Cantillana',
    'Cañada Rosal',
    'Carmona',
    'Carrión de los Céspedes',
    'Casariche',
    'Castilblanco de los Arroyos',
    'Castilleja de Guzmán',
    'Castilleja de la Cuesta',
    'Castilleja del Campo',
    'Cazalla de la Sierra',
    'Constantina',
    'Coria del Río',
    'Coripe',
    'Dos Hermanas',
    'Écija',
    'El Castillo de las Guardas',
    'El Coronil',
    'El Cuervo de Sevilla',
    'El Garrobo',
    'El Madroño',
    'El Pedroso',
    'El Real de la Jara',
    'El Ronquillo',
    'El Rubio',
    'El Saucejo',
    'El Viso del Alcor',
    'Espartinas',
    'Estepa',
    'Fuentes de Andalucía',
    'Gelves',
    'Gerena',
    'Gilena',
    'Gines',
    'Guadalcanal',
    'Guillena',
    'Herrera',
    'Huévar del Aljarafe',
    'Isla Mayor',
    'La Algaba',
    'La Campana',
    'La Lantejuela',
    'La Luisiana',
    'La Puebla de Cazalla',
    'La Puebla de los Infantes',
    'La Puebla del Río',
    'La Rinconada',
    'La Roda de Andalucía',
    'Las Cabezas de San Juan',
    'Las Navas de la Concepción',
    'Lebrija',
    'Lora de Estepa',
    'Lora del Río',
    'Los Corrales',
    'Los Molares',
    'Los Palacios y Villafranca',
    'Mairena del Alcor',
    'Mairena del Aljarafe',
    'Marchena',
    'Marinaleda',
    'Martín de la Jara',
    'Montellano',
    'Morón de la Frontera',
    'Olivares',
    'Osuna',
    'Palomares del Río',
    'Paradas',
    'Pedrera',
    'Peñaflor',
    'Pilas',
    'Pruna',
    'Salteras',
    'San Juan de Aznalfarache',
    'San Nicolás del Puerto',
    'Sanlúcar la Mayor',
    'Santiponce',
    'Sevilla',
    'Tocina',
    'Tomares',
    'Umbrete',
    'Utrera',
    'Valencina de la Concepción',
    'Villamanrique de la Condesa',
    'Villanueva de San Juan',
    'Villanueva del Ariscal',
    'Villanueva del Río y Minas',
    'Villaverde del Río',
  ],
  Soria: [
    'Abejar',
    'Adradas',
    'Ágreda',
    'Alconaba',
    'Alcubilla de Avellaneda',
    'Alcubilla de las Peñas',
    'Aldealafuente',
    'Aldealices',
    'Aldealpozo',
    'Aldealseñor',
    'Aldehuela de Periáñez',
    'Alentisque',
    'Aliud',
    'Almajano',
    'Almaluez',
    'Almarza',
    'Almazán',
    'Almazul',
    'Almenar de Soria',
    'Alpanseque',
    'Arancón',
    'Arcos de Jalón',
    'Arenillas',
    'Arévalo de la Sierra',
    'Ausejo de la Sierra',
    'Baraona',
    'Barca',
    'Barcones',
    'Bayubas de Abajo',
    'Bayubas de Arriba',
    'Beratón',
    'Berlanga de Duero',
    'Blacos',
    'Bliecos',
    'Borjabad',
    'Borobia',
    'Buberos',
    'Buitrago',
    'Burgo de Osma-Ciudad de Osma',
    'Cabrejas del Campo',
    'Cabrejas del Pinar',
    'Calatañazor',
    'Caltojar',
    'Candilichera',
    'Cañamaque',
    'Carabantes',
    'Caracena',
    'Carrascosa de Abajo',
    'Carrascosa de la Sierra',
    'Casarejos',
    'Castilfrío de la Sierra',
    'Castillejo de Robledo',
    'Castilruiz',
    'Centenera de Andaluz',
    'Cerbón',
    'Cidones',
    'Cigudosa',
    'Cihuela',
    'Ciria',
    'Cirujales del Río',
    'Coscurita',
    'Covaleda',
    'Cubilla',
    'Cubo de la Solana',
    'Cueva de Ágreda',
    'Dévanos',
    'Deza',
    'Duruelo de la Sierra',
    'El Royo',
    'Escobosa de Almazán',
    'Espeja de San Marcelino',
    'Espejón',
    'Estepa de San Juan',
    'Frechilla de Almazán',
    'Fresno de Caracena',
    'Fuentearmegil',
    'Fuentecambrón',
    'Fuentecantos',
    'Fuentelmonge',
    'Fuentelsaz de Soria',
    'Fuentepinilla',
    'Fuentes de Magaña',
    'Fuentestrún',
    'Garray',
    'Golmayo',
    'Gómara',
    'Gormaz',
    'Herrera de Soria',
    'Hinojosa del Campo',
    'La Losilla',
    'La Póveda de Soria',
    'La Riba de Escalote',
    'Langa de Duero',
    'Las Aldehuelas',
    'Liceras',
    'Los Rábanos',
    'Los Villares de Soria',
    'Magaña',
    'Maján',
    'Matalebreras',
    'Matamala de Almazán',
    'Medinaceli',
    'Miño de Medinaceli',
    'Miño de San Esteban',
    'Molinos de Duero',
    'Momblona',
    'Monteagudo de las Vicarías',
    'Montejo de Tiermes',
    'Montenegro de Cameros',
    'Morón de Almazán',
    'Muriel de la Fuente',
    'Muriel Viejo',
    'Nafría de Ucero',
    'Narros',
    'Navaleno',
    'Nepas',
    'Nolay',
    'Noviercas',
    'Ólvega',
    'Oncala',
    'Pinilla del Campo',
    'Portillo de Soria',
    'Pozalmuro',
    'Quintana Redonda',
    'Quintanas de Gormaz',
    'Quiñonería',
    'Rebollar',
    'Recuerda',
    'Rello',
    'Renieblas',
    'Retortillo de Soria',
    'Reznos',
    'Rioseco de Soria',
    'Rollamienta',
    'Salduero',
    'San Esteban de Gormaz',
    'San Felices',
    'San Leonardo de Yagüe',
    'San Pedro Manrique',
    'Santa Cruz de Yanguas',
    'Santa María de Huerta',
    'Santa María de las Hoyas',
    'Serón de Nágima',
    'Soliedra',
    'Soria',
    'Sotillo del Rincón',
    'Suellacabras',
    'Tajahuerce',
    'Tajueco',
    'Talveila',
    'Tardelcuende',
    'Taroda',
    'Tejado',
    'Torlengua',
    'Torreblacos',
    'Torrubia de Soria',
    'Trévago',
    'Ucero',
    'Vadillo',
    'Valdeavellano de Tera',
    'Valdegeña',
    'Valdelagua del Cerro',
    'Valdemaluque',
    'Valdenebro',
    'Valdeprado',
    'Valderrodilla',
    'Valtajeros',
    'Velamazán',
    'Velilla de la Sierra',
    'Velilla de los Ajos',
    'Viana de Duero',
    'Villaciervos',
    'Villanueva de Gormaz',
    'Villar del Ala',
    'Villar del Campo',
    'Villar del Río',
    'Villasayas',
    'Villaseca de Arciel',
    'Vinuesa',
    'Vizmanos',
    'Vozmediano',
    'Yanguas',
    'Yelo',
  ],
  Tarragona: [
    'Aiguamúrcia',
    'Albinyana',
    'Alcanar',
    'Alcover',
    'Aldover',
    'Alfara de Carles',
    'Alforja',
    'Alió',
    'Almoster',
    'Altafulla',
    'Amposta',
    'Arbolí',
    'Arnes',
    'Ascó',
    'Banyeres del Penedès',
    'Barberà de la Conca',
    'Batea',
    'Bellmunt del Priorat',
    'Bellvei',
    'Benifallet',
    'Benissanet',
    'Blancafort',
    'Bonastre',
    'Bot',
    'Botarell',
    'Bràfim',
    'Cabacés',
    'Cabra del Camp',
    'Calafell',
    'Camarles',
    'Cambrils',
    'Capafonts',
    'Capçanes',
    'Caseres',
    'Castellvell del Camp',
    'Colldejou',
    'Conesa',
    'Constantí',
    "Corbera d'Ebre",
    'Cornudella de Montsant',
    'Creixell',
    'Cunit',
    'Deltebre',
    'Duesaigües',
    'El Catllar',
    'El Lloar',
    'El Masroig',
    'El Milà',
    'El Molar',
    'El Montmell',
    'El Morell',
    'El Perelló',
    'El Pinell de Brai',
    'El Pla de Santa Maria',
    "El Pont d'Armentera",
    'El Rourell',
    'El Vendrell',
    'Els Garidells',
    'Els Guiamets',
    'Els Pallaresos',
    'Falset',
    'Figuerola del Camp',
    'Flix',
    'Forès',
    'Freginals',
    'Gandesa',
    'Garcia',
    'Ginestar',
    'Godall',
    'Gratallops',
    'Horta de Sant Joan',
    "L' Albiol",
    "L' Aldea",
    "L' Aleixar",
    "L' Ametlla de Mar",
    "L' Ampolla",
    "L' Arboç",
    "L' Argentera",
    "L' Espluga de Francolí",
    'La Bisbal de Falset',
    'La Bisbal del Penedès',
    'La Fatarella',
    'La Febró',
    'La Figuera',
    'La Galera',
    'La Masó',
    'La Morera de Montsant',
    'La Nou de Gaià',
    "La Palma d'Ebre",
    'La Pobla de Mafumet',
    'La Pobla de Massaluca',
    'La Pobla de Montornès',
    'La Riba',
    'La Riera de Gaià',
    'La Secuita',
    'La Selva del Camp',
    'La Sénia',
    'La Torre de Fontaubella',
    "La Torre de l'Espanyol",
    'La Vilella Alta',
    'La Vilella Baixa',
    'Les Borges del Camp',
    'Les Piles',
    'Llorac',
    'Llorenç del Penedès',
    'Marçà',
    'Margalef',
    'Mas de Barberans',
    'Masdenverge',
    'Masllorenç',
    'Maspujols',
    'Miravet',
    'Montblanc',
    'Montbrió del Camp',
    'Montferri',
    'Mont-ral',
    'Mont-roig del Camp',
    "Móra d'Ebre",
    'Móra la Nova',
    'Nulles',
    'Passanant i Belltall',
    'Paüls',
    'Perafort',
    'Pira',
    'Poboleda',
    'Pontils',
    'Porrera',
    'Pradell de la Teixeta',
    'Prades',
    'Prat de Comte',
    'Pratdip',
    'Puigpelat',
    'Querol',
    'Rasquera',
    'Renau',
    'Reus',
    "Riba-roja d'Ebre",
    'Riudecanyes',
    'Riudecols',
    'Riudoms',
    'Rocafort de Queralt',
    'Roda de Barà',
    'Rodonyà',
    'Roquetes',
    'Salomó',
    'Salou',
    'Sant Carles de la Ràpita',
    'Sant Jaume dels Domenys',
    "Sant Jaume d'Enveja",
    'Santa Bàrbara',
    'Santa Coloma de Queralt',
    'Santa Oliva',
    'Sarral',
    'Savallà del Comtat',
    'Senan',
    'Solivella',
    'Tarragona',
    'Tivenys',
    'Tivissa',
    'Torredembarra',
    'Torroja del Priorat',
    'Tortosa',
    'Ulldecona',
    'Ulldemolins',
    'Vallclara',
    'Vallfogona de Riucorb',
    'Vallmoll',
    'Valls',
    "Vandellòs i l'Hospitalet de l'Infant",
    'Vespella de Gaià',
    'Vilabella',
    'Vilalba dels Arcs',
    'Vilallonga del Camp',
    'Vilanova de Prades',
    "Vilanova d'Escornalbou",
    'Vilaplana',
    'Vila-rodona',
    'Vila-seca',
    'Vilaverd',
    'Vimbodí i Poblet',
    'Vinebre',
    'Vinyols i els Arcs',
    'Xerta',
  ],
  Teruel: [
    'Ababuj',
    'Abejuela',
    'Aguatón',
    'Aguaviva',
    'Aguilar del Alfambra',
    'Alacón',
    'Alba',
    'Albalate del Arzobispo',
    'Albarracín',
    'Albentosa',
    'Alcaine',
    'Alcalá de la Selva',
    'Alcañiz',
    'Alcorisa',
    'Alfambra',
    'Aliaga',
    'Allepuz',
    'Alloza',
    'Allueva',
    'Almohaja',
    'Alobras',
    'Alpeñés',
    'Anadón',
    'Andorra',
    'Arcos de las Salinas',
    'Arens de Lledó',
    'Argente',
    'Ariño',
    'Azaila',
    'Bádenas',
    'Báguena',
    'Bañón',
    'Barrachina',
    'Bea',
    'Beceite',
    'Bello',
    'Belmonte de San José',
    'Berge',
    'Bezas',
    'Blancas',
    'Blesa',
    'Bordón',
    'Bronchales',
    'Bueña',
    'Burbáguena',
    'Cabra de Mora',
    'Calaceite',
    'Calamocha',
    'Calanda',
    'Calomarde',
    'Camañas',
    'Camarena de la Sierra',
    'Camarillas',
    'Caminreal',
    'Cantavieja',
    'Cañada de Benatanduz',
    'Cañada Vellida',
    'Cañizar del Olivar',
    'Cascante del Río',
    'Castejón de Tornos',
    'Castel de Cabra',
    'Castellote',
    'Castelnou',
    'Castelserás',
    'Cedrillas',
    'Celadas',
    'Cella',
    'Corbalán',
    'Cortes de Aragón',
    'Cosa',
    'Cretas',
    'Crivillén',
    'Cubla',
    'Cucalón',
    'Cuevas de Almudén',
    'Cuevas Labradas',
    'Ejulve',
    'El Castellar',
    'El Cuervo',
    'El Pobo',
    'El Vallecillo',
    'Escorihuela',
    'Escucha',
    'Estercuel',
    'Ferreruela de Huerva',
    'Fonfría',
    'Formiche Alto',
    'Fórnoles',
    'Fortanete',
    'Foz-Calanda',
    'Frías de Albarracín',
    'Fuenferrada',
    'Fuentes Calientes',
    'Fuentes Claras',
    'Fuentes de Rubielos',
    'Fuentespalda',
    'Galve',
    'Gargallo',
    'Gea de Albarracín',
    'Griegos',
    'Guadalaviar',
    'Gúdar',
    'Híjar',
    'Hinojosa de Jarque',
    'Huesa del Común',
    'Jabaloyas',
    'Jarque de la Val',
    'Jatiel',
    'Jorcas',
    'Josa',
    'La Cañada de Verich',
    'La Cerollera',
    'La Codoñera',
    'La Cuba',
    'La Fresneda',
    'La Ginebrosa',
    'La Hoz de la Vieja',
    'La Iglesuela del Cid',
    'La Mata de los Olmos',
    'La Portellada',
    'La Puebla de Híjar',
    'La Puebla de Valverde',
    'La Zoma',
    'Lagueruela',
    'Lanzuela',
    'Las Parras de Castellote',
    'Libros',
    'Lidón',
    'Linares de Mora',
    'Lledó',
    'Los Olmos',
    'Loscos',
    'Maicas',
    'Manzanera',
    'Martín del Río',
    'Mas de las Matas',
    'Mazaleón',
    'Mezquita de Jarque',
    'Mirambel',
    'Miravete de la Sierra',
    'Molinos',
    'Monforte de Moyuela',
    'Monreal del Campo',
    'Monroyo',
    'Montalbán',
    'Monteagudo del Castillo',
    'Monterde de Albarracín',
    'Mora de Rubielos',
    'Moscardón',
    'Mosqueruela',
    'Muniesa',
    'Noguera de Albarracín',
    'Nogueras',
    'Nogueruelas',
    'Obón',
    'Odón',
    'Ojos Negros',
    'Olba',
    'Oliete',
    'Orihuela del Tremedal',
    'Orrios',
    'Palomar de Arroyos',
    'Pancrudo',
    'Peñarroya de Tastavins',
    'Peracense',
    'Peralejos',
    'Perales del Alfambra',
    'Pitarque',
    'Plou',
    'Pozondón',
    'Pozuel del Campo',
    'Puertomingalvo',
    'Ráfales',
    'Rillo',
    'Riodeva',
    'Ródenas',
    'Royuela',
    'Rubiales',
    'Rubielos de la Cérida',
    'Rubielos de Mora',
    'Salcedillo',
    'Saldón',
    'Samper de Calanda',
    'San Agustín',
    'San Martín del Río',
    'Santa Cruz de Nogueras',
    'Santa Eulalia',
    'Sarrión',
    'Segura de los Baños',
    'Seno',
    'Singra',
    'Terriente',
    'Teruel',
    'Toril y Masegoso',
    'Tormón',
    'Tornos',
    'Torralba de los Sisones',
    'Torre de Arcas',
    'Torre de las Arcas',
    'Torre del Compte',
    'Torre los Negros',
    'Torrecilla de Alcañiz',
    'Torrecilla del Rebollar',
    'Torrelacárcel',
    'Torremocha de Jiloca',
    'Torres de Albarracín',
    'Torrevelilla',
    'Torrijas',
    'Torrijo del Campo',
    'Tramacastiel',
    'Tramacastilla',
    'Tronchón',
    'Urrea de Gaén',
    'Utrillas',
    'Valacloche',
    'Valbona',
    'Valdealgorfa',
    'Valdecuenca',
    'Valdelinares',
    'Valdeltormo',
    'Valderrobres',
    'Valjunquera',
    'Veguillas de la Sierra',
    'Villafranca del Campo',
    'Villahermosa del Campo',
    'Villanueva del Rebollar de la Sierra',
    'Villar del Cobo',
    'Villar del Salz',
    'Villarluengo',
    'Villarquemado',
    'Villarroya de los Pinares',
    'Villastar',
    'Villel',
    'Vinaceite',
    'Vicién',
    'Villanova',
    'Villanúa',
    'Villanueva de Sigena',
    'Yebra de Basa',
    'Yésero',
    'Zaidín',
  ],
  Toledo: [
    'Ajofrín',
    'Alameda de la Sagra',
    'Albarreal de Tajo',
    'Alcabón',
    'Alcañizo',
    'Alcaudete de la Jara',
    'Alcolea de Tajo',
    'Aldea en Cabo',
    'Aldeanueva de Barbarroya',
    'Aldeanueva de San Bartolomé',
    'Almendral de la Cañada',
    'Almonacid de Toledo',
    'Almorox',
    'Añover de Tajo',
    'Arcicóllar',
    'Argés',
    'Azután',
    'Barcience',
    'Bargas',
    'Belvís de la Jara',
    'Borox',
    'Buenaventura',
    'Burguillos de Toledo',
    'Burujón',
    'Cabañas de la Sagra',
    'Cabañas de Yepes',
    'Cabezamesada',
    'Calera y Chozas',
    'Caleruela',
    'Calzada de Oropesa',
    'Camarena',
    'Camarenilla',
    'Camuñas',
    'Cardiel de los Montes',
    'Carmena',
    'Carranque',
    'Carriches',
    'Casarrubios del Monte',
    'Casasbuenas',
    'Castillo de Bayuela',
    'Cazalegas',
    'Cebolla',
    'Cedillo del Condado',
    'Cervera de los Montes',
    'Chozas de Canales',
    'Chueca',
    'Ciruelos',
    'Cobeja',
    'Cobisa',
    'Consuegra',
    'Corral de Almaguer',
    'Cuerva',
    'Domingo Pérez',
    'Dosbarrios',
    'El Campillo de la Jara',
    'El Carpio de Tajo',
    'El Casar de Escalona',
    'El Puente del Arzobispo',
    'El Real de San Vicente',
    'El Romeral',
    'El Toboso',
    'El Viso de San Juan',
    'Erustes',
    'Escalona',
    'Escalonilla',
    'Espinoso del Rey',
    'Esquivias',
    'Fuensalida',
    'Gálvez',
    'Garciotum',
    'Gerindote',
    'Guadamur',
    'Herreruela de Oropesa',
    'Hinojosa de San Vicente',
    'Hontanar',
    'Hormigos',
    'Huecas',
    'Huerta de Valdecarábanos',
    'Illán de Vacas',
    'Illescas',
    'La Estrella',
    'La Guardia',
    'La Iglesuela',
    'La Mata',
    'La Nava de Ricomalillo',
    'La Puebla de Almoradiel',
    'La Puebla de Montalbán',
    'La Pueblanueva',
    'La Torre de Esteban Hambrán',
    'La Villa de Don Fadrique',
    'Lagartera',
    'Las Herencias',
    'Las Ventas con Peña Aguilera',
    'Las Ventas de Retamosa',
    'Las Ventas de San Julián',
    'Layos',
    'Lillo',
    'Lominchar',
    'Los Cerralbos',
    'Los Navalmorales',
    'Los Navalucillos',
    'Los Yébenes',
    'Lucillos',
    'Madridejos',
    'Magán',
    'Malpica de Tajo',
    'Manzaneque',
    'Maqueda',
    'Marjaliza',
    'Marrupe',
    'Mascaraque',
    'Mazarambroz',
    'Mejorada',
    'Menasalbas',
    'Méntrida',
    'Mesegar de Tajo',
    'Miguel Esteban',
    'Mocejón',
    'Mohedas de la Jara',
    'Montearagón',
    'Montesclaros',
    'Mora',
    'Nambroca',
    'Navahermosa',
    'Navalcán',
    'Navalmoralejo',
    'Navamorcuende',
    'Noblejas',
    'Noez',
    'Nombela',
    'Novés',
    'Numancia de la Sagra',
    'Nuño Gómez',
    'Ocaña',
    'Olías del Rey',
    'Ontígola',
    'Orgaz',
    'Oropesa',
    'Otero',
    'Palomeque',
    'Pantoja',
    'Paredes de Escalona',
    'Parrillas',
    'Pelahustán',
    'Pepino',
    'Polán',
    'Portillo de Toledo',
    'Puerto de San Vicente',
    'Pulgar',
    'Quero',
    'Quintanar de la Orden',
    'Quismondo',
    'Recas',
    'Retamoso de la Jara',
    'Rielves',
    'Robledo del Mazo',
    'San Bartolomé de las Abiertas',
    'San Martín de Montalbán',
    'San Martín de Pusa',
    'San Pablo de los Montes',
    'San Román de los Montes',
    'Santa Ana de Pusa',
    'Santa Cruz de la Zarza',
    'Santa Cruz del Retamar',
    'Santa Olalla',
    'Santo Domingo-Caudilla',
    'Sartajada',
    'Segurilla',
    'Seseña',
    'Sevilleja de la Jara',
    'Sonseca',
    'Sotillo de las Palomas',
    'Talavera de la Reina',
    'Tembleque',
    'Toledo',
    'Torralba de Oropesa',
    'Torrecilla de la Jara',
    'Torrico',
    'Torrijos',
    'Totanés',
    'Turleque',
    'Ugena',
    'Urda',
    'Valdeverdeja',
    'Valmojado',
    'Velada',
    'Villacañas',
    'Villafranca de los Caballeros',
    'Villaluenga de la Sagra',
    'Villamiel de Toledo',
    'Villaminaya',
    'Villamuelas',
    'Villanueva de Alcardete',
    'Villanueva de Bogas',
    'Villarejo de Montalbán',
    'Villarrubia de Santiago',
    'Villaseca de la Sagra',
    'Villasequilla',
    'Villatobas',
    'Yeles',
    'Yepes',
    'Yuncler',
    'Yunclillos',
    'Yuncos',
  ],
  Zaragoza: [
    'Abanto',
    'Acered',
    'Agón',
    'Aguarón',
    'Aguilón',
    'Ainzón',
    'Aladrén',
    'Alagón',
    'Alarba',
    'Alberite de San Juan',
    'Albeta',
    'Alborge',
    'Alcalá de Ebro',
    'Alcalá de Moncayo',
    'Alconchel de Ariza',
    'Aldehuela de Liestos',
    'Alfajarín',
    'Alfamén',
    'Alforque',
    'Alhama de Aragón',
    'Almochuel',
    'Almonacid de la Cuba',
    'Almonacid de la Sierra',
    'Alpartir',
    'Ambel',
    'Anento',
    'Aniñón',
    'Añón de Moncayo',
    'Aranda de Moncayo',
    'Arándiga',
    'Ardisa',
    'Ariza',
    'Artieda',
    'Asín',
    'Atea',
    'Ateca',
    'Azuara',
    'Badules',
    'Bagüés',
    'Balconchán',
    'Bárboles',
    'Bardallur',
    'Belchite',
    'Belmonte de Gracián',
    'Berdejo',
    'Berrueco',
    'Biel',
    'Bijuesca',
    'Biota',
    'Bisimbre',
    'Boquiñeni',
    'Bordalba',
    'Borja',
    'Botorrita',
    'Brea de Aragón',
    'Bubierca',
    'Bujaraloz',
    'Bulbuente',
    'Bureta',
    'Cabañas de Ebro',
    'Cabolafuente',
    'Cadrete',
    'Calatayud',
    'Calatorao',
    'Calcena',
    'Calmarza',
    'Campillo de Aragón',
    'Carenas',
    'Cariñena',
    'Caspe',
    'Castejón de Alarba',
    'Castejón de las Armas',
    'Castejón de Valdejasa',
    'Castiliscar',
    'Cervera de la Cañada',
    'Cerveruela',
    'Cetina',
    'Chiprana',
    'Chodes',
    'Cimballa',
    'Cinco Olivas',
    'Clarés de Ribota',
    'Codo',
    'Codos',
    'Contamina',
    'Cosuenda',
    'Cuarte de Huerva',
    'Cubel',
    'Daroca',
    'Ejea de los Caballeros',
    'El Burgo de Ebro',
    'El Buste',
    'El Frago',
    'El Frasno',
    'Embid de Ariza',
    'Encinacorba',
    'Épila',
    'Erla',
    'Escatrón',
    'Fabara',
    'Farlete',
    'Fayón',
    'Figueruelas',
    'Fombuena',
    'Fréscano',
    'Fuendejalón',
    'Fuendetodos',
    'Fuentes de Ebro',
    'Fuentes de Jiloca',
    'Gallocanta',
    'Gallur',
    'Gelsa',
    'Godojos',
    'Gotor',
    'Grisel',
    'Grisén',
    'Herrera de los Navarros',
    'Ibdes',
    'Illueca',
    'Isuerre',
    'Jaraba',
    'Jarque',
    'Jaulín',
    'La Almolda',
    'La Almunia de Doña Godina',
    'La Joyosa',
    'La Muela',
    'La Puebla de Alfindén',
    'La Vilueña',
    'La Zaida',
    'Lagata',
    'Langa del Castillo',
    'Las Cuerlas',
    'Las Pedrosas',
    'Layana',
    'Lécera',
    'Lechón',
    'Leciñena',
    'Letux',
    'Litago',
    'Lituénigo',
    'Lobera de Onsella',
    'Longares',
    'Longás',
    'Los Fayos',
    'Los Pintanos',
    'Lucena de Jalón',
    'Luceni',
    'Luesia',
    'Luesma',
    'Lumpiaque',
    'Luna',
    'Maella',
    'Magallón',
    'Mainar',
    'Malanquilla',
    'Maleján',
    'Mallén',
    'Malón',
    'Maluenda',
    'Manchones',
    'Mara',
    'María de Huerva',
    'Marracos',
    'Mediana de Aragón',
    'Mequinenza',
    'Mesones de Isuela',
    'Mezalocha',
    'Mianos',
    'Miedes de Aragón',
    'Monegrillo',
    'Moneva',
    'Monreal de Ariza',
    'Monterde',
    'Montón',
    'Morata de Jalón',
    'Morata de Jiloca',
    'Morés',
    'Moros',
    'Moyuela',
    'Mozota',
    'Muel',
    'Munébrega',
    'Murero',
    'Murillo de Gállego',
    'Navardún',
    'Nigüella',
    'Nombrevilla',
    'Nonaspe',
    'Novallas',
    'Novillas',
    'Nuévalos',
    'Nuez de Ebro',
    'Olvés',
    'Orcajo',
    'Orera',
    'Orés',
    'Oseja',
    'Osera de Ebro',
    'Paniza',
    'Paracuellos de Jiloca',
    'Paracuellos de la Ribera',
    'Pastriz',
    'Pedrola',
    'Perdiguera',
    'Piedratajada',
    'Pina de Ebro',
    'Pinseque',
    'Plasencia de Jalón',
    'Pleitas',
    'Plenas',
    'Pomer',
    'Pozuel de Ariza',
    'Pozuelo de Aragón',
    'Pradilla de Ebro',
    'Puebla de Albortón',
    'Puendeluna',
    'Purujosa',
    'Quinto',
    'Remolinos',
    'Retascón',
    'Ricla',
    'Romanos',
    'Rueda de Jalón',
    'Ruesca',
    'Sabiñán',
    'Sádaba',
    'Salillas de Jalón',
    'Salvatierra de Esca',
    'Samper del Salz',
    'San Martín de la Virgen de Moncayo',
    'San Mateo de Gállego',
    'Santa Cruz de Grío',
    'Santa Cruz de Moncayo',
    'Santa Eulalia de Gállego',
    'Santed',
    'Sástago',
    'Sediles',
    'Sestrica',
    'Sierra de Luna',
    'Sigüés',
    'Sisamón',
    'Sobradiel',
    'Sos del Rey Católico',
    'Tabuenca',
    'Talamantes',
    'Tarazona',
    'Tauste',
    'Terrer',
    'Tierga',
    'Tobed',
    'Torralba de los Frailes',
    'Torralba de Ribota',
    'Torralbilla',
    'Torrehermosa',
    'Torrelapaja',
    'Torrellas',
    'Torres de Berrellén',
    'Torrijo de la Cañada',
    'Tosos',
    'Trasmoz',
    'Trasobares',
    'Uncastillo',
    'Undués de Lerda',
    'Urrea de Jalón',
    'Urriés',
    'Used',
    'Utebo',
    'Val de San Martín',
    'Valdehorna',
    'Valmadrid',
    'Valpalmas',
    'Valtorres',
    'Velilla de Ebro',
    'Velilla de Jiloca',
    'Vera de Moncayo',
    'Vierlas',
    'Villadoz',
    'Villafeliche',
    'Villafranca de Ebro',
    'Villahermosa del Campo',
    'Villalba de Perejil',
    'Villalengua',
    'Villamayor de Gállego',
    'Villanueva de Gállego',
    'Villanueva de Huerva',
    'Villanueva de Jiloca',
    'Villar de los Navarros',
    'Villarreal de Huerva',
    'Villarroya de la Sierra',
    'Villarroya del Campo',
    'Vistabella',
    'Zaragoza',
    'Zuera',
  ],
  'Valencia/València': [
    'Ademuz',
    'Ador',
    'Agullent',
    'Aielo de Malferit',
    'Aielo de Rugat',
    'Alaquàs',
    'Albaida',
    'Albal',
    'Albalat de la Ribera',
    'Albalat dels Sorells',
    'Albalat dels Tarongers',
    'Alberic',
    'Alborache',
    'Alboraya',
    'Albuixech',
    'Alcàntera de Xúquer',
    'Alcàsser',
    'Alcublas',
    'Aldaia',
    'Alfafar',
    'Alfara de Algimia',
    'Alfara del Patriarca',
    'Alfarp',
    'Alfarrasí',
    'Alfauir',
    'Algar de Palancia',
    'Algemesí',
    'Algimia de Alfara',
    'Alginet',
    'Almàssera',
    'Almiserà',
    'Almoines',
    'Almussafes',
    'Alpuente',
    'Alzira',
    'Andilla',
    'Anna',
    'Antella',
    'Aras de los Olmos',
    "Atzeneta d'Albaida",
    'Ayora',
    'Barx',
    'Barxeta',
    'Bèlgida',
    'Bellreguard',
    'Bellús',
    'Benagéber',
    'Benaguasil',
    'Benavites',
    'Beneixida',
    'Benetússer',
    'Beniarjó',
    'Beniatjar',
    'Benicolet',
    'Benicull de Xúquer',
    'Benifaió',
    'Benifairó de la Valldigna',
    'Benifairó de les Valls',
    'Beniflá',
    'Benigánim',
    'Benimodo',
    'Benimuslem',
    'Beniparrell',
    'Benirredrà',
    'Benisanó',
    'Benissoda',
    'Benisuera',
    'Bétera',
    'Bicorp',
    'Bocairent',
    'Bolbaite',
    'Bonrepòs i Mirambell',
    'Bufali',
    'Bugarra',
    'Buñol',
    'Burjassot',
    'Calles',
    'Camporrobles',
    'Canals',
    "Canet d'En Berenguer",
    'Carcaixent',
    'Càrcer',
    'Carlet',
    'Carrícola',
    'Casas Altas',
    'Casas Bajas',
    'Casinos',
    'Castelló de Rugat',
    'Castellonet de la Conquesta',
    'Castielfabib',
    'Catadau',
    'Catarroja',
    'Caudete de las Fuentes',
    'Cerdà',
    'Chella',
    'Chelva',
    'Chera',
    'Cheste',
    'Chiva',
    'Chulilla',
    'Cofrentes',
    'Corbera',
    'Cortes de Pallás',
    'Cotes',
    'Cullera',
    'Daimús',
    'Domeño',
    'Dos Aguas',
    'El Palomar',
    'Emperador',
    'Enguera',
    'Estivella',
    'Estubeny',
    'Faura',
    'Favara',
    'Foios',
    'Fontanars dels Alforins',
    'Fortaleny',
    'Fuenterrobles',
    'Gandia',
    'Gátova',
    'Gavarda',
    'Genovés',
    'Gestalgar',
    'Gilet',
    'Godella',
    'Godelleta',
    'Guadasequies',
    'Guadassuar',
    'Guardamar de la Safor',
    'Higueruelas',
    'Jalance',
    'Jarafuel',
    "L' Alcúdia",
    "L' Alcúdia de Crespins",
    "L' Alqueria de la Comtessa",
    "L' Eliana",
    "L' Ènova",
    "L' Olleria",
    'La Font de la Figuera',
    "La Font d'En Carròs",
    'La Granja de la Costera',
    'La Llosa de Ranes',
    'La Pobla de Farnals',
    'La Pobla de Vallbona',
    'La Pobla del Duc',
    'La Pobla Llarga',
    'La Yesa',
    'Llanera de Ranes',
    'Llaurí',
    'Llíria',
    'Llocnou de la Corona',
    'Llocnou de Sant Jeroni',
    "Llocnou d'En Fenollet",
    'Llombai',
    'Llutxent',
    'Loriguilla',
    'Losa del Obispo',
    'Macastre',
    'Manises',
    'Manuel',
    'Marines',
    'Masalavés',
    'Massalfassar',
    'Massamagrell',
    'Massanassa',
    'Meliana',
    'Millares',
    'Miramar',
    'Mislata',
    'Mogente/Moixent',
    'Moncada',
    'Montaverner',
    'Montesa',
    'Montitxelvo/Montichelvo',
    'Montroy',
    'Montserrat',
    'Museros',
    'Náquera',
    'Navarrés',
    'Novelé/Novetlè',
    'Oliva',
    'Olocau',
    'Ontinyent',
    'Otos',
    'Paiporta',
    'Palma de Gandía',
    'Palmera',
    'Paterna',
    'Pedralba',
    'Petrés',
    'Picanya',
    'Picassent',
    'Piles',
    'Pinet',
    'Polinyà de Xúquer',
    'Potríes',
    'Puçol',
    'Puebla de San Miguel',
    'Puig',
    'Quart de les Valls',
    'Quart de Poblet',
    'Quartell',
    'Quatretonda',
    'Quesa',
    'Rafelbuñol/Rafelbunyol',
    'Rafelcofer',
    'Rafelguaraf',
    'Ráfol de Salem',
    'Real de Gandía',
    'Real de Montroi',
    'Requena',
    'Riba-roja de Túria',
    'Riola',
    'Rocafort',
    'Rotglà i Corberà',
    'Rótova',
    'Rugat',
    'Sagunto/Sagunt',
    'Salem',
    'San Antonio de Benagéber',
    "Sant Joan de l'Ènova",
    'Sedaví',
    'Segart',
    'Sellent',
    'Sempere',
    'Senyera',
    'Serra',
    'Siete Aguas',
    'Silla',
    'Simat de la Valldigna',
    'Sinarcas',
    'Sollana',
    'Sot de Chera',
    'Sueca',
    'Sumacàrcer',
    'Tavernes Blanques',
    'Tavernes de la Valldigna',
    'Teresa de Cofrentes',
    'Terrateig',
    'Titaguas',
    'Torrebaja',
    'Torrella',
    'Torrent',
    'Torres Torres',
    'Tous',
    'Tuéjar',
    'Turís',
    'Utiel',
    'Valencia',
    'Vallada',
    'Vallanca',
    'Vallés',
    'Venta del Moro',
    'Vilamarxant',
    'Villalonga',
    'Villanueva de Castellón',
    'Villar del Arzobispo',
    'Villargordo del Cabriel',
    'Vinalesa',
    'Xàtiva',
    'Xeraco',
    'Xeresa',
    'Xirivella',
    'Yátova',
    'Zarra',
  ],
  Valladolid: [
    'Adalia',
    'Aguasal',
    'Aguilar de Campos',
    'Alaejos',
    'Alcazarén',
    'Aldea de San Miguel',
    'Aldeamayor de San Martín',
    'Almenara de Adaja',
    'Amusquillo',
    'Arroyo de la Encomienda',
    'Ataquines',
    'Bahabón',
    'Barcial de la Loma',
    'Barruelo del Valle',
    'Becilla de Valderaduey',
    'Benafarces',
    'Bercero',
    'Berceruelo',
    'Berrueces',
    'Bobadilla del Campo',
    'Bocigas',
    'Bocos de Duero',
    'Boecillo',
    'Bolaños de Campos',
    'Brahojos de Medina',
    'Bustillo de Chaves',
    'Cabezón de Pisuerga',
    'Cabezón de Valderaduey',
    'Cabreros del Monte',
    'Campaspero',
    'Camporredondo',
    'Canalejas de Peñafiel',
    'Canillas de Esgueva',
    'Carpio',
    'Casasola de Arión',
    'Castrejón de Trabancos',
    'Castrillo de Duero',
    'Castrillo-Tejeriego',
    'Castrobol',
    'Castrodeza',
    'Castromembibre',
    'Castromonte',
    'Castronuevo de Esgueva',
    'Castronuño',
    'Castroponce',
    'Castroverde de Cerrato',
    'Ceinos de Campos',
    'Cervillego de la Cruz',
    'Cigales',
    'Ciguñuela',
    'Cistérniga',
    'Cogeces de Íscar',
    'Cogeces del Monte',
    'Corcos',
    'Corrales de Duero',
    'Cubillas de Santa Marta',
    'Cuenca de Campos',
    'Curiel de Duero',
    'El Campillo',
    'Encinas de Esgueva',
    'Esguevillas de Esgueva',
    'Fombellida',
    'Fompedraza',
    'Fontihoyuelo',
    'Fresno el Viejo',
    'Fuensaldaña',
    'Fuente el Sol',
    'Fuente-Olmedo',
    'Gallegos de Hornija',
    'Gatón de Campos',
    'Geria',
    'Herrín de Campos',
    'Hornillos de Eresma',
    'Íscar',
    'La Mudarra',
    'La Parrilla',
    'La Pedraja de Portillo',
    'La Seca',
    'La Unión de Campos',
    'La Zarza',
    'Laguna de Duero',
    'Langayo',
    'Llano de Olmedo',
    'Lomoviejo',
    'Manzanillo',
    'Marzales',
    'Matapozuelos',
    'Matilla de los Caños',
    'Mayorga',
    'Medina de Rioseco',
    'Medina del Campo',
    'Megeces',
    'Melgar de Abajo',
    'Melgar de Arriba',
    'Mojados',
    'Monasterio de Vega',
    'Montealegre de Campos',
    'Montemayor de Pililla',
    'Moral de la Reina',
    'Moraleja de las Panaderas',
    'Morales de Campos',
    'Mota del Marqués',
    'Mucientes',
    'Muriel',
    'Nava del Rey',
    'Nueva Villa de las Torres',
    'Olivares de Duero',
    'Olmedo',
    'Olmos de Esgueva',
    'Olmos de Peñafiel',
    'Palazuelo de Vedija',
    'Pedrajas de San Esteban',
    'Pedrosa del Rey',
    'Peñafiel',
    'Peñaflor de Hornija',
    'Pesquera de Duero',
    'Piña de Esgueva',
    'Piñel de Abajo',
    'Piñel de Arriba',
    'Pollos',
    'Portillo',
    'Pozal de Gallinas',
    'Pozaldez',
    'Pozuelo de la Orden',
    'Puras',
    'Quintanilla de Arriba',
    'Quintanilla de Onésimo',
    'Quintanilla de Trigueros',
    'Quintanilla del Molar',
    'Rábano',
    'Ramiro',
    'Renedo de Esgueva',
    'Roales de Campos',
    'Robladillo',
    'Roturas',
    'Rubí de Bracamonte',
    'Rueda',
    'Saelices de Mayorga',
    'Salvador de Zapardiel',
    'San Cebrián de Mazote',
    'San Llorente',
    'San Martín de Valvení',
    'San Miguel del Arroyo',
    'San Miguel del Pino',
    'San Pablo de la Moraleja',
    'San Pedro de Latarce',
    'San Pelayo',
    'San Román de Hornija',
    'San Salvador',
    'San Vicente del Palacio',
    'Santa Eufemia del Arroyo',
    'Santervás de Campos',
    'Santibáñez de Valcorba',
    'Santovenia de Pisuerga',
    'Sardón de Duero',
    'Serrada',
    'Siete Iglesias de Trabancos',
    'Simancas',
    'Tamariz de Campos',
    'Tiedra',
    'Tordehumos',
    'Tordesillas',
    'Torre de Esgueva',
    'Torre de Peñafiel',
    'Torrecilla de la Abadesa',
    'Torrecilla de la Orden',
    'Torrecilla de la Torre',
    'Torrelobatón',
    'Torrescárcela',
    'Traspinedo',
    'Trigueros del Valle',
    'Tudela de Duero',
    'Urones de Castroponce',
    'Urueña',
    'Valbuena de Duero',
    'Valdearcos de la Vega',
    'Valdenebro de los Valles',
    'Valdestillas',
    'Valdunquillo',
    'Valladolid',
    'Valoria la Buena',
    'Valverde de Campos',
    'Vega de Ruiponce',
    'Vega de Valdetronco',
    'Velascálvaro',
    'Velilla',
    'Velliza',
    'Ventosa de la Cuesta',
    'Viana de Cega',
    'Villabáñez',
    'Villabaruz de Campos',
    'Villabrágima',
    'Villacarralón',
    'Villacid de Campos',
    'Villaco',
    'Villafrades de Campos',
    'Villafranca de Duero',
    'Villafrechós',
    'Villafuerte',
    'Villagarcía de Campos',
    'Villagómez la Nueva',
    'Villalán de Campos',
    'Villalar de los Comuneros',
    'Villalba de la Loma',
    'Villalba de los Alcores',
    'Villalbarba',
    'Villalón de Campos',
    'Villamuriel de Campos',
    'Villán de Tordesillas',
    'Villanubla',
    'Villanueva de Duero',
    'Villanueva de la Condesa',
    'Villanueva de los Caballeros',
    'Villanueva de los Infantes',
    'Villanueva de San Mancio',
    'Villardefrades',
    'Villarmentero de Esgueva',
    'Villasexmir',
    'Villavaquerín',
    'Villavellid',
    'Villaverde de Medina',
    'Villavicencio de los Caballeros',
    'Viloria',
    'Wamba',
    'Zaratán',
  ],
  Vizcaya: [
    'Abadiño',
    'Abanto y Ciérvana-Abanto Zierbena',
    'Ajangiz',
    'Alonsotegi',
    'Amorebieta-Etxano',
    'Amoroto',
    'Arakaldo',
    'Arantzazu',
    'Areatza',
    'Arrankudiaga',
    'Arratzu',
    'Arrieta',
    'Arrigorriaga',
    'Artea',
    'Artzentales',
    'Atxondo',
    'Aulesti',
    'Bakio',
    'Balmaseda',
    'Barakaldo',
    'Barrika',
    'Basauri',
    'Bedia',
    'Berango',
    'Bermeo',
    'Berriatua',
    'Berriz',
    'Bilbao',
    'Busturia',
    'Derio',
    'Dima',
    'Durango',
    'Ea',
    'Elantxobe',
    'Elorrio',
    'Erandio',
    'Ereño',
    'Ermua',
    'Errigoiti',
    'Etxebarri',
    'Etxebarria',
    'Forua',
    'Fruiz',
    'Galdakao',
    'Galdames',
    'Gamiz-Fika',
    'Garai',
    'Gatika',
    'Gautegiz Arteaga',
    'Gernika-Lumo',
    'Getxo',
    'Gizaburuaga',
    'Gordexola',
    'Gorliz',
    'Güeñes',
    'Ibarrangelu',
    'Igorre',
    'Ispaster',
    'Iurreta',
    'Izurtza',
    'Karrantza Harana/Valle de Carranza',
    'Kortezubi',
    'Lanestosa',
    'Larrabetzu',
    'Laukiz',
    'Leioa',
    'Lekeitio',
    'Lemoa',
    'Lemoiz',
    'Lezama',
    'Loiu',
    'Mallabia',
    'Mañaria',
    'Markina-Xemein',
    'Maruri-Jatabe',
    'Mendata',
    'Mendexa',
    'Meñaka',
    'Morga',
    'Mundaka',
    'Mungia',
    'Munitibar-Arbatzegi Gerrikaitz',
    'Murueta',
    'Muskiz',
    'Muxika',
    'Nabarniz',
    'Ondarroa',
    'Orozko',
    'Ortuella',
    'Otxandio',
    'Plentzia',
    'Portugalete',
    'Santurtzi',
    'Sestao',
    'Sondika',
    'Sopelana',
    'Sopuerta',
    'Sukarrieta',
    'Trucios-Turtzioz',
    'Ubide',
    'Ugao-Miraballes',
    'Urduliz',
    'Urduña/Orduña',
    'Valle de Trápaga-Trapagaran',
    'Zaldibar',
    'Zalla',
    'Zamudio',
    'Zaratamo',
    'Zeanuri',
    'Zeberio',
    'Zierbena',
    'Ziortza-Bolibar',
  ],
  Zamora: [
    'Abezames',
    'Alcañices',
    'Alcubilla de Nogales',
    'Alfaraz de Sayago',
    'Algodre',
    'Almaraz de Duero',
    'Almeida de Sayago',
    'Andavías',
    'Arcenillas',
    'Arcos de la Polvorosa',
    'Argañín',
    'Argujillo',
    'Arquillinos',
    'Arrabalde',
    'Aspariegos',
    'Asturianos',
    'Ayoó de Vidriales',
    'Barcial del Barco',
    'Belver de los Montes',
    'Benavente',
    'Benegiles',
    'Bermillo de Sayago',
    'Bretó',
    'Bretocino',
    'Brime de Sog',
    'Brime de Urz',
    'Burganes de Valverde',
    'Bustillo del Oro',
    'Cabañas de Sayago',
    'Calzadilla de Tera',
    'Camarzana de Tera',
    'Cañizal',
    'Cañizo',
    'Carbajales de Alba',
    'Carbellino',
    'Casaseca de Campeán',
    'Casaseca de las Chanas',
    'Castrillo de la Guareña',
    'Castrogonzalo',
    'Castronuevo',
    'Castroverde de Campos',
    'Cazurra',
    'Cerecinos de Campos',
    'Cerecinos del Carrizal',
    'Cernadilla',
    'Cobreros',
    'Coomonte',
    'Coreses',
    'Corrales',
    'Cotanes del Monte',
    'Cubillos',
    'Cubo de Benavente',
    'Cuelgamures',
    'El Cubo de Tierra del Vino',
    'El Maderal',
    'El Pego',
    'El Perdigón',
    'El Piñero',
    'Entrala',
    'Espadañedo',
    'Faramontanos de Tábara',
    'Fariza',
    'Fermoselle',
    'Ferreras de Abajo',
    'Ferreras de Arriba',
    'Ferreruela',
    'Figueruela de Arriba',
    'Fonfría',
    'Fresno de la Polvorosa',
    'Fresno de la Ribera',
    'Fresno de Sayago',
    'Friera de Valverde',
    'Fuente Encalada',
    'Fuentelapeña',
    'Fuentes de Ropel',
    'Fuentesaúco',
    'Fuentesecas',
    'Fuentespreadas',
    'Galende',
    'Gallegos del Pan',
    'Gallegos del Río',
    'Gamones',
    'Gema',
    'Granja de Moreruela',
    'Granucillo',
    'Guarrate',
    'Hermisende',
    'Jambrina',
    'Justel',
    'La Bóveda de Toro',
    'La Hiniesta',
    'La Torre del Valle',
    'Losacino',
    'Losacio',
    'Lubián',
    'Luelmo',
    'Madridanos',
    'Mahide',
    'Maire de Castroponce',
    'Malva',
    'Manganeses de la Lampreana',
    'Manganeses de la Polvorosa',
    'Manzanal de Arriba',
    'Manzanal de los Infantes',
    'Manzanal del Barco',
    'Matilla de Arzón',
    'Matilla la Seca',
    'Mayalde',
    'Melgar de Tera',
    'Micereces de Tera',
    'Milles de la Polvorosa',
    'Molacillos',
    'Molezuelas de la Carballeda',
    'Mombuey',
    'Monfarracinos',
    'Montamarta',
    'Moral de Sayago',
    'Moraleja de Sayago',
    'Moraleja del Vino',
    'Morales de Rey',
    'Morales de Toro',
    'Morales de Valverde',
    'Morales del Vino',
    'Moralina',
    'Moreruela de los Infanzones',
    'Moreruela de Tábara',
    'Muelas de los Caballeros',
    'Muelas del Pan',
    'Muga de Sayago',
    'Navianos de Valverde',
    'Olmillos de Castro',
    'Otero de Bodas',
    'Pajares de la Lampreana',
    'Palacios de Sanabria',
    'Palacios del Pan',
    'Pedralba de la Pradería',
    'Peleagonzalo',
    'Peleas de Abajo',
    'Peñausende',
    'Peque',
    'Pereruela',
    'Perilla de Castro',
    'Pías',
    'Piedrahita de Castro',
    'Pinilla de Toro',
    'Pino del Oro',
    'Pobladura de Valderaduey',
    'Pobladura del Valle',
    'Porto',
    'Pozoantiguo',
    'Pozuelo de Tábara',
    'Prado',
    'Puebla de Sanabria',
    'Pueblica de Valverde',
    'Quintanilla de Urz',
    'Quintanilla del Monte',
    'Quintanilla del Olmo',
    'Quiruelas de Vidriales',
    'Rabanales',
    'Rábano de Aliste',
    'Requejo',
    'Revellinos',
    'Riofrío de Aliste',
    'Rionegro del Puente',
    'Roales',
    'Robleda-Cervantes',
    'Roelos de Sayago',
    'Rosinos de la Requejada',
    'Salce',
    'Samir de los Caños',
    'San Agustín del Pozo',
    'San Cebrián de Castro',
    'San Cristóbal de Entreviñas',
    'San Esteban del Molar',
    'San Justo',
    'San Martín de Valderaduey',
    'San Miguel de la Ribera',
    'San Miguel del Valle',
    'San Pedro de Ceque',
    'San Pedro de la Nave-Almendra',
    'San Vicente de la Cabeza',
    'San Vitero',
    'Santa Clara de Avedillo',
    'Santa Colomba de las Monjas',
    'Santa Cristina de la Polvorosa',
    'Santa Croya de Tera',
    'Santa Eufemia del Barco',
    'Santa María de la Vega',
    'Santa María de Valverde',
    'Santibáñez de Tera',
    'Santibáñez de Vidriales',
    'Santovenia',
    'Sanzoles',
    'Tábara',
    'Tapioles',
    'Toro',
    'Torregamones',
    'Torres del Carrizal',
    'Trabazos',
    'Trefacio',
    'Uña de Quintana',
    'Vadillo de la Guareña',
    'Valcabado',
    'Valdefinjas',
    'Valdescorriel',
    'Vallesa de la Guareña',
    'Vega de Tera',
    'Vega de Villalobos',
    'Vegalatrave',
    'Venialbo',
    'Vezdemarbán',
    'Vidayanes',
    'Videmala',
    'Villabrázaro',
    'Villabuena del Puente',
    'Villadepera',
    'Villaescusa',
    'Villafáfila',
    'Villaferrueña',
    'Villageriz',
    'Villalazán',
    'Villalba de la Lampreana',
    'Villalcampo',
    'Villalobos',
    'Villalonso',
    'Villalpando',
    'Villalube',
    'Villamayor de Campos',
    'Villamor de los Escuderos',
    'Villanázar',
    'Villanueva de Azoague',
    'Villanueva de Campeán',
    'Villanueva de las Peras',
    'Villanueva del Campo',
    'Villar de Fallaves',
    'Villar del Buey',
    'Villaralbo',
    'Villardeciervos',
    'Villardiegua de la Ribera',
    'Villárdiga',
    'Villardondiego',
    'Villarrín de Campos',
    'Villaseco del Pan',
    'Villavendimio',
    'Villaveza de Valverde',
    'Villaveza del Agua',
    'Viñas',
    'Zamora',
  ],
};
