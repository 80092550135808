import { useState, useRef, useEffect } from 'react';
import BlogPost from './BlogPost';
import ThemeSwitch from './ThemeSwitch';
import { articles } from './data';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-blog.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { Link } from 'react-router-dom';

const Blog = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [currentArticle, setCurrentArticle] = useState(articles[0]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const articleRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const savedTheme = document.documentElement.classList.contains('dark')
      ? 'dark'
      : 'light';
    setTheme(savedTheme);
    setIsLoaded(true);
  }, []);

  const toggleTheme = (newTheme: 'light' | 'dark') => {
    setTheme(newTheme);
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(newTheme);
  };

  const handleArticleChange = (index: number) => {
    setCurrentArticle(articles[index]);
    setMenuOpen(false); // Cierra el menú al seleccionar un artículo

    // Delay scroll to allow menu to close completely in mobile view
    setTimeout(() => {
      if (articleRef.current && headerRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        window.scrollTo({
          top: articleRef.current.offsetTop - headerHeight,
          behavior: 'smooth',
        });
      }
    }, 900); // Adjust timeout duration if necessary
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (!isLoaded) {
    return null; // or a loading spinner
  }

  return (
    <div
      className={`min-h-screen transition-colors duration-300 ${
        theme === 'light'
          ? 'bg-white text-gray-900'
          : 'bg-gray-900 text-gray-100'
      }`}>
      <header
        ref={headerRef}
        className="sticky top-0 z-50 bg-white/80 dark:bg-gray-800/80 backdrop-blur-md shadow-md">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <div className="flex items-center w-full justify-between">
            <div className="flex gap-10 items-center">
              <Link to="/">
                <CloseIcon fill="currentColor" width={36} height={36} />
              </Link>
              <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                ¿Quieres tema?
              </h1>
            </div>
            <button className="lg:hidden ml-4 p-2" onClick={toggleMenu}>
              <ArrowIcon
                className={`transform transition-transform duration-300 ${
                  menuOpen ? 'rotate-180' : 'rotate-0'
                }`}
                fill="currentColor"
                width={36}
                height={36}
              />
            </button>
          </div>
          <nav className="hidden lg:flex space-x-4 whitespace-nowrap">
            {articles.map((article, index) => (
              <button
                key={index}
                onClick={() => handleArticleChange(index)}
                className={`px-4 py-2 rounded-full border border-gray-500 text-sm ${
                  currentArticle.title === article.title
                    ? 'bg-gray-300 dark:bg-gray-700'
                    : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                }`}>
                {article.title}
              </button>
            ))}
          </nav>
        </div>
        <div
          className={`lg:hidden transition-max-height duration-700 ease-in-out overflow-hidden ${
            menuOpen ? 'max-h-screen' : 'max-h-0'
          }`}>
          <nav className="flex flex-col space-y-2 p-4">
            {articles.map((article, index) => (
              <button
                key={index}
                onClick={() => handleArticleChange(index)}
                className={`px-4 py-2 rounded-full border border-gray-500 text-sm ${
                  currentArticle.title === article.title
                    ? 'bg-gray-300 dark:bg-gray-700'
                    : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                }`}>
                {article.title}
              </button>
            ))}
          </nav>
        </div>
      </header>
      <div className="container mx-auto p-4" ref={articleRef}>
        <BlogPost article={currentArticle} />
        <ThemeSwitch setTheme={toggleTheme} theme={theme} />
      </div>
    </div>
  );
};

export default Blog;
