import { useTranslation } from 'react-i18next';
import './logo.scss';

const LogoComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-5 z-10">
      <img
        className="w-40 h-40 object-contain"
        src="img/landing/celestina.webp"
        alt="celestina-logo"
      />
      <h1 className="uppercase text-[#e9bf95] text-4xl font-semibold">
        {t('appName')}
      </h1>
    </div>
  );
};

export default LogoComponent;
