import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/celestina.webp';
import questionsAndAnswers from './questionsAndAnswers';
import { useTranslation } from 'react-i18next';
import './support-chat.scss';

interface ConversationEntry {
  question: string;
  answer: string;
}

const SupportChatComponent = () => {
  const { t } = useTranslation();
  const [conversation, setConversation] = useState<ConversationEntry[]>([]);
  const [answeredQuestions, setAnsweredQuestions] = useState<{
    [categoryIndex: number]: number[],
  }>({});
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [showBotFirstMessage, setShowBotFirstMessage] = useState(false);
  const [visibleOptionsCount, setVisibleOptionsCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  // Agregar un estado para controlar la visibilidad del chat de soporte
  const [supportChatVisible, setSupportChatVisible] = useState(false);
  // Agregar un estado para controlar el bounce del boton de ayuda
  const [isButtonBouncing, setIsButtonBouncing] = useState(false);

  useEffect(() => {
    setIsBotTyping(true);
    setTimeout(() => {
      setIsBotTyping(false);
      setShowBotFirstMessage(true);
    }, 2000); // Duración del efecto "escribiendo..."

    if (showBotFirstMessage && selectedCategory !== null) {
      // Asumiendo que selectedCategory es el índice de la categoría seleccionada
      const questionsInCategory =
        questionsAndAnswers[selectedCategory].questions;

      const maxOptions = questionsInCategory.filter(
        (_, index) =>
          !(answeredQuestions[selectedCategory] || []).includes(index),
      ).length;

      let currentOptions = 0;
      const interval = setInterval(() => {
        currentOptions++;
        setVisibleOptionsCount(currentOptions);

        if (currentOptions >= maxOptions) {
          clearInterval(interval);
        }
      }, 300);

      return () => clearInterval(interval);
    }
  }, [showBotFirstMessage, answeredQuestions, selectedCategory]);

  const handleQuestionClick = (question: string) => {
    // Asegúrate de que selectedCategory no sea null y tenga un índice válido
    if (selectedCategory !== null) {
      const categoryQuestions = questionsAndAnswers[selectedCategory].questions;
      const index = categoryQuestions.findIndex(
        (qa) => qa.question === question,
      );

      // Verifica si la pregunta fue encontrada
      if (index !== -1) {
        // Agregar la pregunta seleccionada a la conversación inmediatamente
        const userEntry = {
          question: categoryQuestions[index].question,
          answer: '',
        };
        setConversation([...conversation, userEntry]);

        // Actualizar el estado de preguntas respondidas con el nuevo índice de pregunta
        const updatedAnsweredQuestions = { ...answeredQuestions };
        if (!updatedAnsweredQuestions[selectedCategory]) {
          updatedAnsweredQuestions[selectedCategory] = [];
        }
        updatedAnsweredQuestions[selectedCategory].push(index);
        setAnsweredQuestions(updatedAnsweredQuestions);
        setIsBotTyping(true);

        // Después de un retraso, mostrar la respuesta del bot
        setTimeout(() => {
          const botEntry = {
            question: '',
            answer: categoryQuestions[index].answer,
          };
          // Asegúrate de actualizar correctamente la conversación con la entrada anterior y la nueva del bot
          setConversation((conversation) => [...conversation, botEntry]);
          setIsBotTyping(false);
        }, 2000); // Duración de la simulación de "escribiendo..."
      }
    }
  };

  const handleCloseChat = () => {
    setSupportChatVisible(false);
  };

  // Función para alternar la visibilidad del chat de soporte
  const toggleSupportChat = () => {
    setIsButtonBouncing(true);
    setSupportChatVisible(!supportChatVisible);
    setTimeout(() => setIsButtonBouncing(false), 500);
  };

  // Dentro del componente, justo antes del return y después de toda lógica previa:
  let unansweredQuestions = [];
  if (selectedCategory !== null) {
    unansweredQuestions = questionsAndAnswers[
      selectedCategory
    ]?.questions.filter(
      (_, index) =>
        !(answeredQuestions[selectedCategory] || []).includes(index),
    );
  }

  return (
    <>
      <div
        className={`support-chat ${
          supportChatVisible ? 'support-chat--active' : ''
        }`}>
        <div className="support-chat__header justify-between">
          <div className="flex items-center">
            <img className="support-chat__header-image" src={logo} alt="logo" />
            <h2 className="support-chat__header-title">{t('appName')}</h2>
          </div>
          <button className="px-2 w-12 sm:hidden" onClick={handleCloseChat}>
            <img src="/img/icons/close.svg" alt="icono cerrar" />
          </button>
        </div>
        <div className="support-chat__messages">
          {showBotFirstMessage && (
            <div className="message message--bot">
              <div className="message__label">{t('appName')}</div>
              <p>{t('support.message')}</p>
            </div>
          )}

          {conversation.map((entry, index) => (
            <React.Fragment key={index}>
              {entry.question && (
                <div className="message message--user">
                  <p>{entry.question}</p>
                </div>
              )}
              {entry.answer && (
                <div className="message message--bot">
                  <div className="message__label">{t('appName')}</div>
                  <p>{entry.answer}</p>
                </div>
              )}
            </React.Fragment>
          ))}

          {isBotTyping && (
            <div className="message message--bot typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}

          {showBotFirstMessage && !isBotTyping && selectedCategory !== null && (
            <>
              {unansweredQuestions.length > 0 ? (
                <div className="support-chat__categories flex flex-wrap gap-2 justify-end">
                  {questionsAndAnswers[selectedCategory].questions
                    .filter(
                      (_, index) =>
                        !(answeredQuestions[selectedCategory] || []).includes(
                          index,
                        ),
                    )
                    .map((qa, index) => (
                      <button
                        className="border-[1px] border-gray-900 rounded-2xl px-3 text-start text-gray-900 py-1 text-md font-semibold hover:bg-gray-200 hover:text-gray-800"
                        key={index}
                        onClick={() => handleQuestionClick(qa.question)}>
                        {qa.question}
                      </button>
                    ))}
                </div>
              ) : (
                <div className="message message--bot">
                  <div className="message__label">{t('appName')}</div>
                  <p>
                    He respondido a todas las preguntas de esta categoría. ¿Hay
                    algo más en lo que pueda ayudarte?
                  </p>
                </div>
              )}
              <button
                className="flex bg-gray-800 w-fit rounded-lg text-white px-3 py-1 mt-3 text-md font-semibold hover:bg-gray-700 mb-2"
                onClick={() => setSelectedCategory(null)}>
                Volver a Categorías
              </button>
            </>
          )}

          {/* Si ninguna categoría ha sido seleccionada aún, muestra las categorías como opciones */}
          {showBotFirstMessage && !isBotTyping && selectedCategory === null && (
            <div className="support-chat__categories flex flex-wrap gap-2 justify-end">
              {questionsAndAnswers.map((category, index) => (
                <button
                  className="border-[1px] border-gray-900 w-fit rounded-2xl text-gray-900 px-3 py-1 text-md font-semibold hover:bg-gray-200 hover:text-gray-800"
                  key={index}
                  onClick={() => setSelectedCategory(index)}>
                  {category.category}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* Botón de soporte */}
      <button
        className={`support-button ${
          isButtonBouncing ? 'bounce' : ''
        } drop-shadow-3xl ${
          supportChatVisible ? 'hidden' : ''
        } sm:block bg-yellow-400`}
        onClick={toggleSupportChat}>
        {supportChatVisible ? (
          <img
            className="flex w-10 mx-auto"
            src="/img/icons/close.svg"
            alt="icono cerrar"
          />
        ) : (
          <img
            className="flex w-8 mx-auto"
            src="/img/icons/chat.svg"
            alt="icono cerrar"
          />
        )}
      </button>
    </>
  );
};

export default SupportChatComponent;
