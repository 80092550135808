import React from 'react';
import './chatSection.scss';
import { Link } from 'react-router-dom';
import goBack from './../../assets/images/goBack.png';
import arrowBack from './../../assets/images/arrowBack2.png';
import ChatConversation from './chatConversation';

interface ChatSectionProps {
  selectedUser: string | null;
  onBack?: () => void;
  image: string;
}

const ChatSection = ({ selectedUser, onBack, image }: ChatSectionProps) => {
  if (!selectedUser) {
    return (
      <div className="chat-window">Please select a user from the list.</div>
    );
  }

  return (
    <div className="chat-section">
      {selectedUser && (
        <div className="chat-section">
          <div className="chat-section__header">
            <div className="chat-section__header-info">
              {onBack && (
                // Show the "Back" button only on mobile view
                <button className="back-button" onClick={onBack}>
                  <img src={arrowBack} alt="back" />
                </button>
              )}
              <img
                className="chat-section__header-image"
                src={image}
                alt={image}
              />
              <h2 className="chat-section__header-title">{selectedUser}</h2>
            </div>

            <Link to="/" className="chat-section__header-close">
              <img src={goBack} alt="close" />
            </Link>
          </div>
          <div className="chat-section__messages">
            <ChatConversation selectedUser={selectedUser} />
          </div>
          <div className="chat-section__input">
            <input
              type="text"
              placeholder="Después de seleccionar las respuestas, dale a enviar"
            />
            <button></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatSection;
