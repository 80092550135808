interface ChatMessage {
  sender: 'bot' | 'user';
  content?: string;
  options?: string[];
  waitingForUserInput?: boolean;
}

export const getTuCelestina: ChatMessage[] = [
  {
    sender: 'bot',
    content: 'Hola Laura, soy Tu Celestina',
    // waitingForUserInput: false,
  },
  {
    sender: 'bot',
    content:
      'Te voy a hacer una serie de preguntas para conocerte un poco más y averiguar que te enamora',
  },
  {
    sender: 'bot',
    content:
      'No te preocupes si durante el proceso quieres cambiar algo, una vez terminemos, podrás volver las veces que necesites y hacer todos los cambios que quieras',
  },
  {
    sender: 'bot',
    content:
      'En primer lugar, me gustaría saber el motivo que te ha traido aquí. Entiendo, que para encontrar pareja, pero ¿porque has elegido a Tu Celestina?',
  },
  {
    sender: 'user',
    options: [
      'Por falta de tiempo',
      'Tengo curiosidad y quería probar algo nuevo',
      'Quiero conocerme más a mi mismo',
      'Tengo dificultades para encontrar pareja',
      'Me siento excluido o incomprendido',
      'Me cuesta encontrar a alguien con quien conectar	',
      'Confio en Tu Celestina',
      'Me han hablado muy bien de Tu Celestina	',
    ],
    waitingForUserInput: true,
  },
  {
    sender: 'bot',
    content: '¿Qué distancia recorrerías para conocer a la persona que buscas?',
  },
  {
    sender: 'user',
    options: [
      'No me importa la distancia',
      'Hasta 300 Km',
      'Hasta 200 Km',
      'Hasta 100 Km',
    ],
    waitingForUserInput: true,
  },
  {
    sender: 'bot',
    content: '¿Te importa la edad?',
  },
  {
    sender: 'user',
    options: ['No, me fio de vuestro criterio', 'Si'],
    waitingForUserInput: true,
  },
];

export const getIntereses: ChatMessage[] = [
  {
    sender: 'bot',
    content: 'Matrimonio',
  },
  {
    sender: 'user',
    options: ['Me gustaría casarme', 'No quiero casarme nunca'],
  },
  {
    sender: 'bot',
    content: 'Religión',
  },
  {
    sender: 'user',
    options: ['Budista', 'Espiritualista', 'Hinduista'],
  },
  {
    sender: 'bot',
    content: 'Animales, que tienes, te gustan o tendrías',
  },
  {
    sender: 'user',
    options: ['Perros', 'Gatos', 'Peces', 'Caballos'],
  },
  {
    sender: 'bot',
    content: 'Horarios',
  },
  {
    sender: 'user',
    options: [
      'Soy madrugadora',
      'Me gusta levantarme tarde',
      'Soy trasnochadora',
      'Me gusta irme a dormir pronto',
    ],
  },
];
