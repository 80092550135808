import React, { useState, useRef, useEffect } from 'react';
import './chatConversation.scss';
import { getIntereses, getTuCelestina } from './chatMessages';

interface ChatConversationProps {
  selectedUser: string;
}
interface ChatMessage {
  sender: 'bot' | 'user';
  content?: string;
  options?: string[];
  waitingForUserInput?: boolean;
}

const ChatConversation = ({ selectedUser }: ChatConversationProps) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [chatInputDisabled, setChatInputDisabled] = useState(true);
  const chatRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [botWaitingForUserInput, setBotWaitingForUserInput] = useState(false);
  const [currentBotMessageIndex, setCurrentBotMessageIndex] = useState(0);
  const [botMessages, setBotMessages] = useState<ChatMessage[]>(getTuCelestina);
  const [selectedOptions, setSelectedOptions] = useState<
    // eslint-disable-next-line prettier/prettier
    Record<number, string>>({});

  const showBotMessages = async (index: number) => {
    if (index < botMessages.length) {
      const botMessage = botMessages[index];
      setCurrentBotMessageIndex(index);
      // Simular que el bot está escribiendo
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', content: 'Escribiendo...' },
      ]);

      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Eliminar el mensaje "Escribiendo..." después de la simulación
      setMessages((prevMessages) =>
        prevMessages.slice(0, prevMessages.length - 1),
      );

      // Mostrar el mensaje del bot
      setMessages((prevMessages) => [...prevMessages, botMessage]);

      if (botMessage.waitingForUserInput) {
        setBotWaitingForUserInput(true);
        return; // Detener la visualización si esperamos la entrada del usuario.
      }
      // if (
      //   botMessage.waitingForUserInput &&
      //   botMessage.options?.includes('Tengo una discapacidad')
      // ) {
      //   setChatInputDisabled(true);
      // }

      // Mostrar el siguiente mensaje después de un retraso
      setTimeout(() => {
        showBotMessages(index + 1);
      }, 2000);
    }
  };

  useEffect(() => {
    console.log(selectedUser);
    setCurrentBotMessageIndex(0);
    setSelectedOptions({});
    setBotWaitingForUserInput(false);

    let newBotMessages: ChatMessage[] = [];

    if (selectedUser === 'Tu celestina') {
      newBotMessages = getTuCelestina;
    } else if (selectedUser === 'Intereses y gustos') {
      newBotMessages = getIntereses;
    }

    // Limpia los mensajes actuales
    setMessages([]);

    // Llama a la función para mostrar los mensajes
    showBotMessages(0);

    // Asigna los nuevos mensajes después de mostrar los mensajes existentes
    setBotMessages(newBotMessages);
  }, [selectedUser]);

  const handleSendMessageOption = (option: string) => {
    // const newMessage: ChatMessage = {
    //   sender: 'user',
    //   content: option,
    // };

    // setMessages([...messages, newMessage]);
    // setInputValue('');
    setSelectedOption(option);

    // Obtén el índice de la sección actual y actualiza la opción seleccionada
    const currentSectionIndex = messages.findIndex(
      (message, index) => index === currentBotMessageIndex,
    );

    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [currentSectionIndex]: option,
    }));

    if (botWaitingForUserInput) {
      setBotWaitingForUserInput(false);
      showBotMessages(currentBotMessageIndex + 1);
    }
  };

  // Función para aplicar la clase CSS a los botones
  const getButtonClass = (option: string) => {
    return option === selectedOption
      ? 'chat-conversation__message-content-option selected-button'
      : 'chat-conversation__message-content-option';
  };

  const renderMessages = () => {
    return messages.map((message: ChatMessage, index) => {
      const senderClassName = message.sender === 'bot' ? 'left' : 'right';
      const nextMessage =
        index < messages.length - 1 ? messages[index + 1] : null;
      const showTail =
        nextMessage === null || nextMessage.sender !== message.sender;

      return (
        <div
          key={index}
          className={`chat-conversation__message ${
            message.sender
          } ${senderClassName} ${showTail ? 'has-tail' : ''}`}>
          <div className="chat-conversation__message-content">
            {message.content}
            {message.options && (
              <div className="chat-conversation__message-content-options">
                {message.options.map((option, optionIndex) => (
                  <button
                    className={getButtonClass(option)}
                    // className={`chat-conversation__message-content-option`}
                    key={optionIndex}
                    onClick={() => handleSendMessageOption(option)}>
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="chat-conversation">
      {renderMessages()} <div ref={chatRef} />
    </div>
  );
};

export default ChatConversation;
