import './investors.scss';

const Investors = () => {
  return (
    <main className="Investors">
      <h1>INVERSORES</h1>
    </main>
  );
};

export default Investors;
