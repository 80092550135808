import React from 'react';
import { useTranslation } from 'react-i18next';
import './second-info.scss';

interface SecondInfoProps {
  registrationFormRef: React.RefObject<HTMLDivElement>;
  questionsFormRef: React.RefObject<HTMLDivElement>;
}

const SecondInfo = ({
  registrationFormRef,
  questionsFormRef,
}: SecondInfoProps) => {
  const { t } = useTranslation();

  const handleRegistrationButtonClick = () => {
    if (registrationFormRef.current) {
      registrationFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleMoreInfoButtonClick = () => {
    if (questionsFormRef.current) {
      questionsFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="second-info px-5 py-24 sm:py-56 lg:px-16 bg-contain">
      <img
        src="img/landing/blueLeaf.webp"
        alt="Superposed image"
        className="second-info__blueSheet2"
      />
      <div className="flex flex-col gap-6 lg:flex-row z-10">
        <div className="bg-gray-900/60 rounded-lg lg:w-1/3 p-5 shadow-xl backdrop-blur-xl">
          <span className="font-bold text-4xl text-gray-400 flex justify-center">
            1
          </span>
          <p className="text-xl text-justify text-white">
            {t('info2.firstParagraph')}
          </p>
        </div>
        <div className="bg-gray-900/60 rounded-lg lg:w-1/3 p-5 shadow-xl backdrop-blur-xl">
          <span className="font-bold text-4xl text-gray-400 flex justify-center">
            2
          </span>
          <p className="text-xl text-justify text-white">
            {t('info2.secondParagraph')}
          </p>
        </div>
        <div className="bg-gray-900/60 rounded-lg lg:w-1/3 p-5 shadow-xl backdrop-blur-xl">
          <span className="font-bold text-4xl text-gray-400 flex justify-center">
            3
          </span>
          <p className="text-xl text-justify text-white">
            {t('info2.thirdParagraph')}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-6 z-10">
        <h2 className="text-2xl max-w-fit text-center font-semibold text-pink-400 mx-auto uppercase drop-shadow-3xl">
          {t('info2.callToAction')}
        </h2>
        <div className="flex flex-col items-center gap-3 lg:flex-row lg:justify-center">
          <button
            className="text-white uppercase w-64 text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
            onClick={handleRegistrationButtonClick}>
            registrate gratis
          </button>

          <button
            className="text-white uppercase w-64 text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
            onClick={handleMoreInfoButtonClick}>
            + info
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondInfo;
