import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import BlogView from './views/blog/BlogView';
import Donations from './views/donations/DonationsView';
import Investors from './views/investors/investors';
import Home from './views/home/home';
import Login from './components/login/login';
import ChatView from './views/chat/ChatView';
import ConditionsView from './views/conditions/ConditionsView';
import ContactView from './views/contact/ContactView';
import CookieConsent from './components/cookie-consent/CookieConsent';
import ConfirmationView from './views/confirmation/ConfirmationView';
import SupportChatComponent from './components/support-chat/support-chat';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

function App() {
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log('👍', 'beforeinstallprompt', event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);

  async function downloadApp() {
    console.log('👍', 'butInstall-clicked');
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log('oops, no prompt event guardado en window');
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log('👍', 'userChoice', result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }

  return (
    <div className="w-full xl:max-w-screen-2xl">
      {/* {isReadyForInstall && (
        <div className="text-white">
          <button className="uppercase" onClick={downloadApp}>
            Instalar app
          </button>
        </div>
      )} */}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<ChatView />} />
        <Route path="/blog" element={<BlogView />} />
        <Route path="/contact" element={<ContactView />} />
        <Route path="/donations" element={<Donations />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/conditions" element={<ConditionsView />} />
        <Route path="/confirmation" element={<ConfirmationView />} />
      </Routes>
      <CookieConsent />
      <SupportChatComponent />
    </div>
  );
}

export default App;
