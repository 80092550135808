import './donations.scss';

const Donations = () => {
  return (
    <main className="Donations">
      <h1>DONACIONES</h1>
    </main>
  );
};

export default Donations;
