import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <nav className="flex flex-col md:flex-row w-full px-5 py-10">
      <div className="flex flex-col md:w-1/3 md:items-center">
        {/* <span className="text-xl max-w-fit text-center font-semibold text-pink-400 mx-auto">
          Legal
        </span> */}
        <ul className="[&>li>a]:inline-block [&>li>a]:py-1">
          <li>{`${t('appName')} © 2024`}</li>
          <li>
            <a
              href="/docs/cookies.pdf"
              target="_blank"
              rel="noopener noreferrer">
              Cookies
            </a>
          </li>
          <li>
            <a
              href="/docs/privacidad.pdf"
              target="_blank"
              rel="noopener noreferrer">
              Privacidad
            </a>
          </li>
          <li>
            <a
              href="/docs/privacidadRRSS.pdf"
              target="_blank"
              rel="noopener noreferrer">
              Redes sociales
            </a>
          </li>
        </ul>
      </div>
      <div className="flex flex-col md:w-1/3 md:items-center">
        {/* <span className="text-xl max-w-fit text-center font-semibold text-pink-400 mx-auto">
          Ayuda y contacto
        </span> */}
        <ul className="[&>li>a]:inline-block [&>li>a]:py-1">
          <li>
            <a
              href="/docs/avisoLegal.pdf"
              target="_blank"
              rel="noopener noreferrer">
              Aviso legal
            </a>
          </li>
          <li>
            <a
              href="/docs/condicionesGenerales.pdf"
              target="_blank"
              rel="noopener noreferrer">
              Condiciones
            </a>
          </li>
          <li>
            <a href="/contact">Contacto</a>
          </li>
          <li>
            <a>info@tucelestina.com</a>
          </li>
        </ul>
      </div>
      {/* <div className="flex flex-row md:w-1/3 md:items-center md:justify-center">
        <ul className="[&>li>a]:inline-block [&>li>a]:py-1 flex gap-5 md:flex md:flex-col md:gap-0">
          <li>
            <a href="">
              <img src="/linkedin.svg" alt="icono LinkedIn" className="w-7" />
            </a>
          </li>
          <li>
            <a href="">
              <img src="/facebook.svg" alt="icono Facebook" className="w-7" />
            </a>
          </li>
        </ul>
      </div> */}
    </nav>
  );
};

export default Footer;
