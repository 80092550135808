import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const SnackbarComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 30000); // Activa el snackbar después de 30 segundos

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      message={t('snackbar.info')}
      autoHideDuration={30000} // El snackbar se cierra después de 30 segundos
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Posiciona el snackbar en la parte superior y centrado
      ContentProps={{
        sx: {
          backgroundColor: '#be6a94', // Cambiar color de fondo
          color: 'white', // Cambiar el color del texto
          borderRadius: '16px', // Cambiar el radio de las esquinas
          fontWeight: 'bold', // Cambiar el peso de la fuente
          textAlign: 'justify', // Alinear el texto al centro
          fontSize: '21px', // Cambiar el tamaño de la fuente
        },
      }}
      action={
        <button
          className="text-pink-100 uppercase text-sm font-semibold shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-pink-300 rounded-xl hover:bg-pink-500 hover:scale-95 transition"
          onClick={handleClose}>
          cerrar
        </button>
      }
    />
  );
};

export default SnackbarComponent;
