import React, { useState } from 'react';
import './contact-form.scss';

interface ContactFormData {
  email: string;
  subject: string;
  text: string;
}

const initialFormData: ContactFormData = {
  email: '',
  subject: '',
  text: '',
};

const ContactFormComponent = () => {
  const [formData, setFormData] = useState<ContactFormData>(initialFormData);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(baseUrl + '/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData(initialFormData);
      } else {
        console.log('Error al enviar la petición.');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="contact-form__data">
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Correo electrónico"
          required
        />
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Asunto"
          required
        />
        <textarea
          id="text"
          name="text"
          value={formData.text}
          onChange={handleChange}
          placeholder="Texto"
          required
          rows={7}
        />
        <div className="contact-form__button">
          <button
            className="text-pink-100 uppercase w-48 text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
            type="submit">
            Enviar
          </button>
        </div>
      </div>
      <img
        className="contact-form__img drop-shadow-2xl"
        src="img/landing/peacock.webp"
        alt="peacock"
      />
    </form>
  );
};

export default ContactFormComponent;
