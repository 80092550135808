import React, { useState } from 'react';
import LogoComponent from '../logo/logo';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './login.scss';

interface Credentials {
  username: string;
  password: string;
}

const LoginComponent = () => {
  const { t } = useTranslation();

  const isDisabled = true;

  const [credentials, setCredentials] = useState<Credentials>({
    username: '',
    password: '',
  });

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    login(credentials.username, credentials.password);
  }

  async function login(username: string, password: string) {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data); // hacer algo con la respuesta del servidor
      } else {
        console.log('Error al iniciar sesión');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();

  const handleRegistrationButtonClick = () => {
    navigate('/?scrollTo=form');
  };

  return (
    <div className="flex flex-col h-screen items-center md:justify-center bg-[linear-gradient(to_right,#a95c73,#59495e)]">
      <form
        className="login-form flex flex-col gap-5"
        onSubmit={handleFormSubmit}>
        <LogoComponent />

        <p className="text-justify text-white md:text-lg">
          {t('loginFromNavbar.message')}
        </p>

        {/* <div className="login-form__group">
          <input
            className="login-form__input"
            type="text"
            name="username"
            id="username"
            value={credentials.username}
            placeholder="Dirección de email"
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="login-form__group">
          <input
            className="login-form__input"
            type="password"
            name="password"
            id="password"
            value={credentials.password}
            placeholder="Contraseña"
            onChange={handleInputChange}
            required
          />
        </div> */}
        {/* <button
          className={`text-pink-100 uppercase w-full self-center sm:w-[300px] p-[10px] text-sm font-medium shadow-xl border-[1px] border-pink-100 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition ${
            isDisabled ? 'opacity-50 pointer-events-none' : 'opacity-100'
          }`}
          type="submit"
          disabled={isDisabled}>
          Iniciar sesión
        </button> */}
      </form>
      <button
        className="self-center md:bottom-20 text-pink-100 w-48 uppercase text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
        onClick={handleRegistrationButtonClick}>
        ¡registrate gratis!
      </button>
    </div>
  );
};

export default LoginComponent;
