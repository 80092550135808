import './first-info.scss';
import { useTranslation } from 'react-i18next';

const FirstInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="first-info min-h-screen w-full text-2xl px-5 py-20 lg:px-16 leading-loose flex flex-col justify-center text-center lg:justify-between text-white">
      <div className="flex justify-start z-10">
        <div className="px-5 lg:my-10 lg:mx-10 w-full lg:max-w-xl [&>h1]:drop-shadow-3xl">
          <h1>{t('info1.tired')}</h1>
          <h1>{t('info1.misunderstood')}</h1>
          <h1>{t('info1.unluckyLove')}</h1>
        </div>
      </div>
      <div className="flex justify-end z-10">
        <div className="lg:my-10 lg:mx-10 w-full lg:max-w-lg [&>h1]:drop-shadow-3xl">
          <h1>{t('info1.idealPartner')}</h1>
          <h1>{t('info1.whereStart')}</h1>
          <h1>{t('info1.stopFeelingAlone')}</h1>
          <h1>{t('info1.rightPlace')}</h1>
        </div>
      </div>
      <img
        className="first-info__snake"
        src="img/landing/snakeApple.webp"
        alt="Superposed image"
      />
      <img
        src="img/landing/blueLeaf.webp"
        alt="Superposed image"
        className="first-info__blueSheet"
      />
    </div>
  );
};

export default FirstInfo;
