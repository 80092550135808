import React, { useState, useEffect } from 'react';
import './chat.scss';
import looking from '../../assets/images/busco.png';
import tastes from '../../assets/images/gustos.png';
import physic from '../../assets/images/fisico.png';
import adjectives from '../../assets/images/adjetivos.png';
import questions from '../../assets/images/preguntas.png';
import essential from '../../assets/images/imprescindibles.png';
import user from '../../assets/images/usuario.png';
import logo from '../../assets/images/celestina.webp';
import { useTranslation } from 'react-i18next';

// Importar los subcomponentes necesarios
import UserSection from './userSection';
import ChatSection from './chatSection';

const ChatComponent = () => {
  const [activeSection, setActiveSection] = useState<string>('Tu celestina');
  const [activeImage, setActiveImage] = useState<string>(logo);

  const { t } = useTranslation();

  const appName = t('appName');

  const [selectedUser, setSelectedUser] = useState<string | null>(
    'Tu celestina',
  );
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= 991.9808,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991.9808);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleBack = () => {
    setSelectedUser(null);
  };

  const handleSectionClick = (section: string, image: string) => {
    setActiveSection(section);
    setSelectedUser(section);
    setActiveImage(image);
  };

  return (
    <div className="chat">
      {isMobileView && selectedUser ? (
        // Show ChatWindow in mobile view if a user is selected
        <div className="chat__content">
          <ChatSection
            selectedUser={selectedUser}
            onBack={handleBack}
            image={activeImage}
          />
        </div>
      ) : (
        // Show both user list and chat window in desktop view, or user list only in mobile view
        <div className="chat__content">
          <div
            className={`chat__content-users ${isMobileView ? 'mobile' : ''}`}>
            <div
              className={`user-section__item ${
                activeSection === 'Tu celestina' ? 'active' : ''
              }`}
              onClick={() => handleSectionClick('Tu celestina', logo)}>
              <img
                className="chat__content-users--logo"
                src={logo}
                alt="Logo"
              />
              <h2 className="chat__content-users--title">{appName}</h2>
            </div>
            <div className="chat__content-users--scrollable">
              <UserSection
                title="Busco"
                imageSrc={looking}
                active={activeSection === 'Busco'}
                onClick={() => handleSectionClick('Busco', looking)}
              />
              <UserSection
                title="Intereses y gustos"
                imageSrc={tastes}
                active={activeSection === 'Intereses y gustos'}
                onClick={() => handleSectionClick('Intereses y gustos', tastes)}
              />
              <UserSection
                title="Físico"
                imageSrc={physic}
                active={activeSection === 'Físico'}
                onClick={() => handleSectionClick('Físico', physic)}
              />
              <UserSection
                title="Adjetivos"
                imageSrc={adjectives}
                active={activeSection === 'Adjetivos'}
                onClick={() => handleSectionClick('Adjetivos', adjectives)}
              />
              <UserSection
                title="Preguntas"
                imageSrc={questions}
                active={activeSection === 'Preguntas'}
                onClick={() => handleSectionClick('Preguntas', questions)}
              />
              <UserSection
                title="Imprescindibles"
                imageSrc={essential}
                active={activeSection === 'Imprescindibles'}
                onClick={() => handleSectionClick('Imprescindibles', essential)}
              />
              <UserSection
                title="Usuario"
                imageSrc={user}
                active={activeSection === 'Usuario'}
                onClick={() => handleSectionClick('Usuario', user)}
              />
            </div>
          </div>
          {selectedUser && (
            <div className={`chat__content`}>
              <ChatSection selectedUser={selectedUser} image={activeImage} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
