import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Switches from './Switches';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCookieSelected, setIsCookieSelected] = useState(false);
  const { t } = useTranslation();
  const cookiesMsgHtml = t('cookies.message');
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  // Ref para el contenedor con scroll
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // Estado para controlar la visibilidad del gradiente
  const [showGradient, setShowGradient] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (!scrollContainerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      const isScrollable = scrollHeight > clientHeight; // Verifica si el contenedor es desplazable
      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      const isScrolledBeyond97Percent = scrollPercentage >= 97;
      // const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      // Muestra el gradiente solo si el contenido es desplazable y no está desplazado completamente hacia abajo

      setShowGradient(isScrollable && !isScrolledBeyond97Percent);
    };

    // Verifica inicialmente la necesidad del gradiente
    checkScroll();

    // Añade el event listener
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', checkScroll);
    }

    // Añade el listener de resize
    window.addEventListener('resize', checkScroll);

    // Limpieza: remover los listeners cuando el componente se desmonte
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', checkScroll);
      }
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="flex flex-col h-auto gap-5 bg-white fixed max-w-[900px] w-custom rounded-2xl shadow-2xl bottom-1/2 left-1/2 -translate-x-1/2 translate-y-1/2 z-[99999] justify-between">
      <div className="relative border-b-2 md:border-b-0 px-5 py-5 min-h-12 h-4/6">
        <div
          ref={scrollContainerRef}
          className="h-full max-h-36 md:max-h-full overflow-x-hidden">
          {showGradient && (
            <div className="absolute bottom-0 left-0 opacity-100 w-[92%] sm:w-[94%] h-20 z-10 pointer-events-none bg-custom-gradient"></div>
          )}
          <h2 className="text-2xl text-primary">{t('cookies.title')}</h2>
          <p
            className="text-lg link-docs text-[#101010]"
            dangerouslySetInnerHTML={{ __html: cookiesMsgHtml }}
          />
        </div>
      </div>

      <Switches setIsCookieSelected={setIsCookieSelected} />

      <div className="flex flex-col md:flex-row gap-2 justify-center px-5 py-5">
        {isCookieSelected ? (
          <button
            className="text-white uppercase order-2 md:order-1 text-sm md:w-72 font-medium shadow-xl px-4 py-4 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
            onClick={handleAccept}>
            Permitir la selección
          </button>
        ) : (
          <button
            className="text-white uppercase order-2 md:order-1 text-sm md:w-72 font-medium shadow-xl px-4 py-4 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
            onClick={handleAccept}>
            Solo utilizar cookies necesarias
          </button>
        )}
        <button
          className="text-white uppercase order-1 md:order-2 text-sm md:w-72 font-medium shadow-xl px-4 py-4 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
          onClick={handleAccept}>
          Permitir todas las cookies
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
