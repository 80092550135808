import ChatComponent from '../../components/chat/chat';
import './chat.scss';

const ChatView = () => {
  return (
    <main className="chat-view">
      <ChatComponent />
    </main>
  );
};

export default ChatView;
