import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';

type SwitchData = [
  string,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean,
];

interface SwitchesProps {
  setIsCookieSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

function Switches({ setIsCookieSelected }: SwitchesProps) {
  const [isNecessary, setIsNecessary] = useState<boolean>(true);
  const [isAnalytics, setIsAnalytics] = useState<boolean>(false);
  const [isFunctionality, setIsFunctionality] = useState<boolean>(false);
  const [isAdvertising, setIsAdvertising] = useState<boolean>(false);

  const switches: SwitchData[] = [
    ['Necesario', isNecessary, setIsNecessary, true],
    ['Analíticas', isAnalytics, setIsAnalytics, false],
    ['Funcionalidad', isFunctionality, setIsFunctionality, false],
    ['Publicitarias', isAdvertising, setIsAdvertising, false],
  ];

  useEffect(() => {
    if (isAnalytics || isFunctionality || isAdvertising) {
      setIsCookieSelected(true);
    } else {
      setIsCookieSelected(false);
    }
  }, [isAnalytics, isFunctionality, isAdvertising]);

  return (
    <div className="flex flex-col border-b-2 md:border-b-0 md:flex-row w-full justify-center h-1/3 gap-6 md:gap-0 px-5 md:px-0 py-5">
      {switches.map(([title, state, setState, isDisabled], index) => (
        <Switch.Group
          as="div"
          className={`flex md:flex-col items-center justify-between md:w-1/4 md:border-y-2 md:py-5 ${
            index !== switches.length - 1 ? 'md:border-r-2' : ''
          }`}
          key={index}>
          <Switch.Label as="span" className="text-lg font-medium">
            {title}
          </Switch.Label>
          <Switch
            checked={state}
            onChange={setState}
            className={`${
              isDisabled ? 'bg-gray-400' : state ? 'bg-primary' : 'bg-gray-200'
            } relative inline-flex items-center h-7 rounded-full w-12 transition-colors focus:outline-none`}
            disabled={isDisabled}>
            <span
              className={`${
                state ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-5 h-5 transform bg-white rounded-full transition-transform`}
            />
          </Switch>
        </Switch.Group>
      ))}
    </div>
  );
}

export default Switches;
