import React from 'react';

interface Article {
  title: string;
  h1: string;
  sections: {
    h2: string,
    content: string[],
  }[];
}

interface BlogPostProps {
  article: Article;
}

const BlogPost = ({ article }: BlogPostProps) => {
  return (
    <article className="prose lg:prose-xl mx-auto max-w-2xl">
      <header className="my-8">
        <title>{article.title}</title>
        <h1 className="text-4xl font-semibold mb-4">{article.h1}</h1>
      </header>
      {article.sections.map((section, index) => (
        <section key={index} className="my-8">
          <h2 className="text-2xl font-semibold mb-4">{section.h2}</h2>
          {section.content.map((paragraph, idx) => (
            <p key={idx} className="mb-4">
              {paragraph}
            </p>
          ))}
        </section>
      ))}
    </article>
  );
};

export default BlogPost;
