import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './question.scss';

type QuestionType = {
  title: string,
  text: string,
};

type QuestionProps = {
  questions: QuestionType[],
};

const QuestionComponent = () => {
  const { t } = useTranslation();

  const questions = [
    {
      title: t('questions.dateTitle'),
      text: t('questions.dateText'),
    },
    {
      title: t('questions.compressionTitle'),
      text: t('questions.compressionText'),
    },
    {
      title: t('questions.commitmentTitle'),
      text: t('questions.commitmentText'),
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpanded = (index: number) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <div className="question px-5 py-28 md:pt-36 md:pb-56 lg:px-16">
      <img
        src="img/landing/blueLeaf.webp"
        alt="Superposed image"
        className="question__blueSheet3"
      />
      <div className="question__column lg:px-44">
        {questions.map((question, index) => {
          return (
            <div key={index} className="question__questions relative">
              <h2 className="text-pink-400 uppercase font-bold text-lg ml-3">
                {question.title}
              </h2>
              <div className="question__text">
                <p
                  className={`question__content ${
                    expandedIndex !== index ? '' : 'question__content--clamp'
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: question.text.replace(/\n/g, '<br />'),
                  }}
                />
                <button
                  className="question__toggle-btn"
                  onClick={() => toggleExpanded(index)}>
                  {expandedIndex === index ? '⬅ Leer menos' : '➡ Leer más'}
                </button>
              </div>
              <img
                src="img/icons/heart.svg"
                alt="heart icon"
                className="absolute w-6 left-[75px] -top-[26px] animate-heartbeat drop-shadow-3xl"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionComponent;
