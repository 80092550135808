import Blog from '../../components/blog/Blog';
import Footer from '../../components/footer/Footer';
import './blog.scss';

const BlogView = () => {
  return (
    <>
      <main className="Blog">
        <Blog />
      </main>
      <footer className="flex w-full h-auto bg-primary text-white font-semibold text-lg">
        <Footer />
      </footer>
    </>
  );
};

export default BlogView;
