import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomDatePicker from '../../date-picker/CustomDatePicker';
import { provincesData } from './provincesPopulation';
import './registration-form.scss';

const RegistrationForm = () => {
  const { t } = useTranslation();
  const termsHtml = t('registration.checkConditions');
  const [selectedRelationOption, setSelectedRelationOption] = useState('');
  const [selectedAmOption, setSelectedAmOption] = useState('');
  const [selectedLookOption, setSelectedLookOption] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [ifeel, setIfeel] = useState<string>('');
  const [ilook, setIlook] = useState<string[]>([]);
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [relationtype, setRelationtype] = useState<string>('');
  const [legalcheck, setLegalcheck] = useState<boolean>(false);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({
    relationtype: false,
    ifeel: false,
    ilook: false,
    name: false,
    birthdate: false,
    email: false,
    province: false,
    city: false,
  });
  const [formErrorLegalCheck, setFormErrorLegalCheck] = useState({
    legalcheck: false,
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const isMobile = false;

  const handleOptionRelationSelect =
    (option: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      setSelectedRelationOption(option);

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        relationtype: !option,
      }));
    };

  const handleOptionAmSelect =
    (option: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      setSelectedAmOption(option);

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ifeel: !option,
      }));
    };

  const handleOptionLookSelect =
    (option: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      setSelectedLookOption((prevSelectedOptions) => {
        if (prevSelectedOptions.includes(option)) {
          return prevSelectedOptions.filter((item) => item !== option);
        } else {
          return [...prevSelectedOptions, option];
        }
      });

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ilook: !option,
      }));
    };

  const handleAcceptanceChange = () => {
    const newLegalCheckValue = !legalcheck;
    setLegalcheck(newLegalCheckValue);

    setFormErrorLegalCheck({
      legalcheck: !newLegalCheckValue,
    });
  };

  // Función para validar el correo electrónico
  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newFormErrors = {
      relationtype: !relationtype,
      ifeel: !ifeel,
      ilook: !ilook.length,
      name: !name,
      birthdate: !birthdate,
      email: !email || !isValidEmail(email),
      province: !province,
      city: !city,
    };

    const newFormErrorLegalCheck = {
      legalcheck: !legalcheck,
    };

    setFormErrors(newFormErrors);
    setFormErrorLegalCheck(newFormErrorLegalCheck);

    // Verifica si hay errores en el formulario
    if (
      Object.values(newFormErrors).some((error) => error) ||
      Object.values(newFormErrorLegalCheck).some((error) => error)
    ) {
      // No envíes el formulario si hay errores
      return;
    }

    // Formatear la fecha antes de enviarla al backend
    const formattedBirthdate = birthdate ? formatDate(birthdate) : '';

    const formData = {
      relationtype,
      ifeel,
      ilook,
      name,
      birthdate: formattedBirthdate,
      email,
      province,
      city,
      legalcheck,
    };

    try {
      const response = await fetch(baseUrl + '/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        navigate('/confirmation');
      } else {
        console.log('Error al enviar el correo');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      navigate('/confirmation');
    }
  };

  // Función para manejar cambios en el campo del nombre
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value;
    setName(nameValue);

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      name: !nameValue,
    }));
  };

  // Función para manejar cambios en el campo del fecha de nacimiento
  const handleBirthdateChange = (date: Date | null) => {
    // const birthdateValue = event.target.value;
    setBirthdate(date);

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      birthdate: !date,
    }));
  };

  // Función para manejar cambios en el campo de email
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      email: !emailValue || !isValidEmail(emailValue),
    }));
  };

  // Función para manejar cambios en el campo de provincia
  const handleProvinceChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const provinceValue = event.target.value;
    setProvince(provinceValue);
    setCity('');
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      province: !provinceValue,
    }));
  };

  // Función para manejar cambios en el campo de poblacion
  const handlePopulationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const populationValue = event.target.value;
    setCity(populationValue);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      city: !populationValue,
    }));
  };

  useEffect(() => {
    setRelationtype(selectedRelationOption);
  }, [selectedRelationOption]);

  useEffect(() => {
    setIfeel(selectedAmOption);
  }, [selectedAmOption]);

  useEffect(() => {
    setIlook(selectedLookOption);
  }, [selectedLookOption]);

  return (
    <form
      className="registration-form px-5 pt-28 pb-[550px] sm:pb-20"
      onSubmit={handleSubmit}>
      <img
        src="img/landing/adanEva.webp"
        alt="Superposed image"
        className="registration-form__adanEva"
      />
      <div className="registration-form__items [&>div>div>button]:backdrop-blur-md [&>div>div>div>input]:backdrop-blur-md [&>div>div>div>select]:backdrop-blur-md">
        <div className={'registration-form__relation'}>
          <p>{t('registration.question')}</p>
          <div
            className={`registration-form__relation-btn flex flex-col sm:flex-row w-full`}>
            <button
              className={`registration-form__option ${
                selectedRelationOption === 'Monógama' ? 'selected' : ''
              }`}
              onClick={(event) =>
                handleOptionRelationSelect('Monógama')(event)
              }>
              {t('registration.monogamous')}
            </button>
            <button
              className={`registration-form__option ${
                selectedRelationOption === 'Relación abierta' ? 'selected' : ''
              }`}
              onClick={(event) =>
                handleOptionRelationSelect('Relación abierta')(event)
              }>
              {t('registration.openRelationship')}
            </button>
            <button
              className={`registration-form__option ${
                selectedRelationOption === 'Poliamor' ? 'selected' : ''
              }`}
              onClick={(event) =>
                handleOptionRelationSelect('Poliamor')(event)
              }>
              {t('registration.polyamory')}
            </button>
          </div>
          {formErrors.relationtype && (
            <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
              {t('registration.required')}
            </span>
          )}
        </div>
        <div className={'registration-form__basics'}>
          <div className={'registration-form__basics-am'}>
            <p>{t('registration.am')}</p>
            <button
              className={`registration-form__option ${
                selectedAmOption === 'Women' ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionAmSelect('Women')(event)}>
              {t('registration.women')}
            </button>
            <button
              className={`registration-form__option ${
                selectedAmOption === 'Man' ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionAmSelect('Man')(event)}>
              {t('registration.man')}
            </button>
            <button
              className={`registration-form__option ${
                selectedAmOption === 'Both' ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionAmSelect('Both')(event)}>
              {t('registration.both')}
            </button>
            <button
              className={`registration-form__option ${
                selectedAmOption === 'Neutral' ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionAmSelect('Neutral')(event)}>
              {t('registration.neutral')}
            </button>
            <button
              className={`registration-form__option ${
                selectedAmOption === 'NoKnow' ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionAmSelect('NoKnow')(event)}>
              {t('registration.iDontKnow')}
            </button>
            {formErrors.ifeel && (
              <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
                {t('registration.required')}
              </span>
            )}
          </div>
          <div className={'registration-form__basics-look'}>
            <p>{t('registration.lookFor')}</p>
            <button
              className={`registration-form__option ${
                selectedLookOption.includes('Women') ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionLookSelect('Women')(event)}>
              {t('registration.women')}
            </button>
            <button
              className={`registration-form__option ${
                selectedLookOption.includes('Man') ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionLookSelect('Man')(event)}>
              {t('registration.man')}
            </button>
            <button
              className={`registration-form__option ${
                selectedLookOption.includes('Both') ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionLookSelect('Both')(event)}>
              {t('registration.both')}
            </button>
            <button
              className={`registration-form__option ${
                selectedLookOption.includes('Neutral') ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionLookSelect('Neutral')(event)}>
              {t('registration.neutral')}
            </button>
            <button
              className={`registration-form__option ${
                selectedLookOption.includes('NoKnow') ? 'selected' : ''
              }`}
              onClick={(event) => handleOptionLookSelect('NoKnow')(event)}>
              {t('registration.iDontKnow')}
            </button>
            {formErrors.ilook && (
              <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
                {t('registration.required')}
              </span>
            )}
          </div>
          <p className="registration-form__basics-moreInfo">
            {t('registration.moreInfo')}
          </p>
        </div>
        <div className={'registration-form__data'}>
          <div className={'registration-form__data-input'}>
            <label>{t('registration.name')}</label>
            <div className={'registration-form__data-content'}>
              <input
                type="text"
                onChange={handleNameChange}
                className={formErrors.name ? 'error' : ''}></input>
              {formErrors.name && (
                <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
                  {t('registration.required')}
                </span>
              )}
            </div>
          </div>
          <div className={'registration-form__data-input'}>
            <label>{t('registration.birthdate')}</label>
            <div className={'registration-form__data-content width'}>
              <CustomDatePicker
                birthdate={birthdate}
                setBirthdate={handleBirthdateChange}
                birthdateError={formErrors.birthdate}
                setBirthdateError={(error: boolean) =>
                  setFormErrors({ ...formErrors, birthdate: error })
                }
              />
            </div>
          </div>
          <div className={'registration-form__data-input'}>
            <label>{t('registration.email')}</label>
            <div className={'registration-form__data-content'}>
              <input
                type="text"
                onChange={handleEmailChange}
                className={formErrors.email ? 'error' : ''}></input>
              {formErrors.email && (
                <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
                  {t('registration.required')}
                </span>
              )}
            </div>
          </div>
          <div className={'registration-form__data-input'}>
            <label>{t('registration.province')}</label>
            <div className={'registration-form__data-content'}>
              <select
                value={province}
                onChange={handleProvinceChange}
                className={formErrors.province ? 'error' : ''}>
                <option value=""></option>
                {Object.keys(provincesData).map((province) => (
                  <option key={province} value={province}>
                    {province}
                  </option>
                ))}
              </select>
              {formErrors.province && (
                <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
                  {t('registration.required')}
                </span>
              )}
            </div>
          </div>
          <div className={'registration-form__data-input'}>
            <label>{t('registration.population')}</label>
            <div className={'registration-form__data-content'}>
              <select
                value={city}
                onChange={handlePopulationChange}
                disabled={!province}
                className={formErrors.city ? 'error' : ''}>
                <option value=""></option>
                {province &&
                  provincesData[province].map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
              </select>
              {formErrors.city && (
                <span className="flex w-full bg-red-600 px-2 rounded-md text-[15px] text-white">
                  {t('registration.required')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="registration-form__submit ">
          <div
            className={'registration-form__submit-label text-justify sm:w-96'}>
            <input
              className="p-2"
              type="checkbox"
              name="acceptance"
              checked={legalcheck}
              onChange={handleAcceptanceChange}
            />

            <label
              className="link-terms bg-secondary/60 p-2 backdrop-blur-xl rounded-lg"
              dangerouslySetInnerHTML={{ __html: termsHtml }}
            />
          </div>
          {Object.values(formErrors).every((value) => !value) &&
            formErrorLegalCheck.legalcheck && (
              <span className="flex w-full bg-red-600 p-2 rounded-md text-[15px] text-white">
                {t('registration.checkConditionsError')}
              </span>
            )}
          <div
            className={`registration-form__submit-button ${
              formErrorLegalCheck.legalcheck
                ? 'flex flex-col w-full items-center gap-1'
                : ''
            }`}>
            {/* <Link to="/chat" className="registration-form__submit-btn">
              ¡Regístrate gratis!
            </Link> */}

            <button
              className="text-pink-100 uppercase w-64 text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
              type="submit"
              // disabled={!accepted}
            >
              ¡Regístrate gratis!
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RegistrationForm;
