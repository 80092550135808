import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './conditions.scss';

const Conditions = () => {
  const { t } = useTranslation();

  const questions = [
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Registro Gratuito</span>: El registro es completamente gratuito. Puedes acceder desde la página de inicio. Importante: para registrarte debes ser mayor de edad.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Proceso de Bienvenida</span>: Tras completar el registro, serás redirigido a un bot interactivo. Este bot te guiará a través de un cuestionario detallado para ayudarte a reflexionar sobre tus preferencias y expectativas, permitiéndonos así conocerte mejor. Nuestro sistema se adapta a tus respuestas y aprende de tus experiencias para asegurar una compatibilidad completa, a todos los niveles, mental, físico y emocional.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Acceso al Bot</span>: Puedes descargar nuestro bot tanto en tu móvil como en tu ordenador. A través de él nos comunicaremos contigo, aunque nos reservamos el derecho de contactarte por teléfono si fuera necesario.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Primera Cita y Pago</span>: Si evaluamos que tienes posibilidades de encontrar pareja basándonos en la información que has proporcionado y la de otros usuarios, te ofreceremos programar tu primera cita. Solo en ese momento se realizará el pago requerido.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Condiciones del Pago</span>: Realizarás un único pago que cubrirá todas las citas necesarias hasta que encuentres pareja. Consideramos que has encontrado pareja cuando lleves un año conociendo a la misma persona. Una vez alcanzado este hito, el servicio se dará por concluido, independientemente del tipo de relación que hayas seleccionado. En caso de detectar un uso indebido del servicio, consideraremos el servicio como cumplido.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Citas a Ciegas</span>: Las citas son a ciegas, porque creemos que solo hay una oportunidad para una primera impresión y porque una foto no puede sustituir una mirada o una sonrisa y el valor de invertir tu tiempo en ir a conocer a una persona, no se puede comparar con una video llamada. Para que pueda nacer el amor, es importante empezar con buen pie y valorar a la otra persona, dedicándole todo tu tiempo y atención.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Privacidad Garantizada</span>: Nunca publicaremos tus fotos ni tu perfil, va en contra de nuestros principios, una foto no puede transmitir todo lo que una persona realmente es y podrías llegar a hacerte una imagen equivocada. No serás el escaparate de nadie y tu privacidad queda garantizada.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Variedad en las Citas</span>: Organizaremos diferentes tipos de citas, desde cenas románticas hasta actividades al aire libre. Te daremos varias opciones y te informaremos sobre el coste de cada actividad, siendo éste independiente de nuestro servicio. Cada participante pagará su parte.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Seguridad Durante las Citas</span>: Siempre sabremos quién está contigo y dónde. Si surge algún problema durante la cita, podrás solicitar ayuda a los responsables del lugar y deberás informarnos para que podamos tomar medidas. Tu seguridad es nuestra prioridad.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Política de Cancelación</span>: Si no puedes asistir a una cita confirmada, notifícanos lo antes posible para ajustar los planes. En caso de ausencia sin aviso y sin una causa mayor, deberás justificar tu inasistencia. Si faltas a tres citas sin justificación válida, se considerará un uso indebido del servicio y se dará por concluido.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Intercambio de Contactos</span>: Recomendamos no intercambiar contactos personales al finalizar una cita. Nos encargaremos de facilitar el intercambio de números de teléfono cuando sea apropiado.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Feedback Post-Cita</span>: Tras cada cita, nos pondremos en contacto contigo para saber cómo fue y ver si deseas seguir viendo a la persona o no. Si no surge la chispa en la primera cita, no te preocupes; ajustaremos los detalles para mejorar tu próxima experiencia.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Citas Sin Prisas</span>: Las citas se realizarán de manera pausada, permitiéndote conocer a las personas una a una sin prisas.",
    },
    // {
    //   title: '',
    //   text: "<span class='text-[#F472B6]'>Garantía de Servicio</span>: Si no logramos organizar tu primera cita dentro de los primeros 6 meses después del pago, te devolveremos el dinero.",
    // },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Pausar el Perfil</span>: Si en algún momento deseas no recibir propuestas de citas durante un período determinado, podrás pausar tu perfil.",
    },
    {
      title: '',
      text: "<span class='text-[#F472B6]'>Darse de Baja</span>: Puedes darte de baja del servicio en cualquier momento si así lo deseas.",
    },
  ];

  const navigate = useNavigate();

  const handleRegistrationButtonClick = () => {
    navigate('/?scrollTo=form');
  };

  return (
    <div className="relative">
      <div className="conditions pt-32 pb-[25rem] md:pb-80 px-5 sm:px-28 xl:px-48 flex flex-col bg-contain md:bg-cover">
        <div className="flex flex-col gap-4">
          <h2 className="text-pink-400 uppercase font-bold text-lg">
            Ofrecemos el servicio de la siguiente manera
          </h2>
          {questions.map((question, index) => {
            return (
              <div
                key={index}
                className="conditions__questions flex items-start gap-2">
                <img
                  src="img/icons/heart.svg"
                  alt="heart icon"
                  className="w-6 left-[75px] -top-[26px] animate-heartbeat drop-shadow-3xl"
                />
                <p
                  className="text-xl text-justify text-white"
                  dangerouslySetInnerHTML={{ __html: question.text }}></p>
              </div>
            );
          })}
        </div>
      </div>
      <button
        className="self-center absolute left-1/2 bottom-80 md:bottom-44 -translate-x-1/2 text-pink-100 w-48 uppercase text-sm font-medium shadow-xl border-[1px] border-pink-100 px-4 py-1 bg-primary rounded-xl hover:bg-pink-500 hover:scale-95 transition"
        onClick={handleRegistrationButtonClick}>
        ¡registrate gratis!
      </button>
      <img
        src="/img/conditions/lion.webp"
        className="absolute object-contain w-72 h-auto left-0 bottom-0 hidden sm:block drop-shadow-3xl"></img>
      <img
        src="/img/conditions/lion.webp"
        className="absolute object-contain w-72 h-auto right-0 bottom-0 -scale-x-100 drop-shadow-3xl"></img>
    </div>
  );
};

export default Conditions;
