export const articles = [
  {
    title: '¿Por qué no encuentras pareja?',
    h1: '¿Por qué no encuentras pareja?',
    sections: [
      {
        h2: 'El motivo principal porque no encuentras pareja, es el estrés',
        content: [
          'El estrés es el principal motivo por el que te puede costar encontrar pareja.',
          'Existen muchas causas que pueden dificultar que una persona encuentre pareja, como puede ser: baja autoestima, inseguridad, falta de tiempo, expectativas desmesuradas, círculo social pequeño, etc.',
          'Pero existe una que lo engloba todo y que realmente puede llegar a ser un gran obstáculo. La forma de vida actual, consiste en una lista de deberes y obligaciones que nunca terminan, que nos absorben, que no nos permite pararnos a descansar, desconectar y sentir.',
          'Hasta que nos convertimos en máquinas, donde tenemos que anular nuestros propios sentimientos y deseos, para poder aguantar y mantener el ritmo.',
          'Esto sumado a otros problemas como puede ser una enfermedad propia o de una persona cercana, dificultades económicas, desacuerdos familiares, problemas laborales, malas experiencias… Hacen que finalmente, de manera instintiva, cerremos nuestros corazones, para no sentir toda esa negatividad y protegernos, pero al mismo tiempo dejamos de sentir, apreciar la vida y el amor.',
          'Nos forjamos una armadura difícil de penetrar, pero también que no nos deja escapar.',
          'Una persona con el corazón cerrado, que no está en consonancia con sus emociones, podría tener delante al amor de su vida y no darse cuenta, no verlo. En definitiva, el estrés es el mayor problema.',
        ],
      },
      {
        h2: '¿Y qué opciones existen actualmente para encontrar pareja que nos puedan ayudar?',
        content: [
          'Internet se ha convertido en el medio principal para contactar con otras personas y consecuentemente también para encontrar pareja.',
          'Según un estudio realizado por la Universidad de Stanford el uso de internet para encontrar pareja, aumentó drásticamente del 2% al 39% entre 1995 y 2017.',
          'Actualmente casi el 40% de las parejas se conoce a través de Internet.',
          'Esto nos deja como medio principal las aplicaciones de citas que, aunque son de utilidad para algunas personas, otras se llevan una mala experiencia y terminan con una gran frustración.',
          'Sí es tu caso, no te preocupes, no es tu culpa.',
          '¿Conoces la paradoja de la elección?',
          'Elegir entre muchas opciones no nos hace más felices, sino que nos paraliza y nos produce insatisfacción.',
          'La excesiva oferta de millones de perfiles, nos provoca un sentimiento de desazón que impide que nos podamos enamorar, aparte de ser agotador e incluso deprimente.',
          'Y precisamente, esto es lo que ofrecen la mayoría de las aplicaciones de citas actuales, muchos perfiles para que estés buscando cuantas más horas mejor.',
          'Contra más busques más dinero ganaran.',
          'Su finalidad no es que encuentres pareja, es tenerte entretenido y además que creas que, si no has conseguido encontrar pareja, es culpa tuya.',
          '¿Por qué no le gusto a nadie? ¿Por qué no hay una persona que realmente me guste?…',
          '¡¡¡En definitiva… más estrés!!!',
        ],
      },
      {
        h2: '¿Entonces que alternativas me quedan? ¿Cuál es la mejor opción? ¿Cómo puedo abrir mi corazón?',
        content: [
          'Si estás buscando pareja, te invitamos a que conozcas a Tu Celestina, donde te ayudamos a que averigües qué te enamora y combinamos lo mejor de los dos mundos, el online y el físico, para que puedas encontrar una pareja de verdad.',
          'Además, te sugerimos el siguiente artículo: “La forma correcta de conocer a una persona para encontrar pareja”.',
        ],
      },
    ],
  },
  {
    title:
      'Cómo encontrar pareja en una aplicación de citas y no desfallecer en el intento',
    h1: 'Cómo encontrar pareja en una aplicación de citas y no desfallecer en el intento',
    sections: [
      {
        h2: 'Encuentra el amor en una aplicación de citas diferente',
        content: [
          'En Tu Celestina nos gusta ser cristalinos como el agua y transparentes como buena relación sentimental y, por eso, queremos ofrecerte una nueva forma de encontrar a esa persona que te complemente.',
          'Porque sabemos lo duro que es encontrar pareja mediante aplicaciones de citas. Aunque lo negarán si les preguntas, más de una persona del equipo lo ha sufrido en sus propias carnes.',
          'Deslizar perfiles hacia la izquierda o la derecha puede resultar divertido y emocionante durante un tiempo, pero tú y yo sabemos que al final acabas estresado y lo que se suponía que debía ser ilusionante, termina siendo DE-CEP-CIO-NAN-TE.',
          'Superficialidad que te saca de quicio y con la que terminas comparándote. Peligroso, muy peligroso.',
          'Sobrecarga de opciones. No lo niegues, al final ya no sabes si la persona que te gustó tenía el pelo rubio o castaño, los ojos claros u oscuros o la que posaba en un yate o en el campo.',
          'Falta de privacidad. Obligatorio poner una foto. ¿Y si no quieres que tu compañero de trabajo sepa que estás en busca del amor? ¿Y si eres de los que no presta atención a los envoltorios?',
          'Sí, te lo estamos planteando muy negro, pero no somos catastrofistas, hay mucha gente que ha encontrado pareja en esas aplicaciones: El amor está en todas partes.',
          'Pero puede que tú no seas “esa gente” y necesites otro tipo de conexión más auténtica y original.',
          'Sigue leyendo, que te va a gustar lo que te proponemos.',
        ],
      },
      {
        h2: 'Encuentra el amor en una aplicación de citas diferente',
        content: [
          'La emoción de una primera cita es increíble. ¿Imaginas cómo sería si ni siquiera supieses cómo es la persona con la que te vas a encontrar?',
          'Sí, sí, una cita a ciegas en toda regla.',
          'Pero no del todo, porque en Tu Celestina queremos que tú no tengas que preocuparte por nada. Nosotros nos ocuparemos de encontrar el perfil que más se ajuste a tus expectativas, ideales, valores, gustos, inquietudes…',
          'Y cuando lo encontremos, te prepararemos esa cita inolvidable.',
          'Nada de tener que hacerte el selfie perfecto ni de pensar en una descripción que nunca podrá definir todo lo que eres.',
          'Nada de preocuparte por tu intimidad y privacidad.',
          'Nada de pasarle tu ubicación a un amigo antes de salir de casa por seguridad. Nosotros velamos por ti.',
          'Tu Celestina se encarga de todo y para todos porque, ¿hay algo más inclusivo que el amor?',
          'El amor de tu vida puede estar esperándote tras este registro: CORAZÓN.',
        ],
      },
    ],
  },
  {
    title: 'La magia de una cita a ciegas',
    h1: 'La magia de una cita a ciegas',
    sections: [
      {
        h2: '5 razones para tener una cita a ciegas',
        content: [
          'La emoción del misterio. Los humanos somos curiosos por naturaleza. Nos encanta resolver enigmas y que nos mantengan en tensión. Una prueba de ello son los avances de temporada en las series. Te generan interés, curiosidad y deseo de saber más. Imagínate lo que puede ser convertirte en protagonista del avance de tu nueva temporada: la de tu vida en pareja.',
          '¡Fuera expectativas! Libre de ellas, todo lo que puede surgir sólo puede mejorar y dar paso a la autenticidad y espontaneidad.',
          'Permítete sorprenderte. Estamos en la era de la información. Lo tenemos todo a golpe de click y pocas cosas nos sorprenden y nos dejan con la boca abierta. Con una cita a ciegas podrás mirar con ojos curiosos de niño a la persona que tienes delante y jugar a descubrir sus habilidades, su personalidad e intereses.',
          'Un mundo a explorar. En tu cita a ciegas, nosotros seleccionamos el lugar de encuentro. Puede que nunca hayas estado en ese sitio o realizando esa actividad. ¿Imaginas compartir una experiencia nueva para los dos? Una historia nueva para contar que puede ser el inicio de muchas.',
          'Sin presiones. Ambos estaréis en igualdad de condiciones. Olvídate de esa sensación de competencia y comparaciones tanto internas como externas.',
        ],
      },
      {
        h2: 'Bonus track: Hemos hecho selección por ti',
        content: [
          'Tú no sabes nada de esa persona, pero nosotros sí. En base a la información que nos has proporcionado, encontraremos el perfil que más encaje en tu corazoncito. Eso da un extra de seguridad, ¿verdad?',
          'Si ya lo decían… El amor es ciego, pero necesitamos sentirlo en su globalidad: físico, mental y espiritual. ¿Estará aquí esa persona tan especial?',
        ],
      },
    ],
  },
  {
    title: 'La química del enamoramiento ',
    h1: 'La química del enamoramiento ',
    sections: [
      {
        h2: 'Lorem ipsum dolor sit amet',
        content: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        ],
      },
      {
        h2: 'Lorem ipsum dolor sit amet',
        content: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        ],
      },
    ],
  },
];
