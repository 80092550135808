import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationES from './es/translation.json';
// Importa más archivos de traducción si es necesario

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: translationES,
    },
    // Agrega más idiomas y sus archivos de traducción aquí
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
